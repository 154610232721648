.transfersFileUploadContainer {
  width: 100%;
  padding: 16px;
  border-radius: 8px;
  background-color: var(--FAFAFB-27272B);
}

.transfersFileUploadHelper {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 12px;
}

.fileUploadDropzone, .fileUploadActiveDropzone, .fileUploadErrorDropzone {
  display: flex;
  height: 202px;
  flex-direction: column;
  align-items: center;
  border-radius: 8px;
  padding: 32px 24px;
  background-color: var(--FFFFFF-222225);
  border: 1px dashed var(--F9CB76-D7AA70);
}

.fileUploadActiveDropzone {
  background-color: var(--FEF7E9-36302B);
  border: 1px dashed var(--F19304-FEBD72);

  > div > svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  > button {
    background-color: var(--FDE8C0-554639);

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }

    > div {
      color: var(--8C5020-F8C6A0);
    }
  }
}

.fileUploadErrorDropzone {
  background-color: var(--FBE5E7-3A1D1E);
  border: 1px dashed var(--D92638-F45258);

  > div {
    > svg {
      path {
        fill: var(--F2B6BC-763739);
      }
    }
    > div:nth-child(3) {
      color: var(--D92638-F45258);
    }
  }

  > button {
    background-color: transparent;

    svg {
      path {
        fill: var(--D9D9DC-4E4E55);
      }
    }

    > div {
      color: var(--9F9FA7-7A7A84);
    }
  }
}

.transfersFileUploadTitleSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-bottom: 12px;
    path {
      fill: var(--F9CB76-D7AA70);
    }
  }

  > div:nth-child(2) {
    margin-bottom: 2px;
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(3) {
    font-family: Roboto, sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
  }
}

.transfersFileUploadBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  outline: none;
  border: none;
  margin-top: 24px;
  border-radius: 6px;
  width: 123px;
  height: 32px;
  background-color: var(--FEF7E9-36302B);

  svg {
    transform: rotate(180deg);
    margin-right: 8px;

    path {
      fill: var(--FCB241-DD994B);
    }
  }

  > div {
    font-family: Roboto-Medium, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
  }

  &:hover {
    cursor: pointer;
    background-color: var(--FDE8C0-554639);

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }

    > div {
      color: var(--8C5020-F8C6A0);
    }
  }
}

.transfersFileUploadPreviews {
  display: flex;
  flex-wrap: wrap;
  margin-top: 12px;
  gap: 8px;
  row-gap: 18px;
}

.fileUploadPreviewSection, .fileErrorUploadPreviewSection {
  display: flex;
  align-items: center;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  background-color: var(--FFFFFF-222225);
  padding: 6px 8px 6px 6px;
  width: 200px;
  height: 84px;
  position: relative;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--7F78E3-736DBB);
    > div:nth-child(2) {
      svg {
        path {
          fill: var(--7F78E3-736DBB);
        }
      }
    }
  }
}

.fileErrorUploadPreviewSection {
  border: 1px solid var(--F2B6BC-763739) !important;

  > div:nth-child(2) {
      svg {
        path {
          fill: var(--B3B3B9-686871) !important;
        }
      }
  }

  &:hover {
    border: 1px solid var(--D92638-F45258) !important;

     > div:nth-child(2) {
      svg {
        path {
          fill: var(--9F9FA7-7A7A84) !important;
        }
      }
  }
  }
}

.fileUploadPreviewImgSection {
  width: 80px;
  height: 72px;
  border-radius: 3px;
  border: 1px solid var(--F4F4F6-313135);
}

.fileUploadPreviewImg {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 3px;
}

.fileUploadPreviewPdf {
  height: 72px;

  > canvas {
    height: 72px !important;
  }

  > div {
    display: none !important;
  }
}

.fileErrorUploadPreview {
  width: 80px;
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--FAFAFB-27272B);

  > svg {
    path {
      fill: var(--E15C69-C7383D);
    }
  }
}

.fileUploadPreviewCrossIcon {
  position: absolute;
  width: 16px;
  height: 16px;
  right: 8px;
  top: 8px;
  cursor: pointer;

  svg {
    path {
      transform: scale(0.75) translate(4px, 3px);
      fill: var(--B3B3B9-686871);
    }
  }
}

.fileUploadPreviewInfo {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  margin-left: 8px;
}

.fileUploadPreviewName {
  max-width: 94px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: Roboto-Medium, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 1px;
}

.fileUploadPreviewFormat {
  display: flex;
  align-items: center;

  svg {
    width: 16px;
    height: 16px;
    margin-right: 4px;
    path {
      transform: scale(0.7) translate(0, -1px);
      fill: var(--579E92-379585);
    }
  }

  > div {
    font-family: Roboto, sans-serif;
    font-size: 10px;
    line-height: 14px;
    color: var(--9F9FA7-7A7A84);
  }
}

.fileUploadPreviewErrorFormat, .fileUploadPreviewError {
  color: var(--D92638-F45258);
  font-family: Roboto, sans-serif;
  font-size: 10px;
  line-height: 14px;
}

.fileUploadPreviewError {
  position: absolute;
  bottom: -19px;
  left: 8px;
}