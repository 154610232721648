&:root[data-theme="light"] {
  .currentSubscriptionContainer {
    background: url(assets/images/currentSubscriptionBg.webp) lightgray 50% / cover no-repeat;
  }
  .subscriptionPeriodItem {
    background: rgba(255, 255, 255, 0.80);
    box-shadow: 0 4px 16px 0 rgba(178, 190, 195, 0.25);
  }
  .subscriptionPeriodActiveSelectedItem {
    background: rgba(237, 236, 254, 0.40);
  }
}

&:root[data-theme="dark"] {
  .currentSubscriptionContainer {
    background: url(assets/images/currentSubscriptionDarkBg.webp) lightgray 50% / cover no-repeat;
  }
  .subscriptionPeriodItem {
    background: rgba(34, 34, 37, 0.80);
    box-shadow: 0 4px 20px 0 rgba(19, 19, 22, 0.80);
  }
  .subscriptionPeriodActiveSelectedItem {
    background: rgba(45, 43, 70, 0.50);
  }
}

.subscriptionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  overflow-y: auto;
}
.currentSubscriptionContainer {
  position: relative;
  width: 644px;
  height: 361px;
  padding: 24px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background-color: var(--FAFAFB-27272B);

  > div:nth-child(1) {
    display: flex;
    justify-content: space-between;
  }
}

.currentSubscriptionActiveContainer {
  height: 305px;
}

.currentSubscriptionTitleSection {
  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }
  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    margin-top: 11px;
  }
}

.currentSubscriptionStatusSection {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div:nth-child(1) {
    padding: 6px 12px;
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    border-radius: 24px;
    border: 1px solid var(--ECECED-3D3D42);
  }

  > div:nth-child(2) {
    margin-top: 8px;
    margin-right: 12px;
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
  }
}

.currentSubscriptionNotActiveStatusSection {
  > div:nth-child(1) {
    background-color: var(--F4F4F6-313135);
    color: var(--606068-BCBCC2);
  }
}

.currentSubscriptionActiveStatusSection {
  > div:nth-child(1) {
    background-color: var(--EDECFE-2D2B46);
    color: var(--564C94-A39ED1);
  }
  > div:nth-child(2) {
    color: var(--36363A-FFFFFF);
  }
}

.currentSubscriptionOverdueStatusSection {
  > div:nth-child(1) {
    background-color: var(--FEF4EA-443018);
    color: var(--D66B00-D48F49);
  }
  > div:nth-child(2) {
    color: var(--D66B00-D48F49);
  }
}

.currentSubscriptionCancelledStatusSection {
  > div:nth-child(1) {
    background-color: var(--FBE5E7-3A1D1E);
    color: var(--D92638-F45258);
  }
  > div:nth-child(2) {
    color: var(--D92638-F45258);
  }
}

.subscriptionPeriodsSection {
  display: flex;
  gap: 16px;
  margin-top: 24px;
}

.subscriptionPeriodItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  backdrop-filter: blur(6px);
  width: 290px;
  height: 202px;
  padding: 24px 16px;
  position: relative;

  > button {
    width: 156px;
    height: 32px;
    margin-top: 22px;
    text-transform: initial;
  }
}

.subscriptionPeriodAnnualItem {
  border: 1px solid var(--D4E8E4-1F5C52);
}

.subscriptionPeriodActiveItem {
  height: 146px;
}

.subscriptionPeriodActiveSelectedItem {
  border: 2px solid var(--7F78E3-8782C4);
}

.subscriptionPeriodItemTitle {
  margin-bottom: 12px;

  > span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    color: var(--36363A-FFFFFF);
  }
  > span:nth-child(2) {
    margin-left: 8px;
    color: var(--746BB3-8782C4)
  }
}

.subscriptionPeriodItemAmountSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    margin-top: 2px;
  }
}

.subscriptionPeriodItemAmount {
  > span:nth-child(1) {
    color: var(--36363A-FFFFFF);
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 28px;
    line-height: 40px;
  }
  > span:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 22px;
    color: var(--9F9FA7-7A7A84);
    text-decoration-line: line-through;
    text-decoration-thickness: 1px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 8px;
  }
}

.subscriptionPeriodItemMostPopular {
  position: absolute;
  border-radius: 24px;
  padding: 3px 6px;
  background-color: var(--D4E8E4-1F5C52);
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--12836E-74BEA5);
  top: -10px;
}

.includedSubscriptionFeatures {
  margin-top: 24px;

  > div:nth-child(1) {
    margin-left: 16px;
    font-family: Jost, 'sans-serif';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 12px;
  }

  > div:nth-child(2) {
    padding: 40px 46px 48px;
    background-color: var(--FAFAFB-27272B);
    border-radius: 12px;
    border: 1px solid var(--ECECED-3D3D42);
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    width: 644px;
    height: 458px;

    > div {
      &:not(:nth-child(4n)) {
        margin-bottom: 32px;
      }
      &:nth-child(4n) {
        margin-right: 40px;
      }
    }
  }
}

.includedSubscriptionFeature {
  display: flex;

  > svg {
    margin-right: 16px;
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.includedSubscriptionFeatureDescription {
  > div:nth-child(1) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 4px;
  }
  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
  }
}