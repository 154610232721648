.transactionDetailsContainer {
  margin: 24px;
  width: 100%;

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
  }
}

.transactionDetailsSection {
  padding: 40px;
  height: 100%;
  min-height: 732px;
  max-height: 912px;
  width: 640px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
  }
}

.transactionDetailsBreadcrumbs {
  > div:nth-child(3) {
    font-family: Roboto, 'sans-serif' !important;
    color: #8D8D96 !important;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF) !important;
    }
  }

  > div:nth-child(5) {
    font-family: Roboto-Medium, 'sans-serif';
    color: var(--36363A-FFFFFF);
  }
}

.transactionDetailsHeader {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.transactionDetailsAmount, .transactionDetailsInboundAmount, .transactionDetailsDeclinedAmount, .transactionDetailsExpiredAmount {
  display: flex;
  align-items: center;
  position: relative;
  width: fit-content;

  span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 28px;
    line-height: 40px;
    color: var(--36363A-FFFFFF);
  }
}

.transactionDetailsInboundAmount {
  span {
    color: var(--12836E-74BEA5);
  }
}

.transactionDetailsDeclinedAmount, .transactionDetailsExpiredAmount {
  span {
    color: #8D8D96;
  }

  &:before {
    position: absolute;
    content: '';
    height: 1.5px;
    width: 100%;
    background-color: #8D8D96;
    top: 57%;
  }
}

.transactionDetailsExpiredAmount {
  span {
    color: var(--36363A-FFFFFF);
  }

  &:before {
    background-color: var(--36363A-FFFFFF);
  }
}

.transactionDetailsBeneficiaryInfo {
  margin-top: 6px;

  > span {
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.transactionDetailsBeneficiaryDirection {
  font-family: Roboto, sans-serif;
}

.transactionDetailsBeneficiaryName {
  font-family: Roboto-Medium, 'sans-serif';
}

.transactionDetailsBeneficiaryDate {
  font-family: Roboto, sans-serif;
  color: #8D8D96 !important;
}

.transactionDetailsLogo, .transactionHistoryLogoPending, .transactionHistoryLogoDeclined, .transactionLogoExpiredStyles, .transactionLogoRevertedStyles {
  width: 64px;
  height: 64px;
  background-color: var(--F4F4F6-313135);

  > svg:nth-child(1) {
    width: 32px;
    height: 32px;

    path {
      transform: scale(1.4);
      fill: var(--B3B3B9-686871);
    }
  }

  > img {
    width: 64px;
    height: 64px;
  }
}

.transactionDetailsExchangeLogo {
  width: 64px;
  height: 64px;

  > div {
    > img:nth-child(1) {
      width: 42px;
      height: 42px;
    }

    > div {
      bottom: -6px;
      right: -6px;
      width: 56px;
      height: 56px;
      padding: 6px;

      img {
        width: 44px;
        height: 44px;
      }
    }
  }
}

.transactionHistoryLogoPending, .transactionHistoryLogoDeclined, .transactionLogoExpiredStyles, .transactionLogoRevertedStyles {
  > svg:nth-child(2) {
    position: absolute;
    width: 16px;
    height: 16px;
    right: 2.5px;
    bottom: 2px;
    path {
      fill: #FFFFFF !important;
    }
  }

  &:before {
    content: '';
    position: absolute;
    border-radius: 16px;
    border: 2px solid var(--FFFFFF-222225);
    background-color: var(--F69744-E67A0F);
    width: 24px;
    height: 24px;
    right: -2px;
    bottom: -2px;
  }
}

.transactionHistoryLogoDeclined {
  > svg:nth-child(2) {
    right: 2.3px;
    bottom: 2.3px;

    path {
      transform: scale(0.7);
    }
  }

  &:before {
    background-color: var(--E15C69-C7383D);
  }
}

.transactionLogoExpiredStyles {
  > svg:nth-child(2) {
    right: 2px;
  }

  &:before {
    background-color: var(--E15C69-C7383D);
  }
}

.transactionLogoRevertedStyles {
  &:before {
    background-color: var(--7F78E3-8782C4);
  }
}

.transactionDetailsInfoSection {
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  padding: 0 24px;

  > div:not(:last-child) {
    border-bottom: 1px solid var(--F4F4F6-313135);
  }
}

.transactionDetailsField {
  display: flex;
  justify-content: space-between;
  padding: 18px 0;
  align-items: center;
  height: 64px;

  > div:nth-child(1) {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
  }

  > div:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
    text-align: end;
  }
}

.transactionDetailsCrossCurrencyAmount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div:nth-child(2) {
    margin-top: 3px;

    span {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: #8D8D96;
    }
  }
}

.transactionDetailsExchangeRate {
  display: flex;
}

.transactionDetailsButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: 40px;

  button {
    height: 48px;
  }

  > button {
    width: 73px;
  }

  > div:nth-child(2) {
    display: flex;
  }

  > div > button:nth-child(1) {
    width: 165px;
    margin-right: 24px;
  }
}

.transactionDetailsExportBtn {
  border-radius: 6px 0 0 6px;
  width: 149px;
  margin-right: 1px;
}

.transactionsDetailsExportMultiPointButton {
  position: relative;

  > button {
    width: 48px;
    border-radius: 0 6px 6px 0;
    padding: 0 !important;
    position: static !important;

  > div {
    display: none !important;
  }

  > span {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background-color: var(--FCB241-DD994B);

    &:not(:nth-child(3)) {
      margin-right: 4px;
    }
  }

  &:hover {
    > span {
      background-color: var(--F19304-FEBD72);
    }
  }
  }
}

.exportSelector {
  position: absolute;
  width: 155px;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--FFFFFF-222225);
  right: 0;
  top: 54px;
  box-shadow: var(--B2BEC340-131316CC);
  z-index: 2;

  > div {
    padding: 10px 12px 10px 14px;
    font-family: Roboto, 'sans-serif';
    line-height: 20px;
    font-size: 14px;
    color: var(--606068-BCBCC2);

    &:hover {
      cursor: pointer;
      background-color: var(--FAFAFB-27272B);
    }
  }
}

.inboundTransferSection {
  margin-top: 40px;
}