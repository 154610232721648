.container {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 0 auto;
  }
}

.leftPanel {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--F2F2F2-313135);
  padding: 40px 48px;

  @media (max-width: 1440px) {
    width: 54%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 42.5%;
  }

  @media (min-width: 1920px) {
    padding-left: 0;
  }
}

.rightPanel {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--FFFFFF-222225);
  position: relative;

  @media (max-width: 1440px) {
    width: 46%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 57.5%;
  }
}

.header {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: left;
  color: var(--36363A-FFFFFF);
}

.blockTransactionContainer {
  padding-top: 8px;
}

.blockTransaction {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 96px;

  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);

  background-color: var(--FFFFFF-222225);
  margin-top: 24px;
  padding: 0 24px;

  > div {
    display: flex;
    align-items: center;
  }

  &:hover {
    border: 1px solid var(--7F78E3-8782C4);

    > div {
      > svg {
        path {
          fill: var(--746BB3-A39ED1);
        }
      }
    }
  }
}

.titleBlockTransaction {
  padding: 0 24px;
  font-family: "Roboto-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: left;

  color: var(--606068-BCBCC2);
}

.arrowButton {
  cursor: pointer;

  width: 105px;
  height: 32px;
  border-radius: 6px;
  padding: 5px 12px 5px 16px;

  background: var(--FEF7E9-36302B);

  display: flex;
  align-items: center;
  justify-content: center;

  > span {
    font-family: "Roboto-Medium", sans-serif;
    text-transform: initial;
    font-size: 14px;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
  }

  > svg {
    width: 16px;
    height: 16px;
    margin-left: 8px;

    path {
      transform: scale(0.68);
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    background-color: var(--FDE8C0-554639);

    > span {
      color: var(--8C5020-F8C6A0);
    }

    > svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.emptyContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  > span:nth-child(2) {
    margin-top: 48px;

    font-family: "Jost", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    color: var(--606068-BCBCC2);
  }

  > span:nth-child(3) {
    margin-top: 24px;

    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    color: #8D8D96;

    width: 330px;
  }
}

.headerContainer {
  margin: 49px 48px 33px 48px;

  display: flex;
  justify-content: space-between;
  align-items: center;

  > span {
    font-family: "Jost", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;

    color: var(--36363A-FFFFFF)
  }

  @media (min-width: 1920px) {
    margin-right: 0;
  }
}

.lastTransfersViewAll {
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    > span {
      color: var(--8C5020-F8C6A0);
    }
    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.lastTransfersContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.transactionContainer {
  cursor: pointer;

  height: 80px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  margin: 0 48px 24px 48px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  > div:first-child {
    display: flex;
    align-items: center;

    > svg {
      path {
        fill: var(--ECECED-3D3D42);
      }
    }
  }

  &:hover {
    background-color: var(--FAFAFB-27272B);
    > div:first-child {
      > svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
      > div {
        > div:first-child > span:first-child,
        > div:first-child > span:nth-child(3) {
          color: var(--36363A-FFFFFF);
        }

        >div:nth-child(2) > span {
          color: var(--8D8D96-7A7A84);
        }
      }
    }
  }

  @media (min-width: 1920px) {
    margin-right: 0;
  }
}

.textContainer {
  margin-left: 24px;
  > div:first-child {
    display: flex;
    margin-bottom: 4px;

    > span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;
      color: var(--606068-BCBCC2);
    }
    > span:nth-child(2) {
      color: var(--FCB241-DD994B);
      margin: 0 4px;
    }
  }
  > div:nth-child(2){
    height: 16px;

    > span {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0;
      text-align: left;
      color: var(--9F9FA7-7A7A84);
    }
  }
}

.amountSection {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0;
  text-align: left;
  color: var(--606068-BCBCC2);
}

.buttonsContainer {
  display: flex;
  align-items: center;

  >div {
    margin-left: 24px;
    width: 32px;
    height: 32px;
    padding: 8px;
    border-radius: 6px;

    > svg {
      cursor: pointer;
      path {
        fill: var(--D9D9DC-4E4E55)
      }
    }
    &:hover {
      background-color: var(--FDE8C0-554639);
      > svg {
        path {
          fill: var(--F19304-FEBD72) !important;
        }
      }
    }
  }
}

.tooltipStyle {
  :global {
    .ant-tooltip-inner {
      display: flex;
      align-items: center;
      background-color: var(--FFFFFF-222225);
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      font-weight: 400;
      line-height: 16px;
      color: var(--36363A-FFFFFF);
    }
    .ant-tooltip-arrow-content::before {
      background-color: var(--FFFFFF-222225);
    }

    .ant-tooltip-arrow {
      &:before {
        background-color: var(--FFFFFF-222225);
      }
    }
  }
}

.rightEmptyBlock {
  display: none;
  width: calc((100vw - 1552px - 240px) / 2);

  @media (min-width: 1920px) {
    display: block;
    position: absolute;
    height: 100%;
    right: calc((100vw - 1552px - 240px) / -2);
    top: 0;
    background-color: var(--FFFFFF-222225);
  }
}
