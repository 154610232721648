.terminateCardModal {
  :global {
    .ant-modal-body {
      padding: 32px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }
}

.terminateCardModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 22px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-top: 32px;
}

.terminateCardModalButtons {
  margin-top: 48px;
  width: 100%;
  display: flex;
  justify-content: space-between;

  > button {
    padding: 0;
    width: 61px;
  }
}

.terminateBtn {
  width: 182px !important;

  svg {
    margin-left: 8px;

    path {
      transform: scale(1) translate(0px, 0px) !important;
      fill: var(--E15C69-C7383D);
    }
  }

  &:hover {
    svg{
      path {
        fill: var(--D92638-F45258) !important;
      }
    }
  }
}

.terminateCardModalSubTitle {
  text-align: center;
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
  margin-top: 8px;
}

.terminatePhysicalCardModalSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(1) {
    .terminateCardModalSubTitle
  }

  > div:nth-child(2) {
    margin-top: 24px;
    width: 149px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    label:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  :global {
    .ant-radio-inner {
      width: 20px;
      height: 20px;
    }

    .ant-radio-wrapper span.ant-radio+* {
      padding-inline-end: 0 !important;
    }
  }
}

.terminateReasonItem {
  display: flex;
  align-items: center;
  height: 28px;
  font-size: 18px;
  line-height: 24px;
  font-family: "Roboto", sans-serif;
  color: var(--606068-BCBCC2);
  cursor: pointer;
  margin-right: 0;
}
