.expDeliveryAddress, .deliveryAddress, .selectedDeliveryAddress {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--EAEAEB-3D3D42);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.deliveryAddress, .selectedDeliveryAddress {
  &:hover {
    background-color: var(--FAFAFB-27272B);
    border: 1px solid var(--EAEAEB-3D3D42);

    .deliveryAddressIcons {
      > div:nth-child(1) {
        svg {
          display: block;
        }
      }
    }
    .deliveryAddressIcon {
      background-color: var(--F4F4F6-313135);

      path {
        fill : var(--746BB3-A39ED1);
      }
    }

    .deliveryAddressInfo {
      > svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
    }
    .deliveryAddressName {
      > div {
        color: var(--36363A-FFFFFF);
      }
    }

    .deliveryAddressSelect {
      background-color: var(--F9CB76-D7AA70);

      > span {
        color: var(--673E1D-5F391B);
      }

      svg {
        path {
          fill: var(--673E1D-5F391B);
        }
      }
    }
  }
}

.selectedDeliveryAddress {
  border: 1px solid var(--7F78E3-8782C4);

  &:hover {
    border: 1px solid var(--7F78E3-8782C4);
  }
}

.expDeliveryAddress {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--EDECFE-2D2B46);
  border: 1px solid var(--7F78E3-8782C4);
  border-bottom: none;
  margin-bottom: 0 !important;

  .deliveryAddressIcons {
    > div:nth-child(1) {
      svg {
        display: block;
      }
    }
  }

  .deliveryAddressIcon {
    background-color: var(--F4F4F6-313135);

    path {
      fill : var(--746BB3-A39ED1);
    }
  }

  .deliveryAddressName {
    > span {
      color: var(--36363A-FFFFFF);
      font-family: "Roboto-Medium", sans-serif;
    }
  }

  .deliveryAddressSelect {
    background-color: var(--F9CB76-D7AA70);

    > span {
      color: var(--673E1D-5F391B);
    }

    svg {
      path {
        fill: var(--673E1D-5F391B);
      }
    }
  }
}

.deliveryAddressInfo, .expDeliveryAddressInfo {
  display: flex;
  align-items: center;
}

.deliveryAddressIcon, .expDeliveryAddressIcon {
  border-radius: 6px;
  background-color: var(--FAFAFB-27272B);
  height: 40px;
  width: 40px;
  svg {
    margin: 8px;
    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46) !important;
    path {
      fill: var(--746BB3-A39ED1) !important;
    }
  }
}

.expDeliveryAddressIcon {
  background-color: var(--AFABED-4F498D);
  transform: rotate(180deg);

  path {
    fill: #FFFFFF !important;
  }

  &:hover {
    background-color: var(--AFABED-4F498D) !important;

    path {
      fill: #FFFFFF !important;
    }
  }
}

.deliveryAddressNameSection {
  display: flex;
  flex-direction: column;
  margin-left: 24px;

  > div:nth-child(2) {
    margin-top: 4px;
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: #8D8D96;
  }
}

.deliveryAddressName {
  > span {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.deliveryAddressIcons {
  display: flex;
  align-items: center;
}

.deliveryAddressSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);
  height: 40px;
  width: 117px;
  padding: 7px 12px 7px 16px;

  > span {
    text-transform: uppercase;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    line-height: 23px;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      transform: translate(8px, 4px);
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    background-color: var(--E8C187-BE8F60) !important;
  }
}

.deliveryAddressDetailsSection {
  margin-bottom: 16px;
  padding: 40px 32px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
  border: 1px solid var(--7F78E3-8782C4);
  border-top: none;

  > div:not(:last-child) {
    margin-bottom: 32px;
  }

  > div:nth-child(1) {
    display: flex;

    > div {
      width: 50%;

      &:first-child {
        margin-right: 24px;
      }
    }
  }

  > div:nth-child(2), > div:nth-child(3) {
    display: flex;

    > div:first-child {
      width: 66.6%;
      margin-right: 24px;
    }

    > div:last-child {
      width: 33.3%;
    }
  }
}

.collapseAnimation {
  transition: all 0.2s ease-out;
}
