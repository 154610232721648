.beneficiaryInfo {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
}

.beneficiaryInfoContainer {
  height: 100%;
}

.beneficiaryInfoHeader {
  display: flex;
  justify-content: space-between;

  > div:nth-child(1) {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--2C2C30-FFFFFF);
  }

  > div:nth-child(2) {
    display: flex;
    cursor: pointer;

    path {
      fill: var(--FCB241-DD994B);
    }

    &:hover {
      path {
        fill: var(--F19304-DD994B);
      }

      > span {
        color: var(--8C5020-F8C6A0);
      }
    }

    > span {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      text-transform: uppercase;
      color: var(--AF6931-DFBEA4);
      margin-right: 8px;
    }
  }
}

.activeNewAddBeneficiary {
  display: flex;
  cursor: pointer;

  path {
    fill: var(--F19304-DD994B);
  }

  > span {
    color: var(--673E1D-FDEEE2) !important;
  }
}

.beneficiaryInfoMainSection {
  height: 100%;
}

.addNewBeneficiarySection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div:nth-child(2) {
    font-family: 'Jost', sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    color: var(--606068-BCBCC2);
    margin-bottom: 16px;
    margin-top: 48px;
  }

  > div:nth-child(3) {
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    color: #8D8D96;
    width: 325px;
    text-align: center;
  }
}

.emptyBeneficiarySection {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  > div {
    > div:nth-child(1) {
      font-family: 'Jost', sans-serif;
      font-size: 24px;
      font-weight: 500;
      line-height: 32px;
      color: var(--606068-BCBCC2);
      margin-bottom: 4px;
    }

    > div:nth-child(2) {
      font-family: 'Roboto', sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: #8D8D96;
      width: 360px;
    }
  }
}

.emptyCompanyText {
  margin-left: 24px;
}

.emptyBeneficiaryText {
  margin-left: 24px;
  margin-bottom: 32px;
}

.emptyBeneficiarySection {
  > div:nth-child(3) {
    width: 380px;
  }
}

.beneficiaryInfoSearch {
  margin-top: 18px;
  padding: 12px 12px 12px 36px;
  margin-bottom: 16px;
  left: 0;
  right: 0;

  > input {
    font-size: 16px;
    line-height: 24px;
  }
}

.filteredBeneficiaries {
  scroll-margin-left: 20px;
  height: calc(100% - 105px);
  margin-right: -35px;
  padding-right: 27px;
  margin-top: 16px;
  overflow-y: auto;
}

.filteredBeneficiariesWithAddBeneficiaries {
  scroll-margin-left: 20px;
  height: calc(100% - 347px);
  margin-right: -35px;
  padding-right: 27px;
  margin-top: 16px;
  overflow-y: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;

  &-track {
    background: #FFF;
    border-radius: 4px;
  }

  &-thumb {
    background: #ECECED;
    border-radius: 4px;
  }
}

.limitBankTransferInfoSection {
  margin-top: 30px;
  margin-bottom: 6px;
}
