@import '~theme/variables.less';

.price {
  font-family: "Roboto-Medium", sans-serif;
  color: var(--36363A-FFFFFF);
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;

  &.title {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: right;
    color: var(--36363A-FFFFFF);

    white-space: nowrap;
  }

  &.big {
    font-family: "Roboto", sans-serif;
    color: var(--9F9FA7-7A7A84);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
  }

  > span > div {
    display: flex;

    > span:nth-child(1) {
      margin-right: 2px;
    }
  }
}

.emptyDash {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: right;
  color: var(--9F9FA7-7A7A84) !important;

  white-space: nowrap;
}
