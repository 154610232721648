.exchangeRateContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.exchangeRateInputs {
  display: flex;
  align-items: center;
  width: 100%;

  > div {
    margin-bottom: 0;
  }

  > div:nth-child(1), > div:nth-child(3) {
    width: 100%;
  }

  > div:nth-child(2) {
    padding: 12px;
    border-radius: 8px;
    margin: 0 24px;
    background-color: var(--FAFAFB-27272B);

    svg {
      path {
        fill: var(--B3B3B9-686871);
      }
    }
  }
}

.exchangeRateInfo {
  display: flex;
  margin-top: 24px;
  margin-bottom: 30px;

  > span {
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    color: var(--9F9FA7-7A7A84);
    margin-right: 3px;
  }

  > span:nth-child(2) {
    margin: 0 3px;
  }

  > span:nth-child(3) {
    font-family: Roboto, 'sans-serif';
    color: var(--7F78E3-8782C4);
    margin-right: 3px;
  }

  > span:nth-child(2), > span:nth-child(4) {
    font-family: Roboto-Medium, sans-serif;
    color: var(--606068-BCBCC2);
  }

  > span:nth-child(4) {
    display: flex;
  }
}

.exchangeRateValue {
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto-Medium, sans-serif;
  color: var(--606068-BCBCC2);
  margin-right: 3px;

  > span > div > span {
    margin-right: 0 !important;
  }
}
