.readyReportModal {
  :global {
    .ant-modal-content {
      min-width: 504px;
      width: max-content;
      padding: 32px;
    }

    .ant-modal-title {
      font-family: Jost, 'sans-serif';
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.2px;
      color: var(--36363A-FFFFFF);
      background-color: var(--FFFFFF-222225);
    }
  }
}

.reportModalSubTitle {
  max-width: 440px;
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  margin-bottom: 24px;

  > span:nth-child(2), > span:nth-child(4) {
    font-weight: 600;
  }
}

.reportModalMainContainer {
  border-radius: 12px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 1px solid var(--ECECED-3D3D42);
  background-color: var(--FAFAFB-27272B);
  padding: 17px 24px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
  }
}

.reportModalItemTypeIcon {
  margin-right: 24px;
  height: 40px;
  margin-top: 5px;

  svg {
    path {
      transform: scale(1.4) translate(-4px, -3.5px);
      fill: var(--D9D9DC-4E4E55)
    }
  }
}

.reportModalDate {
  white-space: nowrap;
  display: flex;
  align-items: center;
  height: 24px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.reportModalNewLabel, .reportModalFailedLabel {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: var(--E2F3ED-173B35);
  height: 20px;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: var(--12836E-74BEA5);
    margin-right: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
  svg * {
    width:  100%;
    height: 100%;
  }
}

.reportModalFailedLabel {
  background-color: var(--FBE5E7-3A1D1E) !important;
  white-space: nowrap;

  > span {
    color: var(--D92638-F45258) !important;
  }

  svg {
    path {
      fill: var(--E15C69-C7383D);
    }
  }
}

.reportModalGeneratingLabel {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: var(--EDECFE-2D2B46);
  height: 20px;
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--7F78E3-8782C4);
}

.reportModalItemSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--9F9FA7-7A7A84);
  margin-top: 4px;
}

.reportModalButtons {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;

  > button {
    width: 100%;
    height: 40px;
  }
}

.spinContainer {
  position: relative;
  margin-left: 24px;
  width: 32px;
  height: 32px;

  > div {
    width: 32px !important;
    height: 32px !important;
    top: 0 !important;
    left: 0 !important;
    border: 4px solid var(--EDECFE-736DBB) !important;
    border-top: 4px solid var(--746BB3-2D2B46) !important;
  }
}
