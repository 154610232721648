.trialSubscriptionContainer {
  display: flex;
  width: 100%;
  height: 100%;
}

.trialSubscriptionLeftSection {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: end;
  margin-right: 48px;
  width: 50%;
  background-color: var(--F4F4F6-313135);
  position: relative;

  > div {
    width: 576px;
    z-index: 1;
  }

  > div:nth-child(3) {
    margin-bottom: 16px;
  }

  button {
    margin-top: 48px;
    height: 48px;
  }

  > img {
    position: absolute;
    left: inherit;
    right: 48px;
    bottom: 41px;
    width: 776px;
    height: 667px;
  }

  @media (max-width: 1440px) {
    width: 56.25%;
  }

  @media (min-width: 1680px) {
    > img {
      right: 12.9%;
    }
  }

  @media (min-width: 1920px) {
    > img {
      left: 13.75%;
    }
  }

  @media (min-height: 900px) {
    > img {
      bottom: 16%;
    }
  }

  @media (min-height: 1050px) {
    > img {
      bottom: 22%;
    }
  }

  @media (min-height: 1080px) {
    > img {
      bottom: 29%;
    }
  }
}

.trialSubscriptionLeftSectionTitle {
  font-family: Jost, 'sans-serif';
  font-size: 40px;
  letter-spacing: 0.2px;
  font-weight: 700;
  text-transform: uppercase;
  color: var(--36363A-FFFFFF);
  margin-bottom: 24px;
}

.trialSubscriptionLeftSectionSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 18px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.trialSubscriptionLeftSectionBoldSubTitle {
  font-family: Roboto-Medium, 'sans-serif';
  color: var(--36363A-FFFFFF);
}

.trialSubscriptionRightSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  background: url(assets/images/trialPaidSubscriptionBg.webp) lightgray 50% center no-repeat;
  background-size: cover;

  @media (max-width: 1439px) {
    width: 43.75%;
  }

  @media (min-width: 1920px) {
    justify-content: flex-start;
    padding-left: 276px;
  }
}