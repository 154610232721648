.exchangeAmountInput {
  position: relative;
  width: 100%;
  height: 72px;

  .input {
    width: 100%;
    font-family: Roboto-Medium, 'sans-serif';
    border: 1px solid var(--ECECED-3D3D42);
    border-radius: 4px;
    color: var(--36363A-FFFFFF);
    background-color: var(--FFFFFF-222225);
    font-size: 28px;
    line-height: 40px;
    padding: 14px 14px 14px 16px;

    &:hover {
      border-radius: 4px;
    }
  }

  .input:focus {
    border: 1px solid var(--7F78E3-736DBB) !important;
    border-radius: 4px;
  }
}