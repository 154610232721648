.sendReportModal {
  max-height: 242px;

  :global {
    .ant-modal-header {
      margin-bottom: 32px;
    }

    .ant-modal-content {
      width: 504px;
      padding: 32px;
    }

    .ant-modal-title {
      font-family: Jost, 'sans-serif';
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      letter-spacing: 0.2px;
      color: var(--36363A-FFFFFF);
      background-color: var(--FFFFFF-222225);
    }
  }
}

.sendReportModalContainer {
   > div {
     position: relative;

      > div {
        position: absolute;
      }
   }
}

.sendReportModalButtons {
  display: flex;
  margin-top: 32px;
  justify-content: space-between;

  > button {
    width: 100%;
    height: 40px;
  }

  > button:nth-child(1) {
    display: flex;
    align-items: center;
    margin-right: 32px;
    border-radius: 6px;
    padding: 7px 16px 7px 0;
    background-color: var(--FFFFFF-222225);
    font-family: Roboto-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--AF6931-DFBEA4);
    cursor: initial;

    > span {
      cursor: pointer;
    }

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }

  > button:nth-child(2) {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    padding: 7px 16px;
    background-color: var(--F9CB76-D7AA70);
    font-family: Roboto-Medium, sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--673E1D-5F391B);

    &:hover {
      background: var(--E8C187-BE8F60);
    }
  }
}

.disabledSendReportSendBtn {
  background: var(--F4F4F6-313135) !important;
  color: var(--9F9FA7-7A7A84) !important;
  pointer-events: none;
}
