.checkBoxStyles {
  :global {
    .ant-checkbox-checked:after {
      border-color: var(--7F78E3-8782C4)!important;
    }
    .ant-checkbox-checked .ant-checkbox-inner {
      background-color: var(--746BB3-736DBB) !important;
      border: 2px solid var(--746BB3-736DBB) !important;
    }
    .ant-checkbox .ant-checkbox-inner {
      width: 16px;
      height: 16px;
      background-color: var(--FFFFFF-222225);
      border-radius: 2px;
      border: 2px solid var(--B3B3B9-686871)
    }
    .ant-checkbox+span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      letter-spacing: 0;
      text-align: left;

      color: var(--606068-BCBCC2);
    }
    .ant-checkbox .ant-checkbox-inner:after {
      top: 45% !important;;
    }
    .ant-checkbox:not(.ant-checkbox-disabled):hover .ant-checkbox-inner {
      border: 2px solid var(--7F78E3-8782C4);
    }
    .ant-checkbox-indeterminate .ant-checkbox-inner:after {
      background-color: var(--FFFFFF-222225);
      height: 2px;
      top: 50% !important;
    }
  }
}
