.dashboardAccountContainer {
  width: 50%;
  height: 100%;
  padding: 40px 48px;

  @media (min-width: 1920px) {
    padding-left: 0;
  }
}

.dashboardAccountNavigation {
  display: flex;
  margin-bottom: 30px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}

.accountHeader {
  display: flex;
  justify-content: space-between;
  margin-bottom: 26px;
  height: 42px;

  > button {
    width: 157px;
    margin-top: 2.5px;
    margin-bottom: 0.5px;

    svg {
      margin-left: 8px;
    }
  }
}

.accountHeaderInfo {
  display: flex;
  align-items: center;
  margin-bottom: 6px;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(2), > div:nth-child(3) {
    font-family: "Roboto", sans-serif;
    line-height: 24px;
    font-size: 18px;
  }

  > div:nth-child(2) {
    width: 4px;
    height: 4px;
    margin: 4px 8px 0 8px;
    background-color: var(--B3B3B9-686871);
    border-radius: 50%;
  }

  > div:nth-child(3) {
    color: var(--B3B3B9-686871);
    margin-top: 5px;
  }
}

.accountHeaderShimmer {
  width: 400px;
  background-color: var(--ECECED-3D3D42);
  margin-bottom: 34px;
}

.accountBalance {
  width: 100%;
  height: 126px;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
}

.availableBalanceSection, .bookBalanceSection {
  width: 50%;
  height: 62px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > span:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 6px;
    color: var(--606068-BCBCC2);
  }

  &:first-child {
    border-right: 1px solid var(--ECECED-3D3D42);
  }
}

.availableBalanceSection {
  padding: 4px 16px 0 24px;
}

.bookBalanceSection {
  padding: 4px 24px 0 16px;
}

.accountBalanceAmount {
  font-family: Jost, 'sans-serif';
  line-height: 32px;
  font-size: 24px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.accountBalanceShimmer {
  width: 100%;
  background-color: var(--FAFAFB-27272B);
}

.dashboardAccountButtons {
  display: flex;
  justify-content: center;

  > button:not(:nth-child(3)) {
    margin-right: 12px;
  }

  > div {
    position: relative;
  }
}

.accountButton, .accountReportButton, .accountMultiPointButton, .accountButtonActive {
  display: flex;
  width: 128px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 32px;
  padding: 5px 16px 5px 12px;
  background-color: var(--F9CB76-D7AA70);
  font-family: "Roboto-Medium", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: var(--673E1D-5F391B);
  text-transform: initial;

  > svg {
    margin-right: 8px;
    margin-bottom: 1px;

    path {
      fill: var(--673E1D-5F391B);
    }
  }

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }
}

.accountButtonActive {
  background-color: var(--FCB241-DD994B);
}

.accountReportButton {
  width: 95px !important;
  border-radius: 6px 0 0 6px !important;
  margin-right: 1px;
}

.accountMultiPointButton {
  width: 32px !important;
  border-radius: 0 6px 6px 0 !important;
  padding: 0 !important;
  position: static !important;

  > div {
    display: none !important;
  }

  > span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--673E1D-5F391B);

    &:not(:nth-child(3)) {
      margin-right: 3px;
    }
  }
}

.reportsSelector {
  position: absolute;
  width: 208px;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--FFFFFF-222225);
  right: 0;
  top: 36px;
  box-shadow: var(--B2BEC340-131316CC);

  > div {
    padding: 10px 12px 10px 14px;
    font-family: Roboto, 'sans-serif';
    line-height: 20px;
    font-size: 14px;
    color: var(--606068-BCBCC2);

    &:hover {
      cursor: pointer;
      background-color: var(--FAFAFB-27272B);
    }
  }
}

.inwardDetailsModal {
  svg {
    path {
      fill: var(--B3B3B9-686871);
    }
  }
  :global {
    .ant-modal-content {
      box-shadow: var(--B2BEC340-131316CC);
      min-width: 408px;
    }
    .ant-modal-header {
      padding: 32px 32px 8px 32px;
      border-bottom: none;
      margin-bottom: 0;

      > div {
        font-family: "Jost", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: var(--36363A-FFFFFF);
      }
    }
    .ant-modal-body {
      padding: 0 32px;
    }

    .ant-modal-close {
      top: 35px;
    }
  }
}

.detailsHeader {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  font-weight: 400;
  color: var(--58585F-BCBCC2);
}

.inwardDetailsBody {
  margin-top: 32px;
  position: relative;
}

.inputContainer {
  font-family: "Roboto", sans-serif;
  margin-bottom: 24px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  height: 48px;

  input {
    padding-right: 35px !important;
  }
}

.inputContainerWithError {
  font-family: "Roboto", sans-serif;
  margin-bottom: 48px;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;
  height: 48px;
}

.inputContainerWithError > div > input {
  color: var(--B3B3B9-7A7A84) !important;
}

.buttonContainer {
  padding: 32px 0;
}

.buttonsContainer {
  display: flex;
  flex-direction: row-reverse;
  padding: 32px 0;
}

.footerTitle {
  height: 32px;
  color: var(--9F9FA7-7A7A84);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 12px;
  margin-left: 14px;
  white-space: break-spaces;
}

.button {
  font-family: "Roboto-Medium", sans-serif;
  background: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 23px;
  cursor: pointer;

  &:hover {
    background: var(--E8C187-BE8F60);
  }

  svg {
    margin-left: 8px;
  }

  path {
    fill: var(--673E1D-5F391B) !important;
  }
}

.buttonReload {
  font-family: "Roboto", sans-serif;
  background: var(--FEF7E9-36302B);
  color: var(--AF6931-DFBEA4);
  font-size: 16px;
  font-weight: 500;
  height: 40px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  line-height: 23px;
  margin-right: 32px;
  cursor: pointer;

  &:hover {
    background: var(--FDE8C0-554639);
    color: var(--8C5020-F8C6A0);

    path {
      fill: var(--F19304-DD994B);
    }
  }

  svg {
    margin-left: 8px;
  }

  path {
    fill: var(--FCB241-DD994B)!important;
  }
}

.buttonReloadClick {
  background: var(--FDE8C0-554639);
  svg {
    font-size: 13px;
    margin: 0;
  }
}

.emptyError {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--9F9FA7-7A7A84);
  margin-left: 14px;
}

.referenceNumber, .emptyReferenceNumber {
  height: 48px;
  margin-bottom: 4px;
}

.emptyReferenceNumber > div > input {
  color: var(--B3B3B9-7A7A84)!important;
}

.copyTooltipStyle {
  :global {
    .ant-tooltip-content {
      left: 16px !important;
    }

    .ant-tooltip-arrow {
      right: 2px !important;
      left: initial !important;
    }
  }
}
