.containerShimmer {
  height: 32px;
  border-radius: 8px;
}

.accountOptionShimmer {
  border-radius: 4px;
  height: 48px;
  background-color: var(--F4F4F6-313135);
}
