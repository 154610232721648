&:root[data-theme="light"] {
  .virtualCardLandingContainer {
    background: url(assets/images/virtualCardLanding.webp) lightgray 50% / cover no-repeat;

    &:after {
      content: ' ';
      right: 30px;
      top: 90px;
      position: absolute;
      width: 910px;
      height: 407px;
      border-radius: 1133.022px;
      opacity: 0.9;
      background: #F6E8D0;
      filter: blur(72.15000153px);
    }

    @media (max-width: 1750px) {
      &:after {
        min-width: 730px;
        width: calc(100vw - 840px);
      }
    }

    @media (min-width: 2250px) {
      &:after {
        right: 11%;
      }
    }

    @media (min-width: 2720px) {
      &:after {
        right: 14%;
      }
    }
  }
}

&:root[data-theme="dark"] {
  .virtualCardLandingContainer {
    background: url(assets/images/virtualCardDarkLanding.webp) lightgray 50% / cover no-repeat;
  }
}

.virtualCardLandingContainer {
  position: relative;
  padding: 133px 140px;
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  z-index: 2;

  > div {
    z-index: 1;

    @media (min-width: 1920px) {
      max-width: 1552px;
      width: calc(100vw - 458px);
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  @media (max-width: 1439px) {
    background-size: 2300px 100%;
  }
}

.virtualCardLandingSection {
  display: flex;
  flex-direction: column;

  > div:nth-child(1) {
      font-family: Jost, 'sans-serif';
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.2px;
      margin-bottom: 48px;
      color: var(--36363A-FFFFFF);
      text-transform: capitalize;
  }

  > button {
      width: 275px;
      height: 48px;
  }
}

.virtualCardLandingDescription {
  display: flex;
  margin-bottom: 48px;

  > div {
    width: 275px;
  }

  > div:nth-child(1) {
    margin-right: 20px;
  }
}

.virtualCardLandingDescriptionSection {
  display: flex;
  flex-direction: column;

  > div {
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-FFFFFF);
  }
}