.lastGeneratedReports {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--FFFFFF-222225);
  padding: 49px 48px 0 48px;

  @media (max-width: 1440px) {
    width: 46%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 57.5%;
  }

  @media (min-width: 1920px) {
    padding-right: 0;
  }
}

.lastGeneratedReportsHeader {
  margin-bottom: 33px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastGeneratedReportsTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.lastGeneratedReportsViewAll {
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    > span {
      color: var(--8C5020-F8C6A0);
    }
    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.lastGeneratedReportsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;

  > div > div:not(:last-child) {
    margin-bottom: 24px;
  }
}

.emptyLastReports {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80%;

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--606068-BCBCC2);
    margin-top: 48px;
    margin-bottom: 24px;
  }

  > div:nth-child(3) {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: #8D8D96;
    width: 340px;
    text-align: center;
  }
}
