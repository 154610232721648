.successSubscriptionModalContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding-top: 24px;
  padding-bottom: 40px;
}

.successSubscriptionModal {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  width: 640px;
  height: 100%;
  max-height: 912px;
  background-color: var(--FFFFFF-222225);

  > img {
    margin-bottom: 24px;
    width: 118px;
    height: 118px;
  }

  > button {
    width: 240px;
    height: 40px;
  }
}

.successSubscriptionModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 16px;
}

.successSubscriptionModalSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  margin-bottom: 8px;
  color: var(--606068-BCBCC2);
  text-align: center;
}

.successSubscriptionModalDetails {
  margin-bottom: 48px;
  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    color: var(--7F78E3-8782C4);
    cursor: pointer;

    &:hover {
      color: var(--564C94-A39ED1);
    }
  }
}