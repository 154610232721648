.switchContainer {
  :global {
    .ant-switch {
      width: 46px;
      height: 24px;
    }
    .ant-switch-handle {
      width: 20px;
      height: 20px;

      &:before {
        box-shadow: none;
        width: 20px;
        height: 20px;
        border-radius: 50% !important;
        inset-inline-start: calc(100% - 20px) !important;
      }
    }

    .ant-switch-checked .ant-switch-handle {
      inset-inline-start: calc(100% - 22.5px) !important;
    }

    .ant-switch .ant-switch-handle {
      inset-inline-start: 2.5px;
    }

    .ant-switch.ant-switch-disabled {
      background-color: var(--F4F4F6-313135) !important;
      opacity: 1;

      .ant-switch-handle:before {
        background-color: var(--FAFAFB-27272B);
      }
    }

    .ant-switch-checked.ant-switch-disabled {
      background-color: var(--EDECFE-2D2B46) !important;
      opacity: 1;

      .ant-switch-handle:before {
        background-color: var(--FAFAFB-27272B);
      }
    }
  }
}
