@import '~theme/variables.less';

.notFoundContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100vh - 64px);
  position: absolute;
  width: 100%;
  left: 0;
  background-color: var(--FAFAFB-27272B);
}

.btn {
  font-family: Roboto-Medium, sans-serif;
  background: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  border-radius: 6px;
  width: 320px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;

  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background: var(--E8C187-BE8F60);
  }
}

.title {
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 28px;
  line-height: 36px;
  text-align: center;
  color: var(--36363A-FFFFFF);
  margin-top: 72px;
}

.subTitle {
  width: 545px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 18px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  margin-top: 16px;
  margin-bottom: 48px;
  color: var(--606068-BCBCC2);
}
