.appSelectWithSearch {
  position: relative;
}

.searchContainer {
  position: relative;

  input {
    width: 100%;
    border: 1px solid var(--ECECED-3D3D42);
    border-radius: 4px;
    color: var(--2C2C30-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 12px 13px 14px;
    background-color: var(--FFFFFF-222225);

    &:hover {
      border: 1px solid var(--7F78E3-736DBB);
      border-radius: 4px;
    }
  }

  input::placeholder {
    color: transparent;
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  input:focus {
    border: 1px solid var(--7F78E3-736DBB);
    border-radius: 4px;
    outline: none;
  }

  input:focus::placeholder {
    color: var(--9F9FA7-7A7A84);
  }

  input:focus + span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    opacity:1;
    padding: 0 6px 4.5px 6px;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-736DBB);
    background: var(--FFFFFF-222225);
  }

  > span {
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 12px 13px 14px;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
  }

  > img {
    position: absolute;
    top: 14px;
    left: 16px;
  }
}

.searchIcon {
  position: absolute;
  top: 11px;
  left: 14px;

  path {
    fill: var(--7F78E3-736DBB);
  }
}

.inputArrow, .inputUpArrow {
  position: absolute;
  right: 8px;
  top: 14px;
  cursor: pointer;
}

.inputUpArrow {
  transform: rotate(180deg);

  path {
    fill: var(--7F78E3-736DBB);
  }
}

.dropdown {
  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  box-shadow: var(--B2BEC340-131316CC);
  width: 100%;
  z-index: 100;
  background-color: var(--FFFFFF-222225);
  top: 54px;
  overflow-y: auto;
  max-height: 344px;
}

.dropdownItem, .emptyItem, .selectedDropdownItem {
  display: flex;
  align-items: center;
  padding: 0 16px;
  min-height: 56px;
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  font-family: "Roboto", sans-serif;
  font-weight: 400;

  > div {
    display: flex;
    align-items: center;
  }

  img {
    margin-right: 12px;
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
    cursor: pointer;
  }

  .highlightedElements {
    font-family: Roboto-Medium, sans-serif;
    color: var(--36363A-FFFFFF)
  }
}

.dropdownItemWithSubTitle {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;

  > span:nth-child(2) {
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    margin-top: 2px;
  }
}

.dropdownSpanWithSubTitle {
  font-family: Roboto-Medium, sans-serif;
}

.selectedDropdownItem {
  background-color: var(--EDECFE-2D2B46);
}

.selectWithValue {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
  opacity: 1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(7px);
  background: var(--FFFFFF-222225);
  color: #8D8D96;
}

.emptyItem {
  color: var(--9F9FA7-7A7A84);

  svg {
    margin-right: 12px;
    path {
      fill: var(--D9D9DC-4E4E55);
    }
  }

  &:hover {
    background-color: var(--FFFFFF-222225);
    cursor: default;
  }
}

.openDropdownSearchInput, .openDropdownSearchInputWithImg {
  border: 1px solid var(--7F78E3-736DBB) !important;

  & + span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    opacity: 1;
    padding: 0 6px 4.5px 6px !important;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-736DBB);
    background: var(--FFFFFF-222225);
  }
}

.openDropdownSearchInputWithImg, .searchInputWithImg {
  padding-left: 51px !important;
}
