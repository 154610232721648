@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  overflow:auto;
  width: 100%;
  background-color: var(--FFFFFF-222225);

  @media (max-width: 900px) {
    width: 1000px;
    overflow-x: auto;
  }
}

.signInLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 144px;
  }

   & .title {
     color: var(--36363A-FFFFFF);
     font-family: "Jost", sans-serif;
     font-size: 28px;
     font-weight: 500;
     line-height: 36px;
     text-align: center;

     width: 258px;
     margin-bottom: 16px;
   }

  & .description {
    color: var(--606068-BCBCC2);
    font-family: "Roboto",sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;
    width: 320px;

    & .bold {
      color: var(--606068-BCBCC2);
      font-family: "Roboto-Medium",sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 24px;
      text-align: center;
      width: 320px;
    }
  }

  & .browserContainer {
    display: flex;

    > div {
      width:  192px;
      height: 188px;
      padding: 24px;
      border-radius: 12px;
      margin-top: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      > div {
        margin-top: 12px;
        display: flex;
        justify-content: center;
        align-items: center;
        > span {
          color: var(--7F78E3-8782C4);
          margin-right: 8px;
        }
        > svg {
          path {
            fill: var(--FCB241-DD994B);
          }
        }
      }

      &:hover {
        background-color: var(--FAFAFB-27272B);
        cursor: pointer;

        > div {
          > span {
            color: var(--564C94-A39ED1);
          }
          > svg {
            path {
              fill: var(--F19304-FEBD72);
            }
          }
        }
      }
    }
  }

  & .logo {
    margin-bottom: 104px;
    svg {
      width: 140px;
      height: 51px;
    }
  }

  & .footer {
    display: flex;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    font-family: @font-family-roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 42px;
  }
}

.signInRightSection {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}
