.multipleAccounts {
  width: 50%;
  height: 100%;
  padding: 40px 48px;
  overflow-x: hidden;
  overflow-y: auto;

  @media (min-width: 1920px) {
    padding-left: 0;
  }

  > div:last-child {
    > div:not(:last-child) {
      margin-bottom: 24px;
    }
  }
}

.multipleAccountsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 26px;

  > div {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
    color: var(--36363A-FFFFFF);
  }

  > button {
    width: 157px;
    margin-top: 2.5px;
    margin-bottom: 0.5px;

    svg {
      margin-left: 8px;
    }
  }
}

.multipleAccount {
  border-radius: 12px;
  width: 100%;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  height: 116px;
  padding: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &:hover {
    cursor: pointer;
    border: 1px solid var(--AFABED-4F498D);
  }

  > div {
    display: flex;
    align-items: center;

    > img {
      width: 48px;
      height: 48px;
      border-radius: 50%;
      margin-right: 24px;
    }
  }

  > button {
    width: 85px;
    height: 32px;
    text-transform: initial;
    padding: 5px 12px 5px 16px;

    > span {
      font-size: 14px;
      line-height: 20px;
    }

    svg {
      width: 16px;
      height: 16px;
      margin-left: 8px;

      path {
        transform: translateY(14px) translateX(4px) rotate(-90deg);
        fill: var(--FCB241-DD994B);
      }
    }
  }
}

.multipleAccountInfo {
  > div:nth-child(1) {
    margin-bottom: 4px;

    > span:nth-child(1) {
      font-family: Roboto-Medium, sans-serif;
      font-size: 18px;
      line-height: 24px;
      color: var(--36363A-FFFFFF);
      margin-right: 8px;
    }

    > span:nth-child(2) {
      border-radius: 16px;
      padding: 2px 8px;
      height: 24px;
      background-color: var(--FAFAFB-27272B);
      font-family: Roboto, 'sans-serif';
      font-size: 14px;
      line-height: 20px;
      color: #8D8D96;
    }
  }
}

.accountBalanceAmount {
  font-family: Jost, 'sans-serif';
  line-height: 36px;
  font-size: 28px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.containerShimmer {
  border-radius: 12px;
  width: 100%;
  background-color: var(--ECECED-3D3D42);
  height: 126px;
}

.multipleAccountsHeaderShimmer {
  background-color: var(--ECECED-3D3D42);
  width: 320px;
}
