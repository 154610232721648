@import '~theme/variables.less';

.headerWrapper {
  height: 64px;
  position: relative;
}

.header {
  padding: 0 !important;
  position: fixed !important;
  width: 100vw;
  top: 0;
  z-index: 190;
  border-bottom: 1px solid var(--EAEAEB-3D3D42);

  @media (min-width: 1920px) {
    display: flex;
    justify-content: center;
  }

  :global {
    .ant-page-header-heading {
      background-color: var(--FFFFFF-222225);
      height: 64px;
      min-width: max-content;

      @media (min-width: 1920px) {
        max-width: 1552px;
        min-width: 1552px;
        margin-left: 240px;
      }
    }
    .ant-page-header-heading-left {
      margin: 0;
    }
    .ant-page-header-heading-title {
      margin-right: 0;

      > div {
        display: flex;
        margin-left: 24px;

        @media (min-width: 1920px) {
          margin-left: 0;
        }

        svg {
          width: 76px;
          height: 27px;
        }
      }
    }
    .ant-page-header-heading-extra {
      display: flex;
      margin: 0 16px 0 16px;

      @media (min-width: 1920px) {
        margin-right: 0;
      }
    }
  }
}

.extra {
  display: flex;
  justify-content: center;
  align-items: center;
}

.imageContainer {
  cursor: pointer;
}
