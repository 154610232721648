.transactionStatus {
  text-transform: lowercase;

  &:first-letter {
    text-transform: uppercase;
  }
}

.transactionSuccessStatus {
  color: var(--12836E-74BEA5);
}

.transactionPendingStatus {
  color: var(--D66B00-D48F49);
}

.transactionExpiredStatus {
  color: var(--D92638-F45258);
}

.transactionReversedStatus {
  color: var(--7F78E3-8782C4);
}