.cardsContainer, .cardsLandingContainer, .cardsTransparentContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;
  background-color: var(--FFFFFF-222225);

  @media (max-width: 1280px) {
    width: 1200px;
  }

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 0 auto;
  }
}

.cardsTransparentContainer {
  background-color: transparent;
}

.rightEmptyBlock {
  display: none;
  width: calc((100vw - 1552px - 240px) / 2);

  @media (min-width: 1920px) {
    display: block;
    position: absolute;
    height: 100%;
    right: calc((100vw - 1552px - 240px) / -2);
    top: 0;
    background-color: var(--FFFFFF-222225);
  }
}

.cardsLandingContainer {
  align-items: flex-start;

  @media (min-width: 1920px) {
    width: 100%;
  }
}

.stepperTitle {
  font-family: Jost, 'sans-serif';
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 40px;
  text-align: center;
}

.stepperBackBtn {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  height: 48px;
}
