.cardDetailsLimit {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  width: 50%;
  height: 100%;
  background: var(--FFFFFF-222225);
  padding: 49px 48px;
  position: relative;

  > div {
    display: flex;
    align-items: center;
    flex-direction: column;
  }

  > div > svg {
    position: absolute;
    top: 49px;
    right: 0;
    width: 24px;
    height: 24px;
    cursor: pointer;

    path {
      transform: scale(0.79) translate(8px, 8px);
    }
  }

  @media (max-width: 1440px) {
    width: 46%;
  }

  @media (max-width: 1919px){
    > div > svg {
      right: 48px;
    }
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 57.5%;
  }

  @media (min-width: 1920px) {
    padding-right: 0;
    > div > svg {
      right: 0;
    }
  }
}

.cardDetailsLimitTitle {
  font-family: Roboto-Medium, sans-serif;
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 8px;
}

.cardDetailsLimitSubTitle {
  width: 310px;
  text-align: center;
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
}

.limitProgressSection {
  margin-top: 64px;
}

.progressData {
  display: flex;
  flex-direction: column;
}

.progressDataSpent, .progressRedDataSpent, .progressPurpleDataSpent {
  font-family: Roboto-Medium, sans-serif;
  font-size: 28px;
  line-height: 40px;
  color: var(--9F9FA7-7A7A84);
}

.progressRedDataSpent {
  color: var(--D92638-F45258);
}

.progressPurpleDataSpent {
  color: var(--564C94-A39ED1);
}

.progressDataText {
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: #8D8D96;
}

.progressDataRemaining {
  margin-top: 8px;

  > span {
    font-family: Roboto-Medium, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
  }

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
  }
}

.limitInput, .noLimitInput {
  margin-top: 64px;

  > input {
    width: 310px !important;
    padding: 16px 14px 16px 16px !important;
    font-family: Roboto-Medium, sans-serif !important;
    font-size: 28px !important;
    line-height: 40px !important;
    color: var(--36363A-FFFFFF) !important;
    height: 72px !important;
  }

  > input::placeholder {
    font-family: Roboto, 'sans-serif' !important;
    color: var(--9F9FA7-7A7A84) !important;
    font-size: 28px !important;
    line-height: 40px !important;
  }
}

.noLimitInput {
  > input {
    font-family: Roboto, 'sans-serif' !important;
    color: #8D8D96 !important;
  }
}

.limitInputText {
  margin-top: 4px;
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--9F9FA7-7A7A84);
  margin-left: 16px;
  margin-bottom: 32px;
}

.saveLimitBtn {
  width: 310px;
  height: 48px;

  > svg {
    margin-left: 8px;
  }
}

.limitInputErrorInfo {
  display: flex;
  align-items: center;
  width: 100%;
  border-radius: 8px;
  padding: 8px 12px;
  background-color: var(--FBE5E7-3A1D1E);
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--36363A-FFFFFF);
  margin-top: -28px;

  > svg {
    margin-right: 10px;
    path {
      fill: var(--E15C69-C7383D);
    }
  }
}

.changeLimitModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  font-weight: 500;
  line-height: 32px;
  letter-spacing: 0.2px;
  text-align: center;
  width: 350px;
  margin-bottom: 56px;
}

.changeLimitModalButtons {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > button:nth-child(1) {
    padding: 0;
  }

  > div {
    display: flex;

    > button:nth-child(1) {
      width: 245px;
      margin-right: 24px;
    }
    > button:nth-child(2) {
      width: 136px;
      padding: 0;

      > svg {
        margin-left: 8px;
      }
    }
  }
}

.cardDetailsLimitNotification {
  width: 512px !important;
}
