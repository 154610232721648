.bankAccountContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 40px;
  height: 100%;
}

.bankAccountMainSection {
  height: 100%;
}

.beneficiaryInfo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;

    svg {
      margin-right: 24px;

      path {
        fill: var(--7F78E3-736DBB);
      }
    }

    div {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      line-height: 24px;
      color: var(--2C2C30-FFFFFF);
    }
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      path {
        fill: var(--F19304-DD994B);
      }

      > span {
        color: var(--8C5020-F8C6A0);
      }
    }

    span {
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 23px;
      font-weight: 500;
      text-transform: uppercase;
      color: var(--AF6931-DFBEA4);
      margin-right: 8px;
    }
  }
}

.bankAccountsSection {
  overflow-y: auto;
  padding-right: 40px;
  height: calc(100% - 64px);
  margin-right: -40px;

  &::-webkit-scrollbar {
    width: 8px;
    height: 4px;

    &-track {
      background: var(--FFFFFF-222225);
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 4px;
    }

    &-thumb {
      background: var(--ECECED-3D3D42);
      border-radius: 4px;
    }
  }
}

.limitBankTransferInfoSection {
  margin-bottom: 24px;
}
