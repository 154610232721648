.arrowButtons {
  display: flex;
  align-items: center;
  margin: 0 48px;
}
.paginationButtonPrev {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  margin-left: 8px;
}
.paginationButtonNext {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  line-height: 16px;
  font-size: 12px;
  margin-right: 8px;
}


.perPageContainer {
  width: 200px;
  position: absolute;
  display: flex;
  left: 0;
}

.perPageTitle {
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--9F9FA7-7A7A84);
  font-size: 12px;
}

.perPageSection {
  color: var(--36363A-FFFFFF);
  margin-left: 8px;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: Roboto-Medium, sans-serif;
  font-size: 12px;
  line-height: 16px;
  font-weight: 500;
}

.perPageArrow {
  display: flex;
  align-items: center;
  path {
    fill: var(--D9D9DC-4E4E55);
  }
  &:hover {
    path {
      fill: var(--7F78E3-8782C4);
    }
  }
}

.perPageArrowActive {
  path {
    fill: var(--7F78E3-8782C4);
  }
}

.perPage {
  display: flex;
  align-items: center;
  width: 56px;
  margin-left: 4px;
  &:hover {
    background-color: var(--EDECFE-2D2B46);
    border-radius: 4px;
  }
}

.perPageContainerActive {
  background-color: var(--EDECFE-2D2B46);
  border-radius: 4px;
}

.perPageActive {
  margin-right: 5px;
}

.perPageDropdown {
  width: 56px;
  min-width: 56px !important;
  box-shadow: var(--B2BEC340-131316CC);

  border-radius: 4px;
  border: 1px solid var(--BDB9F0-4F498D);
  background-color: var(--FFFFFF-222225);
  padding: 4px 0;
  left: 92px !important;
}

.perPageDropdownItem {
  padding: 8px;
  cursor: pointer;

  span {
    color: var(--606068-BCBCC2);
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
  }

  &.perPageDropdownItemActive {
    background-color: var(--EDECFE-2D2B46);

    span {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 16px;
      font-weight: 400;
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
  }
}
