@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  width: 100%;

  background: var(--FFFFFF-222225);
  background-size: cover;
  min-height: 102%;
  top: -2%;
}
