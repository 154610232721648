.reportDatePicker {
  margin: 32px 0;

  :global {
    .ant-picker-focused, .ant-picker:hover {
      border: 1px solid var(--7F78E3-8782C4);
    }

    .ant-picker-focused + span, .ant-picker:hover + span {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 16px;
      opacity:1;
      padding: 0 6px 4.5px 6px;
      transform: translateY(-40%) translateX(7px);
      color: var(--7F78E3-8782C4);
      background: var(--FFFFFF-222225);
    }
  }
}

.endOfDayPicker {
  display: flex;
}

.rangePicker {
  display: flex;
  flex-direction: column;
}

.endOfDayPickerPeriods {
  display: flex;
  margin-left: 24px;
  margin-top: 8px;

  > div {
    border-radius: 4px;
    padding: 6px 12px;
    height: 32px;

    &:nth-child(1) {
      margin-right: 8px;
    }
  }
}

.rangePickerPeriods {
  display: flex;
  align-items: center;
  margin-top: 12px;

  > div {
    border-radius: 4px;
    padding: 6px 12px;
    height: 32px;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.defaultPeriod, .activeDefaultPeriod {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
}

.defaultPeriod {
  background-color: var(--F4F4F6-313135);
  color: var(--606068-BCBCC2);

  &:hover {
    background-color: var(--ECECED-3D3D42);
    color: var(--36363A-FFFFFF);
    cursor: pointer;
  }
}

.activeDefaultPeriod {
  background-color: var(--B3B3B9-686871);
  color: #FFFFFF;

  &:hover {
    background-color: var(--B3B3B9-686871);
    color: #FFFFFF;
    cursor: pointer;
  }
}
