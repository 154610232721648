.insufficientBalanceModal {
  :global {
    .ant-modal-close {
      top: 32px;
      right: 32px;
    }
  }
}

.insufficientBalanceModalBody {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
  }

  > img {
    margin-top: 44px;
  }
}

.insufficientBalanceModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-top: 32px;
  text-align: center;
}

.insufficientBalanceModalDescription {
  text-align: center;
  margin-top: 8px;

  > span {
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    font-family: Roboto, sans-serif;
    color: var(--606068-BCBCC2);

    > span:nth-child(2) br {
      display: none;
    }
  }

  > span:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    color: var(--36363A-FFFFFF);
  }
}

.insufficientBalanceModalInput {
  margin-top: 24px;
}

.insufficientBalanceModalButtons {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  > button:nth-child(2) {
    width: 202px;

    svg {
      margin-right: 8px;
    }
  }
}