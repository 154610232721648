.modalContainer {
  :global {
    .ant-modal-body {
      border-top-right-radius: 12px;
      border-top-left-radius: 12px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 32px;

      background-color: var(--FFFFFF-222225);

      > img {
        margin-top: 32px;
      }
    }

    .ant-modal-footer {
      margin-top: 0;
    }
  }
}
.title {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;

  color: var(--36363A-FFFFFF);

  margin-top: 40px;
}

.subTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  color: var(--606068-BCBCC2);

  margin-top: 12px;
}

.modalBtnContainer {
  background-color: var(--FFFFFF-222225);
  padding: 0 48px 48px 48px;

  display: flex;
  justify-content: space-between;
  border-bottom-right-radius: 12px;
  border-bottom-left-radius: 12px;

  > button:nth-child(1) {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0;
  text-align: center;

  height: 48px;
  width: 186px;
  background-color: var(--FEF7E9-36302B);
  color: var(--AF6931-DFBEA4);
  text-transform: uppercase;
  margin-right: 32px;

  &:hover {
    background-color: var(--FDE8C0-554639);
    color: var(--8C5020-F8C6A0);
  }
}
> button:nth-child(2) {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;

  height: 48px;
  width: 186px;
  background-color: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    margin-left: 11px;
    path {
      fill: var(--673E1D-5F391B)
    }
  }

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }
}
}
