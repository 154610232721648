.accountSubscriptionBlockedBanner {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 19px 24px;
  background: url(assets/images/accountBlockedBannerBg.webp) lightgray 50% / cover no-repeat;
  margin: 24px 0;

  > button {
    width: 125px;
  }
}

.accountSubscriptionBlockedBannerDescription {
  max-width: 394px;
  margin-right: 19px;

  > div:nth-child(1) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: #FFFFFF;
    opacity: 0.8;
  }
}