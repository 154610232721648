.beneficiaryPageContainer {
  display: flex;
  width: 100%;
  height: calc(100% - 32px);
  border-radius: 12px;
  border: 1px solid var(--EAEAEB-3D3D42);
  max-height: 1044px;
}

.beneficiaryPageLeftSection {
  display: flex;
  flex-direction: column;
  width: 30.5%;
  background-color: var(--FAFAFB-27272B);
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;

  > div:nth-child(2) {
    margin-left: 40px;
  }
}

.beneficiaryPageRightSection {
  width: 69.5%;
  background-color: var(--FFFFFF-222225);
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
}

.beneficiaryPageLeftSectionTitle {
  font-family: 'Jost', sans-serif;
  font-size: 28px;
  font-style: normal;
  font-weight: 500;
  line-height: 36px;
  color: var(--2C2C30-FFFFFF);
  margin: 40px 0 40px 40px;
}

.mainContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--F2F2F2-313135);
  padding: 24px;

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 0 auto;
    padding: 24px 0;
  }
}

.transfersNavigation {
  display: flex;
  margin-bottom: 16px;
  justify-content: flex-start;
  width: 100%;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}
