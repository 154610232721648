.cardBlockView, .physicalCardBlockView, .virtualCardBlockView {
  position: relative;
  border-radius: 12px;
  width: 285px;
  height: 179px;
  cursor: pointer;
}

.physicalCardBlockView {
  background-color: #222225;
}

.virtualCardBlockView {
  background-color: #CFB999;
}

.cardBlockViewName, .cardBlockViewLast4 {
  font-family: 'Jost', sans-serif;
  line-height: 26px;
  letter-spacing: 0.2px;
  font-size: 18px;
  color: #FFFFFF;
}

.cardBlockViewName {
  position: absolute;
  left: 24px;
  top: 16px;
  max-width: 195px;
  overflow: hidden;
  text-overflow: ellipsis;
  z-index: 1;
}

.cardBlockViewLast4 {
  position: absolute;
  left: 24px;
  bottom: 21px;

  > span:nth-child(1) {
    margin-right: 4px;
    margin-bottom: 6px;
    height: 3px;
    width: 3px;
    background-color: #FFFFFF;
    border-radius: 50%;
    display: inline-block;
  }
}

.cardLogo {
  position: absolute;
  right: 24px;
  bottom: 16px;
}

.cardCloud {
  position: absolute;
  top: 23.41px;
  right: 24.17px;
}

.cardFreeze {
  position: absolute;
  top: 62px;
  left: 114px;
  z-index: 1;
}

.cardFreezeMask, .virtualCardFreezeMask {
  border-radius: 12px;
}

.cardFreezeMask {
  position: absolute;
  mix-blend-mode: hard-light;
  opacity: 0.64;
  background: lightgray 50% / cover no-repeat;
}

.virtualCardFreezeMask {
  position: absolute;
  mix-blend-mode: overlay;
  background: darkgray 50% / cover no-repeat;
}

.cardBlockFreeze {
  filter: blur(1px);
}

.logoFreeze {
  filter: blur(0.7px);
}

.cardExpireSoon, .cardShipping {
  position: absolute;
  right: -5.3px;
  top: -4.2px;
}

.cardExpireSoonDate, .cardShippingDate {
  margin-top: 4px;
  border-radius: 24px;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  font-size: 12px;
  color: #FFFFFF;
  background-color: #C7383D;
  padding: 2px 6px;
  width: max-content;
}

.cardShippingDate {
  background-color: #379585;
}

.cardLockIcon {
  position: absolute;
  left: 114px;
  bottom: 61px;
}


