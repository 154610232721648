@import '~theme/variables.less';

.page_container {
  height: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  overflow:auto;
  width: 100%;
  background-color: var(--FFFFFF-222225);

  @media (max-width: 900px) {
    width: 1000px;
    overflow-x: auto;
  }
}

.signInLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;

  & .logo {
    padding: 27.76px 0 0 44px;
    svg {
      width: 102px;
      height: 36px;
    }
  }

  & .button_container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 100%;
  }

  & .buttonTextSection {
    display: flex;
    justify-content: center;
  }

  :global {
    .buttonCreate {
      font-family: @font-family-roboto;
      white-space: nowrap;
      background: var(--F9CB76-D7AA70);
      color: var(--673E1D-5F391B);
      border-radius: 6px;
      width: 100%;
      max-width: 408px;
      height: 48px;
      margin-bottom: 32px;
      font-size: 16px;
      font-weight: 500;
      line-height: 23px;
      cursor: pointer;

      &:hover {
        background: var(--E8C187-BE8F60);
      }
    }

    .button {
      font-family: @font-family-roboto;
      font-size: 16px;
      font-weight: 400;
      line-height: 23px;
      cursor: pointer;
      text-align: center;

      white-space: nowrap;
      background: var(--FEF7E9-36302B);
      color: var(--AF6931-DFBEA4);
      border-radius: 6px;
      width: 100%;
      max-width: 408px;
      height: 48px;
      margin-bottom: 32px;

      &:hover {
        background: var(--FDE8C0-554639);
        color: var(--8C5020-F8C6A0);
      }
    }
  }

  & .footer {
    display: flex;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    font-family: @font-family-roboto;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 42px;
  }
}

.passChangeSuccess {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
}

.passChangeSuccessLogo {
  width: 140px;
  height: 51px;
  margin-bottom: 124px;
  cursor: pointer;
}

.passChangeSuccessTitle {
  font-family: 'Jost', sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 16px;
}

.passChangeSuccessSubTitle {
  font-size: 18px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
  width: 408px;
  text-align: center;
  margin-bottom: 281px;
}

.passChangeSuccessBtn {
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
}

.signInRightSection {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}
