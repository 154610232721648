.stepperWidgetContainer {
  display: flex;
  flex-direction: column;
}

.stepperWidgetSection {
  display: flex;
  flex-direction: column;

  > div {
    display: flex;

    > div:has(+ div:hover) {
      background-color: var(--564C94-A39ED1) !important;
    }
  }
}

.disabledStepperWidgetSection {
  display: flex;
  flex-direction: column;
  pointer-events: none;
  > div {
    display: flex;
  }
}

.stepperWidgetLine {
  width: 1px;
  height: 48px;
  margin: 8px 0 8px 24px;
  background-color: var(--EAEAEB-3D3D42);
}

.stepperWidgetIcon, .disabledStepperWidgetIcon, .filledStepperWidgetIcon  {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  color: var(--2C2C30-FFFFFF);
  font-family: 'Jost', sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
  border: 1px solid var(--746BB3-736DBB);
  background-color: var(--FFFFFF-222225);
  pointer-events: none;
}

.filledStepperWidgetIcon, .filledStepperWidgetInfo {
  cursor: pointer;
  pointer-events: initial !important;
}

.filledStepperWidgetIcon {
  &:hover {
    background-color: var(--564C94-A39ED1) !important;
    ~ div {
      > div:nth-child(1) {
        color: var(--36363A-FFFFFF);
      }

      > div:nth-child(2) {
        color: var(--606068-BCBCC2);
      }
    }
  }
}

.filledStepperWidgetInfo {
  &:hover {
    > div:nth-child(1) {
      color: var(--36363A-FFFFFF);
    }

    > div:nth-child(2) {
      color: var(--606068-BCBCC2);
    }
  }
}

.stepperWidgetInfo, .disabledStepperWidgetInfo, .filledStepperWidgetInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 16px;
  pointer-events: none;

  > div:nth-child(1) {
    font-family: 'Jost', sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
    color: var(--58585F-BCBCC2);
  }

  > div:nth-child(2) {
    font-family: 'Roboto', sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
  }
}

.filledStepperWidgetIcon {
  border: none;
  color: transparent;
  background-color: var(--746BB3-736DBB);
  cursor: pointer;
  pointer-events: auto;
}

.disabledStepperWidgetIcon {
  color: var(--9F9FA7-7A7A84);
  background-color: var(--F4F4F6-313135);
  border: none;
}

.disabledStepperWidgetInfo {
  > div:nth-child(1) {
    color: var(--9F9FA7-7A7A84);
  }

  > div:nth-child(2) {
    color: var(--9F9FA7-7A7A84);
  }
}
