.generatedReportsFilter {
  margin-top: 24px;
  display: flex;
  position: relative;

  > div {
    width: 100%;

    &:not(:last-child) {
      margin-right: 24px;
    }
  }
}

.rangePicker {
  display: flex;

  > div:nth-child(1) {
    margin-right: 24px;
  }
}

.rangePickerPeriods {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > div {
    border-radius: 4px;
    padding: 6px 12px;
    height: 32px;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.defaultPeriod, .activeDefaultPeriod {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
}

.defaultPeriod {
  background-color: var(--F4F4F6-313135);
  color: var(--606068-BCBCC2);

  &:hover {
    background-color: var(--ECECED-3D3D42);
    color: var(--36363A-FFFFFF);
    cursor: pointer;
  }
}

.activeDefaultPeriod {
  background-color: var(--B3B3B9-686871);
  color: #FFFFFF;

  &:hover {
    background-color: var(--B3B3B9-686871);
    color: #FFFFFF;
    cursor: pointer;
  }
}

.customAppSelectWithNames {
  position: static;

  > div {
    > svg {
      top: 4.5px;
    }
  }

  > div:nth-child(1) > div + span {
      padding: 6px 40px 6px 12px;
      font-size: 14px;
      line-height: 20px
  }

  > div:nth-child(1) > div {
    padding: 6px 40px 6px 12px;
    height: 32px;

    > div {
      font-size: 14px !important;
      line-height: 20px !important;
    }
  }

  > div:nth-child(2) {
    left: 0;
    top: 38px;
    position: absolute;
  }

  > div:nth-child(3) {
    position: absolute;
    top: 100px;
  }

  input {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 40px 6px 12px;
  }
}

.customAppSelect {
  min-width: 244px;

  > div {
    > svg {
      top: 4.5px;
    }
  }

  > div:nth-child(2) {
    top: 38px;
  }

  input {
    font-size: 14px;
    line-height: 20px;
    padding: 6px 40px 6px 12px;
  }
}

.customRangePicker {
  > div {
    width: 246px;
    padding: 6px 12px 6px 40px;

    &:hover {
      border-color: var(--7F78E3-8782C4);
    }
  }

  > span {
    padding: 0 6px 0 6px !important;
    transform: translateY(-60%) translateX(7px);
  }

  :global {
    .ant-picker-suffix {
      margin-top: 0;
      margin-left: 0;
      left: 12px;
    }

    .ant-picker-input {
      input {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .ant-picker-active-bar {
      margin-inline-start: 40px;
    }
  }
}
