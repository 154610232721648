.successOrderCard {
  height: 100%;
  min-height: 772px;
  max-height: 912px;
  width: 640px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.successOrderCardTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  margin-top: 24px;
  margin-bottom: 16px;
  color: var(--36363A-FFFFFF);
}

.successOrderCardText, .successOrderCardBoldText {
  font-size: 16px;
  line-height: 24px;
}

.successOrderCardText {
  font-family: Roboto, 'sans-serif';
  color: var(--606068-BCBCC2);
}

.successOrderCardBoldText {
  font-family: Roboto-Medium, sans-serif;
  color: var(--36363A-FFFFFF);
}

.successOrderCardBtn {
  width: 240px;
  margin-top: 48px;
  height: 40px;
}
