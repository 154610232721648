.addNewAccountContainer {
  margin: 24px;
  height: calc(100% - 48px);

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    height: calc(100% - 56px);
  }

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 24px auto;
  }
}

.addNewAccountNavigation {
  display: flex;
  margin-bottom: 16px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}

.createNewAccountModal {
  width: 640px;
  max-height: 912px;
  padding: 56px 40px 40px 40px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background: var(--FFFFFF-222225);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: space-between;
  }
}

.createNewAccountTitle {
  margin-top: 40px;
  margin-bottom: 16px;
  font-family: Jost, 'sans-serif';
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--606068-BCBCC2);
}

.createNewAccountSubTitle {
  width: 490px;
  margin-bottom: 40px;
  font-family: Roboto, 'sans-serif';
  font-size: 18px;
  line-height: 24px;
  color: #8D8D96;
  text-align: center;
}

.createNewAccountBackBtn {
  height: 48px;

  svg {
    margin-right: 8px;
    transform: rotate(180deg);
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    svg {
      path {
        fill: var(--F19304-DD994B);
      }
    }
  }
}

.createNewAccountConfirmBtn {
  width: 264px;
  height: 48px;
}

.currencySelector {
  width: 320px;

  input {
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
    padding: 14px 14px 14px 16px;
    height: 52px;
  }

  svg:nth-child(1) {
    top: 14px;
    left: 16px;
  }
}

.noFoundCurrency {
  display: flex;
  align-items: center;

  svg {
    margin-right: 16px;
    path {
      fill: var(--D9D9DC-4E4E55);
    }
  }
}
