.specificCardPage, .specificLoadingCardPage, .successActivateCard {
  width: 100%;
  height: 100%;
  position: relative;
}

.successExchangePopUpContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
}

.specificLoadingCardPage {
  background-color: var(--FFFFFF-222225);
}

.successActivateCard {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 24px;
  padding-bottom: 40px;
}

.specificCardsContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--F2F2F2-313135);
  padding: 24px 48px 32px 48px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 682px;

  @media (max-width: 1440px) {
    width: 54%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 42.5%;
  }

  @media (min-width: 1920px) {
    padding-left: 0;
  }
}

.specificCardPageNavigation {
  display: flex;
  margin-bottom: 30px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }
}

.specificCardPageName {
  display: flex;
  align-items: center;

  > div {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }

  > span {
    background-color: var(--36363A-FFFFFF);
    border-radius: 50%;
    width: 3px;
    height: 3px;
    margin: 0 4px;
  }
}

.specificCardSection {
  display: flex;

  > div:nth-child(1) {
    margin-right: 16px;
  }

  > div {
    width: 50%;
    max-width: 285px;
  }
}

.specificCardStatusSection {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.specificCardPageStatus, .specificBlockedCardPageStatus {
  display: flex;
  align-items: center;
  border-radius: 24px;
  padding: 4px 8px;
  background-color: var(--FFFFFF-222225);
  margin-bottom: 32px;

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 8px;

    path {
      fill: var(--B3B3B9-686871);
    }
  }

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
  }

  > div:nth-child(2) {
    color: var(--606068-BCBCC2);
  }

  > div:nth-child(4) {
    color: #8D8D96;
  }

  > span {
    border-radius: 50%;
    width: 3px;
    height: 3px;
    margin: 0 5px;
    background-color: var(--9F9FA7-7A7A84);
  }
}

.specificBlockedCardPageStatus {
  > div:nth-child(4) {
    color: var(--D92638-F45258);
  }
}

.specificCardStatusButtons {
  display: flex;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 80px;

    > div {
      font-family: Roboto-Medium, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: var(--AF6931-DFBEA4);
      text-align: center;
    }
  }

  > div:not(:last-child) {
    margin-right: 8px;
  }

  > div:first-child {
    :global {
      .ant-spin.ant-spin-spinning {
        width: 24px;
        height: 24px;

        > span {
          width: 100%;
          height: 100%;
        }
        svg {
          path {
            transform: scale(1) !important;
          }
        }
      }
    }
    svg {
      path {
        transform: scale(0.4) translateX(4px);
      }
    }
  }
}

.specificCardStatusButton, .specificDisabledCardStatusButton {
  button {
    width: 48px;
    height: 48px;
    margin-bottom: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;

    svg {
      width: 24px;
      height: 24px;

      path {
        fill: var(--673E1D-5F391B);
      }
    }
  }
}

.specificDisabledCardStatusButton {
  > div {
    color: var(--B3B3B9-686871) !important;
  }

  button {
    background-color: var(--ECECED-3D3D42) !important;
  }
}
