.cryptoTransaction {
  margin-top: 40px;
  width: 100%;

  > div:nth-child(3) {
    margin-top: 24px;
  }
}

.cryptoTransferSectionTitle {
  margin-bottom: 12px;
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.cryptoTransactionSender {
  display: flex;
  justify-content: space-between;
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  padding: 22px 24px;

  > span:nth-child(1) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }

  > svg {
    path {
      fill: var(--B3B3B9-686871);
    }

    &:hover {
      cursor: pointer;
      path {
        fill: var(--7F78E3-8782C4);
      }
    }
  }
}

.copyTooltipStyle {
  :global {
    .ant-tooltip-content {
      left: 16px !important;
    }

    .ant-tooltip-arrow {
      right: 2px !important;
      left: initial !important;
    }
  }
}