.successExchangePopUp {
  height: 100%;
  max-height: 912px;
  width: 640px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: center;
  padding: 56px 40px 40px;

  > div {
    width: 100%;
  }

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }
}

.successExchangePopUpHeader {
  text-align: center;
}

.successExchangePopUpImg {
  height: 118px;
  width: 118px;
}

.successExchangePopUpTitle {
  font-family: Jost, 'sans-serif';
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-top: 24px;
}

.successExchangePopUpContainer {
  padding: 0 16px;
  border-radius: 12px;
  width: 100%;
  background-color: var(--FAFAFB-27272B);
  margin-top: 40px;

  > div:not(:last-child) {
    border-bottom: 1px solid var(--F4F4F6-313135);
  }
}

.successExchangePopUpSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 68px;

  > div:nth-child(1) {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
  }

  > div:nth-child(2) {
    display: flex;

    span {
      font-family: Roboto-Medium, 'sans-serif';
      font-size: 18px;
      line-height: 24px;
      color: var(--36363A-FFFFFF);
    }
  }
}

.successExchangeRate {
  > span {
    color: var(--7F78E3-8782C4) !important;
  }
}

.successExchangePopUpButtons {
  display: flex;
  justify-content: space-between;

  button {
    height: 48px;
    width: 100%;
  }

  > button:nth-child(1) {
    margin-right: 24px;

    svg {
      transform: rotate(180deg);
      margin-right: 8px;
      path {
        fill: var(--FCB241-DD994B);
      }
    }

    &:hover {
      > svg {
        path {
          fill: var(--F19304-FEBD72);
        }
      }
    }
  }

  > button:nth-child(2) {
    svg {
      margin-left: 8px;
    }
  }
}

.successExchangeGetTransaction {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-top: 16px;
  margin-left: 24px;
  width: fit-content;

  > span {
    color: var(--AF6931-DFBEA4);
    margin-right: 8px;
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
  }

  > svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }
}