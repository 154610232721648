.expBankAccountContainer, .bankAccountContainer, .selectedBankAccount {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--EAEAEB-3D3D42);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.selectedBankAccount {
  border: 1px solid var(--7F78E3-8782C4);

  &:hover {
    border: 1px solid var(--7F78E3-8782C4) !important;
  }
}

.bankAccountContainer {
  &:hover {
    background-color: var(--FAFAFB-27272B);
    border: 1px solid var(--EAEAEB-3D3D42);

    .bankAccountIcons {
      > div:nth-child(1) {
        svg {
          display: block;
        }
      }
    }
    .bankAccountIcon {
      background-color: var(--F4F4F6-313135);

      path {
        fill : var(--746BB3-A39ED1);
      }
    }

    .bankAccountInfo {
      > svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
    }
    .bankAccountNameSection {
      > div {
        color: var(--36363A-FFFFFF);
      }
    }
    .beneficiaryAccountSelect {
      background-color: var(--F9CB76-D7AA70);

      > span {
        color: var(--673E1D-5F391B);
      }

      svg {
        path {
          fill: var(--673E1D-5F391B);
        }
      }
    }
  }
}

.expBankAccountContainer {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background: var(--EDECFE-2D2B46);
  border: 1px solid var(--EDECFE-2D2B46);

  .bankAccountIcons {
    > div:nth-child(1) {
      svg {
        display: block;
      }
    }
  }

  .bankAccountIcon {
    background-color: var(--F4F4F6-313135);

    path {
      fill : var(--746BB3-A39ED1);
    }
  }

  .bankAccountNameSection {
    > div {
      color: var(--36363A-FFFFFF);
      font-family: "Roboto-Medium", sans-serif;
    }
  }

  .beneficiaryAccountSelect {
    background-color: var(--F9CB76-D7AA70);

    > span {
      color: var(--673E1D-5F391B);
    }

    svg {
      path {
        fill: var(--673E1D-5F391B);
      }
    }
  }
}

.bankAccountInfo, .expBankAccountInfo {
  display: flex;
  align-items: center;
}

.bankAccountNameSection {
  display: flex;
  margin-left: 24px;
}

.bankAccountNameSection {
  display: flex;
  align-items: center;

  > span {
    margin: 0 8px;
    color: var(--FCB241-DD994B);
    font-size: 30px;
    font-weight: 500;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
  }

  > div {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.expBankAccountInfo {
  path {
    fill: var(--7F78E3-736DBB) !important;
  }
}

.expBeneficiaryUser {
  > div {
    font-weight: 500;
    color: var(--2C2C30-FFFFFF);
  }

  svg {
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.bankAccountIcon, .expBankAccountIcon {
  border-radius: 6px;
  background-color: var(--FAFAFB-27272B);
  height: 40px;
  width: 40px;
  svg {
    margin: 8px;
    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46) !important;
    path {
        fill: var(--746BB3-A39ED1) !important;
    }
  }
}

.expBankAccountIcon {
  background-color: var(--AFABED-4F498D);
  transform: rotate(180deg);

  path {
    fill: #FFFFFF !important;
  }

  &:hover {
    background-color: var(--AFABED-4F498D) !important;

    path {
      fill: #FFFFFF !important;
    }
  }
}

.bankAccountDetailsSection {
  margin-top: -16px;
  margin-bottom: 16px;
  padding: 40px 32px 32px 88px;
  display: flex;
  flex-direction: column;
  border-radius: 0 0 12px 12px;
  border: 1px solid var(--EDECFE-2D2B46);

  > div:nth-child(2) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    > div:first-child {
      width: 48%;
      margin-bottom: 32px;
    }
    > div:nth-child(2) {
      width: 48%;
    }
    > div:nth-child(n + 3) {
      width: 100%;
      margin-bottom: 32px;
    }
    > div:last-child {
      margin-bottom: 0 !important;
    }
  }
}

.bankAccountCurrencyDetails {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  > div {
    width: 100%;
  }

  > div:nth-child(1) {
    margin-right: 32px;
  }
}

.collapseAnimation {
  transition: all 0.2s ease-out;
}

.bankAccountIcons {
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    svg {
      display: none;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px 32px;

  > button:nth-child(1) {
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    line-height: 23px;
    height: 40px;
    text-transform: uppercase;
    color: var(--AF6931-DFBEA4);
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }
  > button:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 202px;
    height: 40px;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    font-family: "Roboto", sans-serif;
    text-transform: uppercase;
    color: var(--D92638-F45258);
    padding: 7px 0;
    background-color: var(--FBE5E7-3A1D1E);
    border-radius: 6px;
    cursor: pointer;

    svg {
      margin-left: 8px;

      path {
        fill: var(--E15C69-C7383D);
      }
    }

    &:hover {
      color: var(--36363A-FFFFFF);
      background-color: var(--F2B6BC-763739);

      svg {
        path {
          fill: var(--D92638-F45258);
        }
      }
    }
  }
}

.bankAccountModal {
  :global {
    .ant-modal-content {
      min-width: 500px;
    }
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;
      margin-bottom: 0;

      > div {
        font-size: 18px;
        font-family: Jost, 'sans-serif';
        font-weight: 500;
        line-height: 26px;
        color: var(--36363A-FFFFFF);
      }
    }
    .ant-modal-body {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: var(--606068-BCBCC2);
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0 32px 32px 32px;
    }
    .ant-modal-footer {
      margin-top: 0;
      padding: 0;
      border-top: none;
    }
  }
}

.trashIcon {
  path {
    fill: var(--B3B3B9-686871);
  }

  &:hover {
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.beneficiaryAccountSelect {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);
  height: 40px;
  width: 117px;
  padding: 7px 12px 7px 16px;

  > span {
    text-transform: uppercase;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    line-height: 23px;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      transform: translate(8px, 4px);
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    background-color: var(--E8C187-BE8F60) !important;
  }
}
