.reportsStatements {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--F2F2F2-313135);
  padding: 40px 48px;

  @media (max-width: 1440px) {
    width: 54%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 42.5%;
  }

  @media (min-width: 1920px) {
    padding-left: 0;
  }
}

.reportsStatementsTitle {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 32px;
}

.reportsStatementsSection {
  display: flex;
  flex-direction: column;

  > div:not(:last-child) {
    margin-bottom: 24px;
  }
}
