.cardLimitsContainer {
  margin-top: 20px;
  width: 100%;
}

.cardLimitsTitle {
  margin-bottom: 16px;
  color: var(--36363A-FFFFFF);
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  margin-left: 16px;
}

.cardLimitsSection {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;

  > div {
    width: 48.6%;
  }
}

.cardLimitSection, .selectedCardLimitSection {
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  padding: 16px;
  height: 84px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
  }

  &:hover {
    cursor: pointer;
    border: 1px solid var(--746BB3-736DBB);
  }
}

.selectedCardLimitSection {
  border: 1px solid var(--746BB3-736DBB);
}

.cardLimitTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  margin-bottom: 10px;
  margin-top: 2px;
}

.cardLimit {
  display: flex;

  > span {
    color: #8D8D96;
    width: 7px;
    font-size: 16px;
    margin-top: 0.5px;
  }
}

.cardLimitSpent, .cardLimitSpentRed {
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
  margin-right: 2px;
}

.cardLimitSpentRed {
  color: var(--D92638-F45258);
}

.cardLimitValue {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: #8D8D96;
  margin-left: 2px;
}

.disableLimitModal {
  :global {
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;
      margin-bottom: 0;
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 32px;

      > div {
        display: flex;
        flex-direction: column;
        align-items: center;

        > svg {
          margin-top: 24px;
          margin-bottom: 22px;
        }
      }
    }
    .ant-modal-footer {
      padding: 0;
      border-top: none;
    }
  }
}

.disableLimitModalTitle {
  color: var(--36363A-FFFFFF);
  font-family:Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  margin-bottom: 56px;

  > span:nth-child(2) {
    display: inline-block;
  }

  > span:nth-child(2):first-letter {
    text-transform: lowercase;
  }
}

.disableLimitModalFooter {
  display: flex;
  width: 100%;
  justify-content: space-between;

  > button:nth-child(1) {
    padding: 0;
  }

  > button:nth-child(2) {
    width: 204px;
    color: var(--D92638-F45258);
    background-color: var(--FBE5E7-3A1D1E);

    > svg {
      margin-left: 8px;

      path {
        fill: var(--E15C69-C7383D);
      }
    }

    &:hover {
      color: var(--36363A-FFFFFF);
      background-color: var(--F2B6BC-763739);

      svg {
        path {
          fill: var(--D92638-F45258);
        }
      }
    }
  }
}
