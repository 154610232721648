.cardSettingsContainer {
  margin-top: 20px;
  width: 100%;
}

.cardSettingsTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-left: 16px;
  margin-bottom: 12px;
}

.cardSettingsItems {
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  padding: 8px;
}

.cardSettingItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  padding: 12px 8px;
  height: 64px;

  > svg {
    width: 24px;
    height: 24px;

    path {
      transform: translate(9px, 5px);
      fill: var(--B3B3B9-686871);
    }
  }

  &:hover {
    cursor: pointer;
    background-color: var(--FAFAFB-27272B);

    .cardSettingItemName {
      > div {
        svg {
          path {
            fill: var(--746BB3-A39ED1);
          }
        }
      }
      > span {
        color: var(--36363A-FFFFFF);
      }

    }

    > svg {
      path {
        fill: var(--9F9FA7-7A7A84);
      }
    }
  }
}

.cardDisabledSettingItem {
  cursor: not-allowed !important;
}

.cardSettingItemName {
  display: flex;
  align-items: center;

  > div {
    margin-right: 16px;
    border-radius: 32px;
    padding: 8px;
    width: 40px;
    height: 40px;
    background-color: var(--EDECFE-2D2B46);

    svg {
      path {
        fill: var(--7F78E3-736DBB);
      }
    }
  }

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
  }
}
