.repeatedErrorContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
  width: 100%;
  height: 100%;

   > div {
     width: 640px;
     height: 100%;
     padding: 0 40px 40px 40px;
     border-radius: 12px;
     background-color: var(--FFFFFF-222225);
     display: flex;
     flex-direction: column;
     justify-content: space-between;
   }
}

.repeatedErrorMainSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    margin-top: 56px;
    margin-bottom: 40px;
  }

  > span:nth-child(2), > span:nth-child(3) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--606068-BCBCC2);
  }

  > span:nth-child(4) {
    margin-top: 16px;
  }

  > span:nth-child(4), > span:nth-child(5) {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
    text-align: center;
    width: 364px;
  }

  > span:nth-child(6) {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    text-align: center;
    line-height: 24px;
    color: #9F9FA7;
    margin-top: 64px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.doneButton {
  background-color: var(--FEF7E9-36302B);
  height: 48px;
  width: 100%;
  margin-right: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-family: "Roboto-Medium", sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  color: var(--AF6931-DFBEA4);

  &:hover {
    background-color: var(--FDE8C0-554639);
    > span {
      color: var(--8C5020-F8C6A0);
    }
  }
}

.createNewTransferBtn {
  background-color: var(--F9CB76-D7AA70);
  height: 48px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  text-transform: uppercase;

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }

  > span {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: var(--673E1D-5F391B);
    cursor: pointer;
    margin-right: 8px;
  }

  path {
    fill: var(--673E1D-5F391B);
  }
}
