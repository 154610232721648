.cardsBenefitsContainer {
  display: flex;
  flex-direction: column;
}

.getCardContainer {
  width: 100%;
  background-color: var(--FFFFFF-27272B);
  display: flex;
  flex-direction: column;
  padding: 152.55px 100px 128.45px 140px;
  max-height: 550px;

  > div {
    position: relative;
    display: flex;

    @media (min-width: 1920px) {
      width: 1552px;
      margin: 0 auto;
    }
  }
}

.getBusinessCardSection {
  > div:nth-child(1) {
      width: 451px;
      font-family: Jost, 'sans-serif';
      font-size: 28px;
      line-height: 36.4px;
      letter-spacing: 0.2px;
      color: var(--36363A-FFFFFF);
      margin-bottom: 20px;
    }

    > div:nth-child(2) {
      font-family: Roboto, sans-serif;
      font-size: 18px;
      line-height: 27px;
      color: var(--606068-BCBCC2);
      width: 430px;;
      margin-bottom: 48px;
    }

    > button {
      width: 275px;
      height: 48px;
    }
}

.cardsLandingAnimationContainer {
  height: 737px;
  width: 737px;
  margin-top: -17%;

  @media (max-width: 1710px) {
      margin-top: -19%;
  }

  @media (max-width: 1410px) {
      margin-top: -17%;
  }
}

.keyBenefitsContainer {
  z-index: 1;
  width: 100%;
  background-color: var(--FAFAFB-313135);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 83px 130px;

  > div {
    display: flex;
    justify-content: center;

    @media (min-width: 1920px) {
      max-width: 1552px;
      width: calc(100vw - 458px);
      margin: 0 auto;
    }
  }

  > button {
    width: 275px;
    height: 48px;
    margin-top: 63px;
  }
}

.keyBenefitsSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > svg, > img {
    width: 115px;
    height: 115px;
  }

  &:not(:last-child) {
    margin-right: 40px;
  }

  > div:nth-child(2) {
    text-align: center;
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin-top: 24px;
    margin-bottom: 6px;
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(3) {
    text-align: center;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}