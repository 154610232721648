.lastTransactionsShimmerShimmer {
  padding: 0 48px;

  > div > div {
    height: 80px;
    border-radius: 12px;
    margin-bottom: 24px;

    background-color: var(--FAFAFB-27272B);
  }
}
