.cardFundBanner, .unblockCardBanner, .activateCardBanner {
  margin-top: 24px;

  > div > div:nth-child(2) {
    max-width: 345px;
  }
}

.unblockCardBanner {
  > div > div:nth-child(2) {
    max-width: 375px;
    margin-right: 20px;
  }

  button {
    width: 132px;

    svg {
      margin-right: 8px;
    }
  }
}

.activateCardBanner {
  > div > div:nth-child(2) {
    max-width: 270px;
  }
}

.cardBannersStepper {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 8px;

  > div:not(:last-child) {
    margin-right: 12px;
  }
}

.cardBannersStep {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background-color: var(--D9D9DC-4E4E55);
  cursor: pointer;
}

.cardBannerActiveStep {
  width: 8px;
  height: 8px;
  background-color: var(--746BB3-736DBB);
}

.accountSubscriptionBlockedBanner {
  margin-bottom: 0;
}