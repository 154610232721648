.thanksScreenContainer {
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 900px) {
    width: 1000px;
    background-color: var(--FFFFFF-222225);
  }
}

.thanksScreenLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;

  .logo {
    cursor: pointer;
    width: 102px;
    height: 36.24px;
    margin: 27.76px 0 0 44px;

    svg {
      width: 102px;
      height: 36.24px;
    }
  }

  .footer {
    display: flex;
    font-family: "Roboto", sans-serif;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 44px;
    font-weight: 400;
  }
}

.thanksScreenMainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.thanksScreenTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  margin-bottom: 24px;
  color: var(--36363A-FFFFFF);
  text-align: center;
}

.thanksScreenSubTitle {
  font-size: 18px;
  line-height: 24px;
  width: 408px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: var(--606068-BCBCC2);
}

.thanksScreenRightSection {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}

.returnToMainPageButton {
  text-transform: uppercase;
  width: 100%;
  margin-top: 48px;
  height: 48px;
  font-family: Roboto-Medium, sans-serif;
  background: var(--FEF7E9-36302B);
  color: var(--AF6931-DFBEA4);
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: var(--FDE8C0-554639);
    color: var(--8C5020-F8C6A0);
  }
}
