@import '~theme/variables.less';

.appSelect {
  position: relative;

  :global {
    .ant-typography {
      color: var(--36363A-FFFFFF);
      font-weight: 400;
      font-family: "Roboto", sans-serif;
      font-size: 16px;
      line-height: 24px;
      text-align: left;
    }
    .ant-select-open {
      .ant-select-arrow {
        transform: rotate(180deg);
        svg {
          path {
            fill: var(--7F78E3-736DBB) !important;
          }
        }
      }
    }

    .ant-select-selection-item {
      svg {
        display: none;
      }
    }

    .ant-select-open, .ant-select-focused {
      border: 1px solid var(--7F78E3-8782C4) !important;
    }

    .ant-select-open + span {
      opacity:1;
      font-size: 12px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      padding: 0 6px 4.5px 4px;
      transform: translateY(-50%) translateX(10px);
      color: var(--7F78E3-8782C4) !important;
      background: var(--FFFFFF-222225);
    }

    .ant-select .ant-select-arrow {
      svg {
        path {
          fill: var(--B3B3B9-686871);
        }
      }
    }
  }

  .select {
    width: 100%;
    border: 1px solid var(--EAEAEB-3D3D42);
    border-radius: 4px;
    color: var(--36363A-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    height: 48px;
    display: flex;
    align-items: center;

    &:hover {
      border: 1px solid var(--7F78E3-8782C4) !important;
      border-radius: 4px !important;
    }

    &:focus {
      border: 1px solid var(--7F78E3-8782C4) !important;
    }

    &:active {
      border: 1px solid var(--7F78E3-8782C4) !important;
    }
  }

  > span {
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    padding: 13px 12px 13px 14px;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
  }
}

.dropdown {
  left: 0 !important;
  top: 50px !important;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
}

.selectWithValue {
  font-size: 12px !important;
  line-height: 16px !important;
  opacity: 1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(10px);
  background: var(--FFFFFF-222225);
  color: #8D8D96 !important;
  font-family: "Roboto", sans-serif;
}

.selectItem {
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    flex-direction: column;
  }

  svg {
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}
