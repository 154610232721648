.appInput, .appInputWithoutErrors, .disabledAppInput, .appInputWithDisclaimer {
  position: relative;
  height: 52px;
  transition: height 0.2s ease 0.15s;

  .input {
    width: 100%;
    border: 1px solid var(--ECECED-3D3D42);
    border-radius: 4px;
    color: var(--2C2C30-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 12px 13px 14px;
    background-color: var(--FFFFFF-222225);

    &:hover {
      border: 1px solid var(--7F78E3-8782C4);
      border-radius: 4px;
    }
  }

  .input::placeholder {
    color: transparent;
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
  }

  .input:focus {
    border: 1px solid var(--7F78E3-8782C4);
    border-radius: 4px;
  }

  .input:focus::placeholder {
    color: var(--9F9FA7-7A7A84);
  }

  .input:focus + span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    opacity:1;
    padding: 0 6px 4.5px 6px;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-8782C4);
    background: var(--FFFFFF-222225);
  }

  > span {
    font-family: "Roboto", sans-serif;
    color: var(--9F9FA7-7A7A84);
    font-size: 18px;
    line-height: 24px;
    padding: 13px 12px 13px 14px;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
  }
}

.appInputWithDisclaimer {
  height: 69px;
}

.disabledAppInput, .disabledAppInputWithValues {
  > input {
    cursor: none !important;
    pointer-events: none !important;
    background-color: var(--FAFAFB-27272B) !important;
    border: 1px solid var(--F4F4F6-313135);
  }
}

.disabledAppInputWithValues {
  > span {
    background: linear-gradient(var(--FFFFFF-222225) 50%, var(--FAFAFB-27272B) 0%) !important;
    padding: 0 6px 4.5px 6px !important;
    transform: translateY(-50%) translateX(7px);
    font-size: 12px !important;
    line-height: 16px !important;
    opacity: 1 !important;
    color: #8D8D96;
  }
}

.appInputWithErrors {
  height: 69px;

  > input {
    border: 1px solid var(--F2B6BC-763739) !important;
    box-shadow: none !important;
  }
  > span {
    color: var(--D92638-F45258);
  }
}

.inputWithValue, .errorInputWithValue {
  font-family: "Roboto", sans-serif;
  font-size: 12px !important;
  line-height: 16px !important;
  opacity: 1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(7px);
  background: var(--FFFFFF-222225);
  color: #8D8D96;
}

.errorInputWithValue {
  color: var(--D92638-F45258) !important;
}

.inputError, .disclaimer {
  color: var(--D92638-F45258);
  font-size: 12px;
  font-family: "Roboto", sans-serif;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 16px;
}

.disclaimer {
  opacity: 0;
  position: absolute;
  visibility: hidden;
  transform: translateY(10px);
  transition: opacity 0.5s ease, transform 0.5s ease-out;
  color: var(--9F9FA7-7A7A84);
}

.visibleDisclaimer {
  position: relative;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}

.copy {
  position: absolute;
  right: 12px;
  bottom: 7px;
}

.reload {
  position: absolute;
  right: 12px;
  bottom: 9px;
  svg {
    path {
      fill: var(--D9D9DC-4E4E55)!important;
    }
  }
}


.copy:hover {
  cursor: pointer;
  path {
    fill: var(--7F78E3-8782C4);
  }
}

.tooltipStyle {
  :global {
    .ant-tooltip-content {
      left: 16px;
    }
    .ant-tooltip-inner {
      width: max-content;
      border-radius: 4px;
      background-color: var(--746BB3-736DBB);
      padding: 10px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #FFF;
    }
    .ant-tooltip-placement-bottomLeft {
      padding-top: 8px;
    }
    .ant-tooltip-arrow-content::before {
      background: #746BB3;
    }

    .ant-tooltip-arrow {
      left: 25px;

      &:before {
        background: var(--746BB3-736DBB);
      }
    }
  }
}
