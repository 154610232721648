.transactionItemLogo, .transactionItemLogoPending, .bankTransferLogo, .cardOperationLogo, .homeEducationLogo,
.entertainmentLogo, .transportLogo {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 0;
  position: relative;

  > img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
}

.bankTransferLogo {
  background-color: var(--EDECFE-2D2B46) !important;

  > svg {
    path {
      fill: var(--7F78E3-8782C4) !important;
    }
  }
}

.cardOperationLogo {
  background-color: var(--E2F3ED-173B35) !important;

  > svg {
    path {
      fill: var(--12836E-74BEA5) !important;
    }
  }
}

.homeEducationLogo {
  background-color: var(--E7F9F9-093F41) !important;

  > svg {
    path {
      fill: var(--31C0C4-159499) !important;
    }
  }
}

.entertainmentLogo {
  background-color: var(--FEF8E1-4D3E05) !important;

  > svg {
    path {
      fill: var(--EFC006-AD8C0B) !important;
    }
  }
}

.transportLogo {
  background-color: var(--E3F0FC-114669) !important;

  > svg {
    path {
      fill: var(--479CEB-3F9FF3) !important;
    }
  }
}

.exchangeTransactionLogo {
  background-color: transparent !important;

  > div {
    > img:nth-child(1) {
      position: absolute;
      top: 0;
      left: 0;
      width: 26.25px;
      height: 26.25px;
    }

    > div {
      position: absolute;
      bottom: -3.75px;
      right: -3.75px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      padding: 3.75px;
      background-color: var(--FFFFFF-222225);

      img {
        width: 27.25px;
        height: 27.25px;
      }
    }
  }
}