.exchangeContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

 @media (max-width: 1280px) {
    width: 1200px;
  }

 @media (min-width: 1920px) {
    width: 1552px;
    margin: 0 auto;
 }
}

.exchangeChartContainer {
  height: 100%;
  width: 50%;
  background-color: var(--FFFFFF-222225);
  padding: 42px 48px 48px;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-top: 7px;
  }
}

.rightEmptyBlock {
  display: none;
  width: calc((100vw - 1552px - 240px) / 2);

  @media (min-width: 1920px) {
    display: block;
    position: absolute;
    height: 100%;
    right: calc((100vw - 1552px - 240px) / -2);
    top: 0;
    background-color: var(--FFFFFF-222225);
  }
}

.exchangeDetailsContainer {
  padding: 48px;
  height: 100%;
  width: 50%;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 32px;
  }

  > div:nth-child(2) {
    background-color: var(--FFFFFF-222225);
    padding: 40px;
    border-radius: 12px;
    height: calc(100% - 64px);
  }
}

.successExchangePopUpContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px;
}