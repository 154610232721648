.physicalCardLandingContainer {
  position: relative;
  padding: 120px 141px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  background: url(assets/images/physicalCardLanding.webp) lightgray 50% / cover no-repeat;

  > div {
    > div:nth-child(1) {
      font-family: Jost, 'sans-serif';
      font-size: 28px;
      line-height: 36px;
      letter-spacing: 0.2px;
      margin-bottom: 48px;
      color: #FFFFFF;
      text-transform: capitalize;
    }

    > button {
      width: 275px;
      height: 48px;
    }

    @media (min-width: 1920px) {
      width: 1552px;
      margin: 0 auto;
    }
  }

  @media (max-width: 1439px) {
    background-size: 2300px 100%;
  }
}

.physicalCardLandingDescription {
  display: flex;
  margin-bottom: 48px;

  > div {
    width: 275px;
  }

  > div:nth-child(1) {
    margin-right: 20px;
  }
}

.physicalCardLandingSection {
  display: flex;
  flex-direction: column;

  > div {
    color: #FFFFFF;
  }

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin-bottom: 6px;
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
  }
}