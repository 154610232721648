.sidebar {
  z-index: 100;

  :global {
    .ant-layout-sider-children {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      border-right: 1px solid var(--EAEAEB-3D3D42);
      background-color: var(--FFFFFF-222225);
    }
  }
}

.menu {
  margin-top: 16px;
  background-color: var(--FFFFFF-222225);
  border-right: none !important;

  :global {
    .ant-menu-item, .ant-menu-item-selected {
      height: 48px;
      width: 208px;
      padding: 0 !important;
      margin: 8px 16px 16px 16px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .ant-menu-item-selected {
      background-color: var(--FEF7E9-36302B) !important;
    }

    .ant-menu-item {
      color: var(--58585F-BCBCC2);
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;

      .ant-menu-item-icon {
        path {
          fill: var(--B3B3B9-686871);
        }
      }

      > svg {
        margin-left: 12px;
      }

      > span {
        display: flex;
        align-items: center;
      }
    }

    .ant-menu-item-active {
      background-color: transparent;
    }

    .ant-menu-item:hover {
      color: var(--2C2C30-FFFFFF);
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px
    }

    .ant-menu-title-content {
      height: 24px;
      margin-left: 16px !important;
    }

    .ant-menu-item-selected {
      -moz-border-radius-topleft: 4px;
      -moz-border-radius-topright: 4px;
      color: var(--2C2C30-FFFFFF);
      font-family: "Roboto", sans-serif;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;

      .ant-menu-item-icon {
        path {
          fill: var(--FCB241-DD994B);
        }
      }
    }

    .ant-menu-item-selected::after, .ant-menu-item::after {
      display: none;
    }


      .ant-tooltip-inner {
        width: max-content;
        border-radius: 4px;
        background-color: var(--746BB3-736DBB);
        padding: 10px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 20px;
        color: #FFF;
      }

      .ant-tooltip-arrow::before {
        background: #746BB3;
      }
  }
}

.collapsedMenu {
  :global {
    .ant-menu-item {
      width: 48px;

      > svg {
        margin-left: 0;
      }
    }

    .ant-menu-item-selected::after {
      transform: scale(1);
      opacity: 1;
    }

    .ant-menu-title-content {
      display: none !important;
    }
  }
}
.helpItem {
  display: flex;
  height: 64px;
  align-items: center;
  cursor: pointer;

  > div {
    margin-left: 16px;
    color: var(--58585F-BCBCC2);
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    height: 24px;
    padding-top: 1px;
  }
  path {
    fill: var(--B3B3B9-686871);
  }
}
.collapsedHelpItem {
  > div {
    display: none;
  }
}
