.cryptoTransferContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    width: 100%;
  }
}

.cryptoTransferSubTitle {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.cryptoTransferSubTitleSpan, .cryptoTransferSubTitleBoldSpan {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.cryptoTransferSubTitleBoldSpan {
  font-family: Roboto-Medium, sans-serif;
  color: var(--36363A-FFFFFF);
}

.cryptoTransferSubTitleInfo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px 14px 7px 12px;
  border-radius: 4px;
  background-color: var(--EDECFE-2D2B46);
  margin-top: 12px;
  width: 156px;

  > svg {
    margin-right: 8px;
    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  > div {
    font-family: Roboto-Medium, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--564C94-A39ED1);
  }
}

.cryptoTransferDisclaimer, .cryptoTransferBankDisclaimer {
  margin-top: 12px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  padding: 12px 16px;
  background-color: var(--FAFAFB-27272B);
  max-height: 84px;

  > div:nth-child(1) {
    width: 36px;
  }

  svg {
    width: 24px;
    margin-right: 12px;
    margin-top: 7px;

    path {
      fill: var(--B3B3B9-686871);
    }
  }

  > div:nth-child(2) {
    width: 420px;
  }
}

.cryptoTransferBankDisclaimer {
  height: 64px;
}

.cryptoTransferDisclaimerSpan, .cryptoTransferDisclaimerBoldSpan {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
}

.cryptoTransferDisclaimerBoldSpan {
  font-family: Roboto-Medium, sans-serif;
}

.cryptoTransferDisclaimerPoint {
  display: inline-block;
  margin: 3px 8px;
  width: 3px;
  height: 3px;
  border-radius: 50%;
  background-color: var(--606068-BCBCC2);
}

.cryptoTransferQRCode, .cryptoTransferErrorQRCode, .cryptoTransferDarkErrorQRCode {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  border-radius: 8px;
  width: 240px !important;
  height: 240px;
  border: 6px solid var(--D9D9DC-4E4E55);
  background-color: #FFFFFF;
}

.cryptoTransferErrorQRCode, .cryptoTransferDarkErrorQRCode {
  border: 1px solid var(--F2B6BC-763739);
  background-color: var(--FFFFFF-222225);

  > img {
    width: 240px;
    height: 240px;
    border: 1px solid var(--F2B6BC-763739);
    border-radius: 8px;
  }
}

.cryptoTransferDarkErrorQRCode {
  > img {
    background: lightgray 50% / contain no-repeat;
    mix-blend-mode: soft-light;
  }
}

.cryptoTransferAddress {
  display: flex;
  margin: 24px 0 12px 0;

  > div {
    flex-grow: 1;
    width: fit-content;
    margin-right: 8px;

    input {
      pointer-events: none !important;
      font-family: Roboto-Medium, sans-serif !important;
      height: 52px;
      font-size: 18px !important;
      line-height: 24px !important;
      border: 1px solid var(--D9D9DC-4E4E55);
    }
  }

  > button {
    padding: 0;
    width: 48px;
    height: 52px;
  }
}

.cryptoAddressRefreshBtn {
  margin-right: 8px;
}

.cryptoTransferShimmer {
  border-radius: 4px;
  background-color: var(--F4F4F6-313135);
}

.copyAddressTooltipStyle {
  :global {
    .ant-tooltip-content {
      left: 16px;
    }
    .ant-tooltip-inner {
      width: max-content;
      border-radius: 4px;
      background-color: var(--746BB3-736DBB);
      padding: 10px;
      font-family: "Roboto", sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #FFF;
    }
    .ant-tooltip-placement-bottomLeft {
      padding-top: 8px;
    }
    .ant-tooltip-arrow-content::before {
      background: #746BB3;
    }

    .ant-tooltip-arrow {
      left: 25px;

      &:before {
        background: var(--746BB3-736DBB);
      }
    }
  }
}


