.transferDetailsInput {
  position: relative;
  margin-bottom: 36px;

  .input {
    width: 100%;
    border: 1px solid var(--EAEAEB-3D3D42);
    border-radius: 4px;
    color: var(--36363A-FFFFFF);
    background-color: var(--FFFFFF-222225);
    font-size: 28px;
    line-height: 36px;
    padding: 14px 14px 14px 16px;
    font-weight: 500;

    &:hover {
      border: 1px solid var(--7F78E3-736DBB);
      border-radius: 4px;
    }
  }

  .input:focus {
    border: 1px solid var(--7F78E3-736DBB);
    border-radius: 4px;
  }

  .input:focus + span {
    font-size: 12px;
    line-height: 16px;
    opacity:1;
    padding: 0 6px 4.5px 6px;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-8782C4);
    background: var(--FFFFFF-222225);
  }

  > span {
    color: var(--9F9FA7-7A7A84);
    font-size: 28px;
    line-height: 36px;
    padding: 14px 14px 14px 16px;
    pointer-events: none;
    position: absolute;
    left: 0;
    top: 0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity: 0.5;
  }
}

.transferDetailsInputError {
  > input {
    border: 1px solid var(--F2B6BC-763739) !important;
  }
}

.transferDetailsInputWithValue {
  font-size: 12px !important;
  line-height: 16px !important;
  opacity:1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(7px);
  background: var(--FFFFFF-222225);
  color: #8D8D96;
}

.transferDetailsSpanError {
  color: var(--D92638-F45258) !important;
}

.tooltipStyle {
  :global {
    .ant-tooltip-content {
      left: 16px;
    }

    .ant-tooltip-inner {
      border-radius: 4px;
      background-color: var(--746BB3-736DBB);
      padding: 10px;
      font-size: 14px;
      line-height: 20px;
      color: var(--FFFFFF-222225);
    }
    .ant-tooltip-placement-bottomLeft {
      padding-top: 8px;
    }
    .ant-tooltip-arrow-content::before {
      background: var(--746BB3-736DBB);;
    }
  }
}

.transferDetailsError {
  position: absolute;
  color: var(--D92638-F45258);
  font-size: 12px;
  line-height: 16px;
  margin-top: 4px;
  margin-left: 16px;
}

.transferDetailsCurrency {
  position: absolute;
  padding: 0 32px;
  font-size: 28px;
  line-height: 36px;
  color: #8D8D96;
  top: 1px;
  height: 63.2px;
  right: 1px;
  display: flex;
  align-items: center;
  background: var(--FAFAFB-27272B);
  border-radius: 0 4px 4px 0;
  cursor: default;
}

