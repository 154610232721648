.transferBtwAccounts {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 780px;
  width: 100%;
}

.transferBtwAccountsBtn {
  width: 322px;
  height: 48px;
}