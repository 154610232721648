.trialPaidSubscriptionWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 32px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  width: 408px;

  > div {
    width: 100%;
    text-align: center;
  }
  > button {
    width: 100%;
    height: 48px;
    margin-top: 40px;
  }
}

.trialPaidSubscriptionWidgetTitle {
  font-family: Jost, 'sans-serif';
  font-size: 24px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 24px;
}

.trialPaidSubscriptionWidgetAmountSection {
  padding-bottom: 24px;
  border-bottom: 1px solid var(--ECECED-3D3D42);

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
    margin-top: 2px;
  }
}

.trialPaidSubscriptionWidgetAmount {
  > span:nth-child(1) {
    color: var(--36363A-FFFFFF);
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 36px;
    margin-right: 12px;
    font-weight: 700;
  }
  > span:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 28px;
    color: var(--9F9FA7-7A7A84);
    text-decoration-line: line-through;
    text-decoration-thickness: 1px;
    font-weight: 300;
  }
}

.trialPaidSubscriptionWidgetShimmer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;

  > div {
    border-radius: 8px;
    width: 132px;
    height: 27px;
    background-color: var(--ECECED-3D3D42);
  }
}

.trialPaidSubscriptionWidgetInfoSection {
  display: flex;
  align-items: center;
  margin-top: 24px;

  > svg {
    margin-right: 26px;
    width: 32px;
    height: 32px;
    path {
      transform: scale(1.4);
      fill: var(--7F78E3-736DBB);
    }
  }
}

.trialPaidSubscriptionWidgetInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  > div:nth-child(1) {
    color: var(--36363A-FFFFFF);
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 4px;
  }
  > div:nth-child(2) {
    color: var(--606068-BCBCC2);
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, 'sans-serif';
    text-align: left;
  }
}

.trialPaidSubscriptionWidgetPeriods {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  padding: 4px;
  border-radius: 8px;
  background-color: var(--FAFAFB-27272B);
  height: 40px;
  position: relative;
}

.trialPaidSubscriptionWidgetPeriod {
  width: 50%;
  text-align: center;
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  cursor: pointer;
}

.trialPaidSubscriptionWidgetActivePeriod {
  border-radius: 6px;
  padding: 6px 12px;
  background-color: var(--746BB3-736DBB);
  font-family: Roboto-Medium, 'sans-serif' !important;
  color: #FFFFFF !important;
  cursor: initial !important;
}

.trialPaidSubscriptionWidgetMostPopular {
  position: absolute;
  border-radius: 31px;
  padding: 2px 6px;
  text-align: center;
  background-color: var(--E2F3ED-173B35);
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--12836E-74BEA5);
  right: 46px;
  top: -18px;

  &:before {
    content: '';
    position: absolute;
    border-left: 6px solid transparent;
    border-right: 6px solid transparent;
    border-top: 6px solid var(--E2F3ED-173B35);
    bottom: -6px;
    left: 36px;
  }
}