.container {
  width: 640px;
  height: 100%;
  max-height: 938px;
  padding: 0 40px 40px 40px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.imageContainer {
  display: flex;
  justify-content: center;
  padding-top: 48px;

  svg {
    width: 160px;
    height: 160px;
  }
}

.textContainer, .errorTextContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 24px;
}

.errorTextContainer {
  margin-top: 40px;
}

.description {
  font-family: Roboto-Medium, sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  text-align: center;
  color: var(--606068-BCBCC2);
}

.amount {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: var(--36363A-FFFFFF);
  margin-top: 12px;
}

.transactionFeeContainer, .transactionCustomFeeContainer {
  height: 68px;
  padding: 0 16px 0 16px;
  margin-top: 40px;
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  display: flex;
  justify-content: space-between;
  align-items: center;

  > span:nth-child(2) {
    display: flex;
    align-items: center;
  }
}

.transactionCustomFeeContainer {
  height: auto;
  flex-direction: column;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 68px;
    padding: 24px 8px;
  }

  > div:first-child {
    border-bottom: 1px solid var(--F4F4F6-313135);
  }
}

.exchangeRateSection {
  display: flex;

  > span {
    display: flex;
  }

  > span:nth-child(2) {
    margin-top: 2px;
    color: var(--7F78E3-8782C4);
  }
}

.exchangeRateValue {
  > span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }
}

.textTransactionFee {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #8D8D96;
}

.amountTransactionFee {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
}

.transactionDetailsContainer {
  margin-top: 21px;
  display: flex;
  align-items: center;
  margin-left: 24px;
  cursor: pointer;

  &:hover {
    > span {
      color: var(--8C5020-F8C6A0);
    }
    path {
      fill: var(--F19304-DD994B);
    }
  }

  > span {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
    color: var(--AF6931-DFBEA4);
    margin-right: 8px;
  }
}

.transactionDetailsContainerDisabled {
  pointer-events: none;
}

.buttonsContainer {
  display: flex;
  justify-content: center;
  width: 100%;
}

.repeatButton, .errorDoneButton {
  background-color: var(--FEF7E9-36302B);
  height: 48px;
  width: 100%;
  margin-right: 24px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:hover {
    background-color: var(--FDE8C0-554639);
    > span {
      color: var(--8C5020-F8C6A0);
    }
    path {
      fill: var(--F19304-DD994B);
    }
  }

  > span {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: var(--AF6931-DFBEA4);
    margin-right: 11px;
  }

  svg {
    margin-bottom: 5px;
  }

  path {
    fill: var(--FCB241-DD994B);
  }
}

.doneButton, .errorRepeatButton {
  background-color: var(--F9CB76-D7AA70);
  height: 48px;
  width: 100%;
  border-radius: 6px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }

  > span {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    color: var(--673E1D-5F391B);
    cursor: pointer;
    margin-right: 8px;
  }

  svg {
    margin-bottom: 5px;
  }

  path {
    fill: var(--673E1D-5F391B);
  }
}

.errorRepeatButton {
  svg {
    margin-bottom: 3px;
  }
}

.header {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;
  color: var(--606068-BCBCC2);
}

.firstAndSecond {
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  > span {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.fivePart {
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: var(--9F9FA7-7A7A84);
  margin-top: 64px;
}

