.pinCodeInputContainer {
  display: flex;
  flex-direction: column;
  margin-top: 96px;
  justify-content: center;
  align-items: center;
}

.pinCodeInput {
  width: 72px;
  height: 72px;
  padding: 16px 24px;
  border-radius: 4px;
  border: 1px solid var(--ECECED-3D3D42);
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 28px;
  line-height: 40px;
  color: var(--36363A-FFFFFF);
  background-color: var(--FFFFFF-222225);
  text-align: center;
  outline: none;

  &:not(:last-child) {
    margin-right: 16px;
  }

  &:focus {
    border: 1px solid var(--7F78E3-8782C4);
  }
}

.pinCodeShowSection {
  margin-top: 24px;
  display: flex;
  align-items: center;

  > span {
    font-family: Roboto-Medium, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
    margin-right: 8px;
  }

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    svg {
      cursor: pointer;

      path {
        fill: var(--F19304-FEBD72);
      }
    }

    > span {
      cursor: pointer;
      color: var(--8C5020-F8C6A0);
    }
  }
}
