.limitBankTransferModal {
  :global {
    .ant-modal-body {
      padding: 32px;
    }
  }
}

.limitBankTransferModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    text-align: center;
    width: 100%;
  }

  > div:nth-child(3) {
    margin-bottom: 8px;
  }

  > img {
    margin-bottom: 32px;
    margin-top: 48px;
    height: 118px;
    width: 118px;
  }
}

.limitBankTransferModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 8px;
}

.limitBankTransferModalSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.limitBankTransferModalCustomSubTitle {
  font-family: Roboto, 'sans-serif';
  color: var(--7F78E3-8782C4);
  cursor: pointer;
  font-size: 16px;
  width: fit-content !important;

  &:hover {
    color: var(--564C94-A39ED1);
  }
}

.limitBankTransferModalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  > button:nth-child(1) {
    width: 61px;
  }
  > button:nth-child(2) {
    width: 204px;
  }
}

.limitBankTransferInfo {
  border-radius: 8px;
  background-color: var(--FEF4EA-443018);
  padding: 8px 16px;
  display: flex;
  align-items: center;
  height: 40px;

  > svg {
    width: 24px;
    height: 24px;
    margin-right: 16px;
    path {
      transform: scale(1.1) translateX(1px) translateY(1px);
      fill: var(--F69744-E67A0F);
    }
  }

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--D66B00-D48F49);
  }
}