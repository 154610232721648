.exchangeRatesChartContainer {
  position: relative;
  width: 100%;
  height: calc(100% - 82px);
  padding: 24px;
  border: 1px solid var(--ECECED-3D3D42);
  border-radius: 12px;
  margin-top: 49px;
}

.exchangeRatesChartTimeLines {
  position: absolute;
  top: 24px;
  left: 56px;
  display: flex;
}

.exchangeRatesTimeLine {
  width: 53px;
  height: 24px;
  padding: 5px 16px;
  font-family: Roboto, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #8D8D96;

  > span {
    display: flex;
    justify-content: center;
    position: relative;
    width: 20px;
    cursor: pointer;
  }
}

.exchangeRatesCurrentTimeLine {
  font-family: Roboto-Medium, 'sans-serif';
  color: var(--7F78E3-8782C4);

  > span::after {
    content: '';
    position: absolute;
    bottom: -2px;
    left: 0;
    height: 3px;
    width: inherit;
    background-color: var(--7F78E3-8782C4);
    border-radius: 3px 3px 0 0;
  }
}

.exchangeRatesChart {
  width: 100%;
  height: calc(100% - 48px);
  margin-top: 48px;
}

.exchangeRatesChartLoader {
  top: 46%;
  left: 48%;
}

.exchangeRatesChartErrorSection {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: inherit;
  margin-top: 47px;

  > img {
    margin-bottom: 24px;
  }

  > button {
    width: 112px;
    height: 32px;
    margin-top: 32px;
    text-transform: initial;

    svg {
      margin-left: 8px;
      path {
        transform: scale(0.7) translateX(5px) translateY(4px);
      }
    }
  }
}

.exchangeRatesChartErrorTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  margin-bottom: 4px;
  color: var(--606068-BCBCC2);
}

.exchangeRatesChartErrorSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: #8D8D96;
}