.addBeneficiaryContainer {
  margin-top: 24px;
  margin-bottom: 16px;
  padding: 32px;
  border: 1px solid var(--BDB9F0-4F498D);
  border-radius: 12px;
  display: flex;
  flex-direction: column;
}

.addBeneficiaryTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;

  > div {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--2C2C30-FFFFFF);
  }

  > svg {
    cursor: pointer;
    path {
      transform: translate(5px, 5px);
      fill: var(--D9D9DC-4E4E55);
    }
  }
}

.addBeneficiaryInput {
  height: 52px !important;
}

.addBeneficiarySection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  > div {
    width: 100%;
  }

  > div:nth-child(1) {
    margin-right: 32px;
  }
}

.addCompanySection {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  > div {
    width: 100%;
  }
}

.buttonApply {
  display: flex;
  justify-content: flex-end;
  width: 100%;

  > button {
    width: calc(50% - 16px);
    height: 48px;
  }
}
