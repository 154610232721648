.addBankAccount, .addBankAccountWithData {
  padding: 32px;
  border-radius: 12px;
  border: 1px solid var(--BDB9F0-4F498D);

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0;
    }
  }
}

.addBankAccountWithData {
  margin-bottom: 16px;
}

::-webkit-scrollbar {
  width: 8px;
  height: 4px;

  &-track {
    background: var(--FFFFFF-222225);
    margin-top: 7px;
    margin-bottom: 7px;
    border-radius: 4px;
  }

  &-thumb {
    background: var(--ECECED-3D3D42);
    border-radius: 4px;
  }
}

.addBankAccountTitle {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
  align-items: center;

  > div {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    color: var(--2C2C30-FFFFFF);
  }

  > svg {
    cursor: pointer;
    path {
      transform: translate(5px, 5px);
      fill: var(--D9D9DC-4E4E55);
    }
  }
}

.addBankAccountCurrency {
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;

  > div {
    width: 100%;
  }

  > div:nth-child(1) {
    margin-right: 32px;
  }
}

.addBankAccountFields {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div:first-child {
    width: 48%;
  }
  > div:nth-child(2) {
    width: 48%;
  }
  > div:nth-child(n + 3) {
    width: 100%;
    margin-top: 24px;
  }
}

.buttonApply {
  display: flex;
  justify-content: flex-end;
  width: 100%;
  margin-top: 32px;

  > button {
    width: calc(50% - 16px);
    height: 48px;
  }
}

.stateDropdown {
  left: 0 !important;
  top: 52px !important;
  width: 100% !important;
  min-width: 100% !important;
  border: 1px solid var(--AFABED-4F498D);
  box-shadow: var(--B2BEC340-131316CC);

  :global {
    .ant-select-item {
      padding: 0 0 0 16px;
      align-items: center;
      height: 56px;
    }
    .rc-virtual-list-scrollbar.rc-virtual-list-scrollbar-show {
      display: none !important;
    }
    .rc-virtual-list-holder {
      overflow-y: auto !important;
    }
  }
}

.stateDropdownSelect {
  :global {
    .ant-select-single.ant-select-show-arrow .ant-select-selection-search {
      left: 0 !important;
      right: 0 !important;
    }
  }
}

.beneficiaryModal {
  :global {
    .ant-modal-content {
      min-width: 400px;
    }
    .ant-modal-header {
      padding: 32px 32px 8px 32px;

      > div {
        font-family: 'Jost', sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: var(--36363A-FFFFFF)
      }
    }
    .ant-modal-close {
      top: 47px;
    }
    .ant-modal-body {
      padding: 0 32px 32px 32px;

      font-family: "Roboto", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      text-align: left;
      color: var(--606068-BCBCC2);

    }
    .ant-modal-footer {
      padding: 0 32px 32px 32px;
      border-top: none;
      margin: 0;
    }
  }
}

.beneficiaryButton {
  width: 100%;
  height: 40px;
  background-color: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  font-family: "Roboto", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 23px;
  cursor: pointer;

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }
}

.highlightText {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: var(--606068-BCBCC2);
}
