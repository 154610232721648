.cardOrderContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  height: 100%;

  > div {
    width: 100%;
  }
}

.cardOrderHeader{
  width: 100%;
  display: flex;
  align-items: center;

  img {
    width: 285px;
    height: 182.369px;
    margin-right: 24px;
  }
}

.cardOrderDeliveryInfo {
  width: 100%;
  padding: 20px 24px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);

  > div:nth-child(1) {
    padding-bottom: 19.18px;
    border-bottom: 1px solid var(--ECECED-3D3D42);
  }

  > div:nth-child(2) {
    padding-top: 19.18px;
  }
}

.cardOrderDeliveryInfoItem {
  > div:nth-child(1) {
    margin-bottom: 4px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
  }
  > div:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }
}

.cardOrderDetailsTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin: 24px 0 16px 16px;
}

.cardOrderSection {
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  padding: 0 24px;
  margin-top: 16px;

  > div:not(:last-child) {
    border-bottom: 1px solid var(--F4F4F6-313135);
  }
}

.cardOrderItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 64px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
  }

  > div:nth-child(2) {
    display: flex;
    align-items: center;
    flex-direction: column;
    font-family: Roboto-Medium, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);

    > span {
      width: 4px;
      height: 4px;
      background-color: var(--36363A-FFFFFF);
      margin: 0 5px;
      border-radius: 50%;
    }

    > div:nth-child(2) {
      margin-top: 3px;
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: var(--606068-BCBCC2);
    }
  }
}

.cardOrderExchangeRate {
  flex-direction: row !important;

  > div {
    font-size: 18px !important;
    font-family: Roboto-Medium, 'sans-serif' !important;
    line-height: 24px !important;
    margin-top: 0 !important;
  }
}

.cardOrderAccountInfo {
  flex-direction: row !important;

  > div:nth-child(2) {
    font-size: 18px !important;
    color: var(--B3B3B9-686871) !important;
    line-height: 24px !important;
    margin-top: 0 !important;
  }
}

.shippingServiceFreeName {
  color: var(--12836E-74BEA5) !important;
}

.cardOrderButtons {
  display: flex;
  justify-content: space-between;

  > span {
    margin-top: 0;
  }
}

.orderCardBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  width: 400px;

  > svg {
    margin-left: 8px;
    path {
      fill: var(--673E1D-5F391B);
    }
  }
}

.cardOrderNotEnoughFundsSection {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  > div:nth-child(1) {
    display: flex;
    align-items: center;
    padding: 8px 24px 8px 16px;
    border-radius: 8px;
    background-color: var(--FBE5E7-3A1D1E);
    margin-right: 16px;

    svg {
      margin-right: 16px;
      path {
        fill: var(--E15C69-C7383D);
      }
    }

    > div {
      span {
        font-size: 14px;
        line-height: 20px;
        font-family: Roboto, sans-serif;
        color: var(--D92638-F45258);
      }
      > span:nth-child(2) {
        font-family: Roboto-Medium, 'sans-serif';
      }
    }
  }

  button {
    width: 102px;

    svg {
      margin-right: 8px;
    }
  }
}

.totalAmount {
  color: var(--D92638-F45258) !important;
}