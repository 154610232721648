.orderStatusStepContainer, .orderStatusStepDisabledContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background-color: var(--FFFFFF-222225);
}

.orderStatusStepDisabledContainer {
  border: 1px solid var(--ECECED-3D3D42);
  background-color: var(--ECECED-3D3D42);
}

.orderStatusStepTitle, .orderStatusStepDisabledTitle {
  display: flex;
  margin-bottom: 12px;

  > div:nth-child(1) {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: var(--746BB3-A39ED1);
    margin-right: 8px;

    svg {
      transform: scale(0.8);
      path {
        fill: var(--FFFFFF-222225);
      }
    }
  }

  > div:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: var(--606068-BCBCC2);
  }
}

.orderStatusStepDisabledTitle {
  > div:nth-child(2) {
    margin-left: 8px;
    color: var(--9F9FA7-7A7A84);
  }
}

.orderStatusTitle, .orderStatusDisabledTitle {
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 18px;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 30px;
}

.orderStatusDisabledTitle {
  font-family: Roboto, sans-serif;
  color: var(--9F9FA7-7A7A84);
}

.orderStatusDate, .orderStatusDisabledDate {
  text-align: center;
  width: 100%;
  padding: 8px 0;
  border-radius: 8px;
  background-color: var(--FAFAFB-27272B);
  font-family: Roboto, sans-serif;
  font-size: 12px;
  line-height: 16px;
  color: var(--606068-BCBCC2);
}

.orderStatusDisabledDate {
  background-color: var(--F4F4F6-313135);
  color: var(--9F9FA7-7A7A84);
}