.statementItem {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 24px;
  background-color: var(--FFFFFF-222225);
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);

  &:hover {
    cursor: pointer;
    border: 1px solid var(--7F78E3-8782C4);

    .statementItemIcon {
      svg {
        path {
          fill: var(--746BB3-A39ED1);
        }
      }
    }
  }

  > div {
    display: flex;
    align-items: center;
  }
}

.statementItemTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  color: var(--606068-BCBCC2);
  margin-left: 24px;
}

.statementItemIcon {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 40px;
  background: var(--EDECFE-2D2B46);

  svg {
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.statementItemExport {
  padding: 5px 12px 5px 16px;
  border-radius: 6px;
  background: var(--FEF7E9-36302B);
  cursor: pointer;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
    margin-right: 8px;
  }

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    background-color: var(--FDE8C0-554639);

    > span {
      color: var(--8C5020-F8C6A0);
    }

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

