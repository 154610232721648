.transferDetails {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 40px;
  height: 100%;
  overflow: auto;
}

.transferDetailsHeader {
  display: flex;
  margin-bottom: 24px;
  align-items: center;

  > svg {
    margin: 0 24px;
  }
}

.userInfoHeader {
  display: flex;
  align-items: center;

  > div {
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    color: var(--2C2C30-FFFFFF);
  }

  svg {
    margin-right: 24px;

    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.bankAccountInfoHeader {
  display: flex;
  align-items: center;

  svg {
    margin-right: 24px;

    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  > div {
    font-weight: 400;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--2C2C30-FFFFFF);
  }

  > span {
    margin: 0 12px;
    color: var(--FCB241-DD994B);
    font-family: Roboto-Medium, sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 24px
  }
}

.transferDetailsFrom {
  > div:nth-child(1) {
    font-family: Roboto-Medium, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--2C2C30-FFFFFF);
    margin-bottom: 24px;
  }

  > div:nth-child(2) {
    width: 100%;
  }
}

.additionalValueClassName {
  position: absolute;
  top: 13px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #8D8D96;
  left: 139px;
}

.transferDetailsForm {
  margin-top: 40px;

  > div:nth-child(1) {
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    font-family: Roboto-Medium, sans-serif;
    color: var(--36363A-FFFFFF);
    margin-bottom: 24px;
  }
}

.additionalTransferDetailsInfoWithoutPurposes {
  > div {
    width: 100%;
  }
}

.additionalTransferDetailsInfo {
  display: flex;

  > div {
    width: 50%;
  }
}

.referenceSection {
  height: 69px;
  position: relative;

  > div:nth-child(1) {
    > div {
      padding-right: 55px;
    }
  }
}

.referenceCounter, .referenceCounterError {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  line-height: 16px;
  position: absolute;
  right: 16px;
  bottom: -2px;

  > span:nth-child(1) {
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    color: var(--9F9FA7-7A7A84);
  }
}

.referenceCounterError {
  > span:nth-child(1) {
    color: var(--D92638-F45258);
  }
}

.transferDetailsFooter {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    height: 48px;
  }
}

.backSection {
  display: flex;
  align-items: center;
  cursor: pointer;

  &:hover {
    svg {
      path {
        fill: var(--F19304-DD994B);
      }
    }

    div {
      color: var(--8C5020-F8C6A0);
    }
  }

  svg {
    transform: rotate(180deg);
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  div {
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    font-family: "Roboto", sans-serif;
    color: var(--AF6931-DFBEA4);
    margin-left: 8px;
  }
}

.buttonSubmit, .disabledSubmitBtn, .buttonConfirm {
  display: flex;
  justify-content: flex-end;

  > button {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    width: 396px;
    height: 48px;
    background-color: var(--F9CB76-D7AA70);
    cursor: pointer;

    > div {
      display: flex;
      align-items: center;
      justify-content: center;

      .buttonSubmitText {
        font-family: Roboto-Medium, sans-serif;
        font-size: 16px;
        font-weight: 500;
        line-height: 23px;
        text-transform: uppercase;
        color: var(--673E1D-5F391B);
        padding: 12px 0;
        margin-right: 8px;
      }

      svg {
        path {
          fill: var(--673E1D-5F391B);
        }
      }
    }
  }

  > button:hover {
    background-color: var(--E8C187-BE8F60);
  }
}

.buttonConfirm {
  > button {
    display: flex;
    justify-content: center;
    border-radius: 6px;
    width: 252px !important;
  }
}

.disabledSubmitBtn {
  > button {
    height: 48px;
    background-color: var(--F4F4F6-27272B);
    pointer-events: none;

    .buttonSubmitText {
      color: var(--9F9FA7-7A7A84) !important;
    }
    svg {
      path {
        fill: var(--B3B3B9-686871) !important;
      }
    }

  }
}

.confirmModal {
  :global {
    .ant-modal-content {
      min-width: 600px;
    }
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;
      margin-bottom: 0;

      > div {
        color: var(--36363A-FFFFFF);
        font-family: Roboto-Medium, sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        letter-spacing: 0.2px;
      }
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0 32px 32px 32px;
    }
    .ant-modal-footer {
      padding: 0;
      border-top: none;
    }
  }
}

.modalRow, .modalAmountRow, .modalErrorAmountRow {
  display: flex;
  justify-content: space-between;
  padding: 18px 8px;

  > div:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: #8D8D96;
  }

  > div:nth-child(2) {
    font-size: 18px;
    font-family: Roboto-Medium, sans-serif;
    line-height: 24px;
    font-weight: 500;
    color: var(--36363A-FFFFFF);
    display: flex;

    > span {
      margin: 0 4px;
      color: var(--FCB241-DD994B);
      font-family: Roboto-Medium, sans-serif;
      font-size: 25px;
      font-weight: 500;
      line-height: 24px
    }

    .modalRowAddValue {
      color: var(--606068-BCBCC2);
    }

    svg {
      margin: 0 5px;
      path {
        fill: var(--B3B3B9-686871);
      }
    }
  }
}

.modalSection {
  border-radius: 12px;
  padding: 8px 16px;
  background-color: var(--FAFAFB-27272B);

  > div:not(:last-child) {
    border-bottom: 1px solid var(--F4F4F6-313135);
  }

  > div > div {
    margin: 4px 0;
  }
}

.modalFeeSection {
  margin: 12px 0 -12px 0;

  > div:nth-child(1) {
    height: 98px !important;
    flex-direction: column;
    padding: 10px 0 20px 0;
  }

  :global {
    .ant-radio-group {
      display: flex;
      margin: 0 0 0 8px;

      > label:not(:last-child) {
        margin-right: 32px;
      }
    }

    .ant-radio-wrapper {
      display: flex;
      align-items: center;

      > span:nth-child(2) {
        font-family: Roboto, sans-serif;
        font-size: 18px;
        line-height: 24px;
        color: var(--606068-BCBCC2);
      }
    }

    .ant-radio-inner {
      width: 24px;
      height: 24px;
    }

    .ant-radio-checked .ant-radio-inner::after {
      transform: scale(0.95) !important;
    }

    .ant-radio-wrapper span.ant-radio+* {
      padding-inline-end: 0 !important;
    }
  }
}

.modalFeeSectionDescription {
  font-family: Roboto, sans-serif !important;
  color: #8D8D96 !important;
  margin: 12px 0 0 8px !important;
  font-weight: 400 !important;
}

.modalAmountRow, .modalErrorAmountRow {
  margin-top: 24px;
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  padding: 32px 24px;
  height: 86px;

  > div:nth-child(2) {
    font-size: 28px;
    font-weight: 500;
    font-family: Roboto-Medium, sans-serif;
    line-height: 20px;
  }
}

.modalErrorAmountRow {
  > div:nth-child(2) {
    color: var(--D92638-F45258);
  }
}

.modalButtonsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 32px;
}

.confirmError {
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  line-height: 24px;
  color: var(--D92638-F45258);
  text-align: center;
  margin-top: 12px;
}

.repeatTransferWarningModal {
  :global {
    .ant-modal-content {
      min-width: 560px;
    }
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;
      margin-bottom: 0;
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 56px 32px 32px 32px;
    }
    .ant-modal-footer {
      padding: 0;
      border-top: none;
    }
  }
}

.repeatTransferWarningContainer {
  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-bottom: 32px;
  }

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    line-height: 32px;
    letter-spacing: 0.2px;
    font-size: 20px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 8px;
  }

  > div:nth-child(3), > div:nth-child(4) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.repeatTransferWarningButtons {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-top: 48px;

  > button {
    text-transform: uppercase;
    font-size: 16px;
    line-height: 23px
  }

  > button:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    color: var(--AF6931-DFBEA4);

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }

  > button:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    color: var(--673E1D-5F391B);
    border-radius: 6px;
    width: 252px;
    height: 48px;
    padding: 12px 16px;
    background-color: var(--F9CB76-D7AA70);

    &:hover {
      background-color: var(--E8C187-BE8F60);
    }
  }
}

.customAccountSelect {
  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 16px;
    }

    .ant-select-selection-item {
      .ant-typography {
        font-size: 18px;

        > div > div {
          font-size: 14px;
          line-height: 20px;
          color: var(--606068-BCBCC2);
        }
      }

      div > span:nth-child(2) {
        display: none;
      }
    }

    .ant-select-dropdown .ant-select-item-option {
      height: 56px;
      padding-left: 16px;
      padding-right: 14px;
    }

    .ant-select-item-option-content {
      > div > div > div {
        > span:nth-child(2) {
          font-size: 12px !important;
          line-height: 16px !important;
          color: #8D8D96 !important;
        }
      }
      div {
        > span {
          font-size: 14px !important;
          line-height: 20px !important;
          margin-bottom: 2px !important;
          color: var(--606068-BCBCC2) !important;
        }
      }
    }
  }
}

.transferReferencesSection {
  display: flex;
  justify-content: space-between;

  > div {
    width: 50%;
  }

  > div:nth-child(1) {
    margin-right: 24px;
  }
}

.fromBankAccount {
  display: flex;
}

.bankAccountBalance {
  font-size: 18px;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
  font-family: Roboto, 'sans-serif';

  > span > div {
    > span {
      font-size: 18px;
      line-height: 24px;
      color: var(--36363A-FFFFFF);
    }

    > span:nth-child(1) {
      margin-left: 3px;
      margin-right: 0 !important;
    }
    > span:nth-child(2) {
      display: inline-block !important;
    }
  }
}

.payrollTransferSection {
  display: flex;
  align-items: center;
  margin: 20px 0 16px 16px;

  > div:nth-child(2) {
    margin-left: 12px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.limitBankTransferInfoSection {
  margin-bottom: 40px;
}
