@import '~theme/variables.less';

.ldsRing {
  border: 5px solid var(--EDECFE-736DBB);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-top: 5px solid var(--746BB3-2D2B46);
  border-radius: 50%;
  width: 48px;
  height: 48px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
