.lastTransactionsShimmer {
  height: 100%;
}

.shimmerElDate {
  border-radius: 16px;
  background-color: var(--FAFAFB-27272B);
  margin-bottom: 12px;
  width: 82px;
  height: 16px;
}

.shimmerEl {
  height: 60px;
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}
