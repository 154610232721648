.exportReportContainer {
  margin: 24px;
  > div:nth-child(2) {
    display: flex;
    justify-content: center;
  }

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 24px auto;
  }
}
.reportsNavigation {
  display: flex;
  margin-bottom: 16px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}
.reportModal {
  width: 640px;
  min-height: 794px;
  padding: 40px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background: var(--FFFFFF-222225);

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @media (min-width: 1680px) {
    height: 906px;
  }
}

.titleExportModal {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  letter-spacing: 0.20000000298023224px;
  text-align: left;

  color: var(--36363A-FFFFFF);
}

.accountInformationContainer {
  margin-top: 32px;
  display: flex;
  justify-content: space-between;

  > div {
    width: 100%;
  }
}

.fileFormatContainer {
  width: 268px;
  > div > div {
    border: 1px solid var(--ECECED-3D3D42) !important;
  }
}

.checkBoxSendToEmail {
  margin-top: 32px;
}

.emailAddressContainer {
  margin-top: 14px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  > div {
    width: 528px;
  }
}

.buttonsContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  > button:first-child {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 23px;
    text-align: center;
    text-transform: uppercase;

    color: var(--AF6931-DFBEA4);
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 48px;

    > svg {
      transform: rotate(180deg);
      margin-right: 8px;
      path {
        fill: var(--FCB241-DD994B);
      }
    }

    &:hover {
      color: var(--8C5020-F8C6A0);
      > svg {
        path {
          fill: var(--F19304-FEBD72);
        }
      }
    }
  }

  > button:nth-child(2) {
    height: 48px;
    width: 264px;
  }
}

.subTitleContainer {
  > span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2), > span:nth-child(4) {
    font-weight: 500;
    font-family: "Roboto-Medium",sans-serif;
  }
}

.subTitleNavigatedSpan {
  cursor: pointer;
}

.failedSubTitleContainer {
  > span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    cursor: pointer;
    color: var(--7F78E3-8782C4);
  }
}

.actionButtonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-child {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;

    height: 40px;
    width: 204px;
    background-color: var(--FEF7E9-36302B);
    color: var(--AF6931-DFBEA4);
    text-transform: uppercase;
    margin-right: 32px;

    &:hover {
      background-color: var(--FDE8C0-554639);
      color: var(--8C5020-F8C6A0);
    }
  }
  > button:nth-child(2) {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;

    height: 40px;
    width: 204px;
    background-color: var(--F9CB76-D7AA70);
    color: var(--673E1D-5F391B);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 8px;
      path {
        fill: var(--673E1D-5F391B)
      }
    }

    &:hover {
      background-color: var(--E8C187-BE8F60);
    }
  }
}

.downloadBtnDisabled {
  font-weight: 400 !important;
  font-family: "Roboto" , sans-serif !important;
  background-color: var(--F4F4F6-313135) !important;
  color: var(--9F9FA7-7A7A84)!important;
  pointer-events: none;

  svg {
    path {
      fill: var(--B3B3B9-686871) !important;
    }
  }
}

.reportDownloadLoading {
  pointer-events: none;
  background-color: var(--E8C187-BE8F60) !important;
  color: var(--673E1D-5F391B) !important;

  > span {
    margin-right: 8px;
  }

  svg {
    margin-left: 0 !important;

    path {
      fill: var(--673E1D-5F391B) !important;
    }
  }
}

.buttonExportDisabled {
  background-color: var(--F4F4F6-313135) !important;
  color: var(--9F9FA7-7A7A84)!important;
  pointer-events: none;
}

.emailAddressError {
  > input {
    border: 1px solid var(--F2B6BC-763739)!important;
    border-radius: 4px!important;
  }
  > span {
    color: var(--D92638-F45258);
  }
}
.errorText {
  color: var(--D92638-F45258);

  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;

  margin-top: 4px;
  margin-left: 16px;
}

.typeSelectorContainer {
  margin: 32px 0;
  padding: 32px 0;
  border-top: 1px solid var(--F4F4F6-313135);
  border-bottom: 1px solid var(--F4F4F6-313135);
}

.typeSelectorSection {
  display: flex;
  border-radius: 8px;
  padding: 4px;
  background: var(--FAFAFB-27272B);
  width: 100%;

  > div:nth-child(1) {
    margin-right: 4px;
  }
}

.typeSelector {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50%;
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  padding: 6px 0;
  border-radius: 6px;

  &:hover {
    background: var(--ECECED-3D3D42);
    cursor: pointer;
  }
}

.activeTypeSelector {
  background: var(--746BB3-736DBB);
  color: #FFFFFF;

  &:hover {
    background: var(--746BB3-736DBB);
    color: #FFFFFF;
  }
}

.allOperationsSection {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  height: 116px;

  > svg {
    margin-right: 24px;
    margin-top: 15px;
  }

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    width: 280px;
    color: var(--606068-BCBCC2);
  }
}

.noCounterpartiesSection {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 116px;

  > svg {
    margin-right: 24px;
    margin-top: 15px;
  }

  > div {
    width: 280px;

    > div {
      font-family: Roboto, 'sans-serif';
      font-size: 16px;
      line-height: 24px;
      color: var(--606068-BCBCC2);
    }

    > div:nth-child(2) {
      margin-top: 4px;
    }
  }
}

.onlyTransfersSection {
  margin-top: 16px;
}

.customAccountSelect {
  :global {
    .ant-select-item-option-content {
      div {
        > span:nth-child(1) {
          font-size: 14px;
          line-height: 20px;
          color: var(--606068-BCBCC2);
        }
      }
    }
  }
}

.exportReportTitle {
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    transform: translateY(2px);

    path {
      fill: var(--B3B3B9-686871);
    }
  }
}
