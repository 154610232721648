.searchInputContainer, .focusedSearchInputContainer {
  position: relative;
  border-bottom: 1px solid var(--EAEAEB-3D3D42);

  > svg {
    position: absolute;
    left: 0;
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  > input {
    font-family: 'Roboto', sans-serif;
    width: 100%;
    padding: 0;
    border: none;
    background-color: var(--FFFFFF-222225);
    color: var(--36363A-FFFFFF);
    font-weight: 400;

    &:focus {
      outline: none;
    }

    &::placeholder {
      color: var(--9F9FA7-7A7A84);
    }
  }
}

.focusedSearchInputContainer {
  border-bottom: 1px solid var(--7F78E3-736DBB);

  .cleanImage {
    svg {
      path {
        fill: var(--B3B3B9-686871);
      }
    }
    &:hover {
      svg {
        path {
          fill: var(--7F78E3-736DBB);;
        }
      }
    }
  }

  svg {
    path {
      fill: var(--7F78E3-736DBB);;
    }
  }
}

.searchInputCleanImage {
  cursor: pointer;
  position: absolute;
  right: 0;
  bottom: 10px;
  width: 24px;
  height: 24px;

  path {
    fill: var(--B3B3B9-686871);
    transform: translate(5px, 5px);
  }
}
