.missMatchCountryDataModal {
  :global {
    .ant-modal-content {
      width: 500px;
      padding: 32px;
    }
    .ant-modal-header {
      margin-bottom: 32px;

      > div {
        font-family: Jost, 'sans-serif';
        font-size: 18px;
        line-height: 26px;
        letter-spacing: 0.2px;
        color: var(--36363A-FFFFFF) !important;
      }
    }
    .ant-modal-close {
      width: 14px;
      height: 14px;
      top: 38px;
      right: 33px;
    }
  }
}

.missMatchCountryDataModalContainer {
  display: flex;
  flex-direction: column;
}

.missMatchCountryDataModalDescription, .unsupportedCountryDataModalDescription {
  width: 350px;
  margin-bottom: 24px;

  > span {
    font-family: Roboto, sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    color: var(--36363A-FFFFFF);
  }
}

.unsupportedCountryDataModalDescription {
  width: 100%;
}

.missMatchCountryDataModalInputs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;

  > svg {
    margin: 0 12px;

    path {
      fill: var(--9F9FA7-7A7A84);
    }
  }
}

.missMatchCountryDataModalInput {
  > input {
    border: 1px solid var(--F4F4F6-313135) !important;
  }

  > span {
    font-size: 12px;
    line-height: 16px;
    transform: translateY(-50%) translateX(7px);
    padding: 0 6px !important;
    color: #8D8D96;
  }
}

.unsupportedCountryEl {
  cursor: pointer;
  color: var(--7F78E3-8782C4);
  font-family: Roboto, sans-serif;
  font-size: 18px;
  line-height: 24px;

  &:hover {
    color: var(--564C94-A39ED1) !important;
  }
}

.unsupportedCountryDataModalButton {
  margin-top: 8px;
}