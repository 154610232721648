.purposeTransactionSelector {
  margin-left: 24px;

  > div:nth-child(2) {
    top: 58px;
  }

  input {
    padding: 14px 52px 14px 16px;
    text-overflow: ellipsis;
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }
}
