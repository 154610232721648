.subscriptionPaymentBanner {
  position: relative;
  width: 100%;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  background: linear-gradient(90deg, #151223 16.5%, #544A8F 100%);
  margin: 24px 0;

  &:before {
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(assets/images/cardActivationBg.webp) lightgray 50% / cover no-repeat;
    background-size: cover;
    mix-blend-mode: soft-light;
    border-radius: 12px;
  }

  > div {
    max-width: 398px;

    > div:last-child {
      margin-top: 6px;
    }
  }

  > button {
    width: 101px;
  }
}

.subscriptionPaymentBannerTitle {
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  margin-bottom: 6px;
}

.subscriptionPaymentBannerDescription {
  display: flex;
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: #FFFFFF;
  opacity: 0.8;

  > div:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
  }
}