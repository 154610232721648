.wrap{
  background: #FFFFFF;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  align-content: center;
  height: 100%;

  > img {
    max-width: 300px;
  }
}
.logo{
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 72px;
    height: 72px;
  }
}
.title{
  font-family: "Jost", sans-serif;
  font-weight: 500;
  font-size: 20px;
  line-height: 32px;
  width: 310px;
  text-align: center;
  color: #36363A;
  letter-spacing: 0.2px;
}
.download{
  font-family: "Jost", sans-serif;
  font-size: 20px;
  line-height: 150%;

  text-align: center;

  color: #F9FAFB;
}
.anyway{
  font-family: Roboto-Medium, sans-serif;
  text-align: center;
  padding: 5px 0;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  color: #AF6931;
}
