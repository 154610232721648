.exchangeWidget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  width: 100%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
}

.exchangeAmountInputTitle {
  margin-bottom: 16px;
  width: inherit;
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 18px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.exchangeAccountBalance {
  margin: 4px 0 0 16px;
  display: flex;
  width: inherit;

  span {
    font-family: Roboto, 'sans-serif' !important;
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    margin-right: 4px;
    color: var(--9F9FA7-7A7A84);
  }

  > span:nth-child(3) {
    margin-left: 8px;
    padding: 2px 8px;
    border-radius: 4px;
    background-color: var(--F4F4F6-313135);
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    cursor: pointer;
  }
}

.exchangeErrorAccountBalance {
  > span:nth-child(1) {
    color: var(--D92638-F45258);
  }
  > span:nth-child(2) {
    color: var(--D92638-F45258);
    span {
      color: var(--D92638-F45258);
    }
  }
}

.exchangeCurrenciesSwitcher {
  height: 48px;
  display: flex;
  align-items: center;
  margin-top: 20px;
  width: inherit;

  > div:nth-child(1), > div:nth-child(3) {
    width: 100%;
    height: 1px;
    background-color: var(--ECECED-3D3D42);
  }

  > div:nth-child(1) {
    margin-right: 16px;
  }

  > div:nth-child(3) {
    margin-left: 16px;
  }

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    padding: 12px;
    background-color: var(--FAFAFB-27272B);
    width: 48px;
    height: 48px;

    svg {
      transform: rotate(90deg);
      path {
        fill: var(--B3B3B9-686871);
      }
    }

    &:hover {
      cursor: pointer;
      background-color: var(--EDECFE-2D2B46);

      svg {
        path {
          fill: var(--746BB3-A39ED1);
        }
      }
    }
  }
}

.exchangeCurrenciesDisabledSwitcher {
  pointer-events: none;
}

.exchangeWidgetRate {
  display: flex;
  width: fit-content;
  margin-top: 48px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: var(--FAFAFB-27272B);

  > span {
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    color: var(--9F9FA7-7A7A84);
    margin-right: 3px;
  }

  > span:nth-child(2) {
    margin: 0 3px;
  }

  > span:nth-child(3) {
    font-family: Roboto, 'sans-serif';
    color: var(--7F78E3-8782C4);
    margin: 0 3px;
  }

  > span:nth-child(2), > span:nth-child(4) {
    font-family: Roboto-Medium, sans-serif;
    color: var(--606068-BCBCC2);
  }

  > span:nth-child(4) {
    display: flex;
  }
}

.exchangeWidgetRateValue {
  font-size: 16px;
  line-height: 24px;
  font-family: Roboto-Medium, sans-serif;
  color: var(--606068-BCBCC2);
}

.exchangeWidgetDefaultSubmitBtn {
  width: 100%;
  height: 48px;

  svg {
    margin-left: 8px;
  }
}

.exchangeWidgetCustomSubmitBtn {
  width: 322px;
}

.exchangeWidgetInputSection {
  position: relative;
  width: inherit;

  &:hover {
    input {
      border: 1px solid var(--AFABED-4F498D);
    }

    > div:nth-child(2) {
      border-top: 1px solid var(--AFABED-4F498D);
      border-right: 1px solid var(--AFABED-4F498D);
      border-bottom: 1px solid var(--AFABED-4F498D);

      svg {
        path {
          fill: var(--7F78E3-736DBB);
        }
      }
    }
  }
}

.exchangeWidgetDisabledInputSection {
  pointer-events: none;

  input {
    background-color: var(--FAFAFB-27272B) !important;
  }
}

.exchangeWidgetErrorInputSection {
  input {
    border: 1px solid var(--F2B6BC-763739) !important;
    color: var(--D92638-F45258) !important;
  }
}

.exchangeWidgetHoveredInputSection {
  input {
    border: 1px solid var(--AFABED-4F498D) !important;
  }
}

.exchangeWidgetActiveHoveredInputSection {
    border-top: 1px solid var(--AFABED-4F498D) !important;
    border-right: 1px solid var(--AFABED-4F498D) !important;
    border-bottom: 1px solid var(--AFABED-4F498D) !important;

    > span {
      color: var(--606068-BCBCC2) !important;
    }

    > svg {
      path {
        fill: var(--7F78E3-736DBB) !important;
      }
    }
}

.exchangeWidgetFocusInputSection {
  > div:nth-child(2) {
    border-top: 1px solid var(--AFABED-4F498D);
    border-right: 1px solid var(--AFABED-4F498D);
    border-bottom: 1px solid var(--AFABED-4F498D);
  }
}

.exchangeAmountInputCurrency {
  position: absolute;
  padding: 14px 16px;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  background: var(--FAFAFB-27272B);
  border-top: 1px solid var(--ECECED-3D3D42);
  border-right: 1px solid var(--ECECED-3D3D42);
  border-bottom: 1px solid var(--ECECED-3D3D42);
  border-radius: 0 4px 4px 0;
  cursor: default;

  > img {
    border-radius: 50%;
    width: 24px;
    height: 24px;
    margin-top: 2px;
    margin-right: 12px;
  }

  > span {
    font-size: 28px;
    line-height: 40px;
    color: #8D8D96;
  }

  > svg {
    margin-top: 2px;
    margin-left: 8px;
    path {
      fill: var(--B3B3B9-686871);
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
    cursor: pointer;
    border-top: 1px solid var(--AFABED-4F498D) !important;
    border-right: 1px solid var(--AFABED-4F498D) !important;
    border-bottom: 1px solid var(--AFABED-4F498D) !important;

    > span {
      color: var(--606068-BCBCC2) !important;
    }

    > svg {
      path {
        fill: var(--7F78E3-736DBB) !important;
      }
    }
  }
}

.exchangeAmountErrorInputCurrency {
  border-top: 1px solid var(--F2B6BC-763739) !important;
  border-right: 1px solid var(--F2B6BC-763739) !important;
  border-bottom: 1px solid var(--F2B6BC-763739) !important;
}

.exchangeAmountActiveInputCurrency {
  background-color: var(--EDECFE-2D2B46);
  cursor: pointer;
  border-top: 1px solid var(--7F78E3-736DBB) !important;
  border-right: 1px solid var(--7F78E3-736DBB) !important;
  border-bottom: 1px solid var(--7F78E3-736DBB) !important;

  > span {
    color: var(--36363A-FFFFFF) !important;
  }

  > svg {
    transform: rotate(180deg);
    path {
      fill: var(--746BB3-A39ED1) !important;
    }
  }
}

.exchangeWidgetAccounts {
  position: absolute;
  padding: 12px 0 8px 0;
  background-color: var(--FFFFFF-222225);
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  width: 320px;
  top: 72px;
  right: 0;
  z-index: 1;
  max-height: 408px;
  overflow: hidden;
  overflow-y: auto;
  cursor: default;

  &::-webkit-scrollbar {
    border-radius: 0 4px 4px 0;
  }
}

.exchangeWidgetAccountSection {
  display: flex;
  align-items: center;
  padding-left: 16px;
  height: 56px;

  > img {
    width: 24px;
    height: 24px;
    margin-right: 12px;
  }

  &:hover {
    background-color: var(--FAFAFB-27272B);
    cursor: pointer;
  }
}

.exchangeWidgetSelectedAccountSection {
  background-color: var(--EDECFE-2D2B46);

  &:hover {
    background-color: var(--EDECFE-2D2B46);
  }
}

.exchangeWidgetAccountInfo {
  display: flex;

  span {
    font-size: 14px;
    line-height: 20px;
    font-family: Roboto, sans-serif;
    color: var(--606068-BCBCC2);
  }
}

.exchangeWidgetAccountCurrencyName {
  font-size: 12px;
  line-height: 16px;
  font-family: Roboto, sans-serif;
  color: #8D8D96;
}

.exchangeWidgetSearchAccounts {
  position: relative;
  margin: 0 16px;
  border-bottom: 1px solid var(--ECECED-3D3D42);

  > svg {
    position: absolute;
    width: 16px;
    height: 16px;
  }

  > svg:nth-child(1) {
    top: 6px;
    left: 8px;

    path {
      transform: scale(0.6) translateY(2px);
      fill: var(--FCB241-DD994B) !important;
    }
  }

  > svg:nth-child(3) {
    cursor: pointer;
    right: 0;
    top: 9px;

    path {
      transform: scale(0.8) translate(3px, 2px);
      fill: var(--B3B3B9-686871);
    }
  }

  > input {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--36363A-FFFFFF) !important;
    background-color: var(--FFFFFF-222225);
    width: 100%;
    padding: 6px 0 6px 34px;
    outline: none;
    border: none !important;
  }

  input::placeholder {
    color: var(--9F9FA7-7A7A84) !important;
  }
}

.exchangeWidgetOnFocusSearchAccounts {
  border-bottom: 1px solid var(--7F78E3-8782C4);

  > svg:nth-child(1) {
    path{
      fill: var(--7F78E3-736DBB) !important;
    }
  }
}

.exchangeWidgetSearchHighlightedEl {
  font-family: Roboto-Medium, 'sans-serif' !important;
  color: var(--36363A-FFFFFF) !important;
}

.exchangeWidgetAccountTitle {
  margin: 16px 0 4px 16px;
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--36363A-FFFFFF);
}

.exchangeDetailsModalBody {
  padding: 32px;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 20px;
  }
}

.exchangeWidgetNoAccounts {
  margin-top: 4px;
  margin-left: 16px;
  height: 56px;
  display: flex;
  align-items: center;

  > svg {
    margin-right: 16px;
    path {
      fill: var(--D9D9DC-4E4E55);
    }
  }

  > div {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--9F9FA7-7A7A84);
  }
}

.exchangeDetailsContainer {
  border-radius: 12px;
  background-color: var(--FAFAFB-27272B);
  margin-bottom: 32px;
  padding: 0 16px;
}

.exchangeDetailsSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 64px;

  > div:nth-child(1) {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
  }
}

.exchangeDetailsData {
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  > div:nth-child(1) {
    margin-bottom: 3px;
    text-align: right;
    span {
      font-family: Roboto-Medium, 'sans-serif';
      font-size: 18px;
      line-height: 24px;
      color: var(--36363A-FFFFFF);
    }
  }
  > div:nth-child(2) {
    display: flex;
    span {
      font-family: Roboto, sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: #606068;
    }
  }
}

.exchangeDetailsRateData {
  display: flex;

  span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }

  svg {
    margin: 0 4px;
    path {
      fill: var(--B3B3B9-686871);
    }
  }
}

.exchangeDetailsButtons {
  display: flex;
  justify-content: space-between;

  button {
    height: 47px;
  }

  > button:nth-child(2) {
    width: 252px;
  }
}

.noAccountToYetText {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--9F9FA7-7A7A84);
  margin-top: 4px;
}