.filtersCardsContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.filtersByTypeSection {
  display: flex;
  border-radius: 8px;
  padding: 4px;
  background-color: var(--FAFAFB-27272B);
  height: 32px;
}

.filtersByTypeSectionShimmer {
  > div {
    width: 202px;
    height: 32px;

    > div {
      height: 100%;
      border-radius: 8px;
      background-color: var(--ECECED-3D3D42);
    }
  }
}

.filtersByViewValue {
  display: flex;
}

.filterByType, .filterByTypeActive {
  display: flex;
  align-items: center;
  padding: 4px 8px;
  font-size: 12px;
  line-height: 16px;
  color: var(--606068-BCBCC2);
  cursor: pointer;
  border-radius: 6px;
  height: 24px;
  font-family: "Roboto", sans-serif;

  > div {
    margin-top: 1px;
  }

  > span {
    margin: 1px 3px 0 3px;
    color: var(--606068-BCBCC2);
    font-family: "Roboto", sans-serif;
    font-size: 25px;
    font-weight: 500;
    line-height: 24px;
  }
}

.filterByType, .filterByView {
  &:hover {
    background-color: var(--ECECED-3D3D42);
  }
}

.filterByTypeActive {
  background-color: var(--746BB3-736DBB);
  color: #FFFFFF;
  font-family: Roboto-Medium, sans-serif;

  > span {
    color: #FFFFFF;
    font-family: Roboto-Medium, sans-serif;
  }

  &:hover {
    background-color: var(--746BB3-736DBB);
  }
}

.filterByView, .filterByViewActive {
  padding: 4px;
  border-radius: 4px;
  cursor: pointer;
  height: 32px;

  &:first-child {
    margin-right: 8px;
  }
}

.filterByViewActive {
  background-color: var(--746BB3-736DBB);

  &:hover {
    background-color: var(--746BB3-736DBB);
  }

  svg {
    path {
      fill: #FFFFFF;
    }
  }
}
