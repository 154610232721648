.transactionHistoryFilters {
  display: flex;
  margin-top: 24px;

  > div:nth-child(1) {
    margin-right: 24px;
  }
  > div:nth-child(2) {
    margin-right: 16px;
  }
  > div:nth-child(3) {
    margin-right: 24px;
  }
}

.transactionsRangePickerPeriods {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > div {
    border-radius: 4px;
    padding: 6px 12px;
    height: 32px;
    white-space: nowrap;

    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.customRangePicker {
  > div {
    width: 240px;
    padding: 6px 12px 6px 40px;

    &:hover {
      border-color: var(--7F78E3-8782C4);
    }
  }

  > span {
    padding: 0 6px 0 6px !important;
    transform: translateY(-60%) translateX(7px);
  }

  :global {
    .ant-picker-suffix {
      margin-top: 0;
      margin-left: 0;
      left: 12px;
    }

    .ant-picker-input {
      input {
        font-size: 14px;
        line-height: 20px;
      }
    }

    .ant-picker-active-bar {
      margin-inline-start: 40px;
    }
  }
}

.transactionsHistorySearch {
  padding: 6px 6px 6px 28px;
  height: 32px;
  width: 100%;

  > input {
    font-size: 14px;
    line-height: 20px;
  }

  > svg {
    width: 16px;
    height: 16px;
    top: 8px;

    path {
      transform: scale(0.65) translate(-2px, -1px);
    }
  }

  > div {
    width: 16px;
    height: 16px;
    bottom: 6px;

    > svg {
      width: 16px;
      height: 16px;

      path {
        transform: scale(0.8) translate(2px, 3px);
      }
    }
  }
}

.accountsCardFilter {
  > div:nth-child(1) {
    width: 240px;
    height: 32px;

    input {
      padding: 6px 25px 6px 12px;
      font-size: 14px;
      line-height: 20px;
      color: var(--36363A-FFFFFF);

      &:hover {
        cursor: default;
      }
    }

    > svg {
      right: 8px;
      top: 4px;
    }

    > span {
      font-size: 10px !important;
      line-height: 14px !important;
    }
  }

  > div:nth-child(2) {
    top: 36px;
    width: 320px;
  }
}
