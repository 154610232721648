.notActivatedCardPageContainer {
  margin-top: 20px
}

.orderStatusContainer {
  margin-bottom: 16px;
}

.orderStatusTitle {
  display: flex;
  justify-content: space-between;
  margin-left: 16px;
  margin-bottom: 19px;
  align-items: center;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);

    &:hover {
      cursor: pointer;
      color: var(--8C5020-F8C6A0);
    }
  }
}

.orderStatusSection {
  display: flex;

  > div {
    width: 100%;
  }

  > div:not(:last-child) {
    position: relative;
    margin-right: 16px;

    &:after {
      content: '';
      position: absolute;
      width: 17px;
      right: -17px;
      height: 1px;
      background-color: var(--D9D9DC-4E4E55);
    }
  }
}

.activateCardBanner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 112px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background: linear-gradient(90deg, #151223 16.5%, #544A8F 100%);
  padding: 16px 24px;

  &:before {
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(assets/images/cardActivationBg.webp) lightgray 50% / cover no-repeat;
    mix-blend-mode: soft-light;
    border-radius: 12px;
  }

  > button {
    width: 106px;
  }
}

.accountBlockSection {
  margin-top: 20px;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 18px;
    line-height: 26px;
    letter-spacing: 0.2px;
    margin-bottom: 12px;
    margin-left: 16px;
    color: var(--36363A-FFFFFF);
  }
}