.businessCards {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--F2F2F2-313135);
  padding: 0 48px 40px 48px;
  overflow-y: auto;
  overflow-x: hidden;
  min-width: 682px;

  > div:nth-child(1) {
    position: sticky;
    background: var(--F2F2F2-313135);
    top: 0;
    z-index: 100;
    padding-bottom: 25px;
    padding-top: 40px;
    min-width: 586px;
  }

  &::-webkit-scrollbar {
    width: 12px;
    height: 4px;
    background-color: var(--F2F2F2-313135);
  }

  &::-webkit-scrollbar-thumb {
    background-color: var(--ECECED-3D3D42) !important;
  }

  &::-webkit-scrollbar-track {
    background-color: var(--F2F2F2-313135) !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    padding-right: 4px !important;
  }

  @media (max-width: 1440px) {
    width: 54%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 42.5%;
  }

  @media (min-width: 1920px) {
    padding-left: 0;
  }
}

.businessCardsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;

  > div {
    font-family: "Jost", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }
}

.getNewCard {
  border-radius: 6px;
  border: 1px solid var(--E8C187-BE8F60);
  background-color: var(--FFFFFF-222225);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  padding: 0 12px 0 16px;

  > span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--AF6931-DFBEA4);
    margin-right: 8px;
  }

  &:hover {
    background-color: var(--FEF7E9-36302B);

    > span {
      color: var(--8C5020-F8C6A0);
    }

    svg {
      path {
        fill: var(--F19304-FEBD72)
      }
    }
  }
}

.cardsBlockContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  min-width: 590px;
  padding-right: 4px;
  margin-top: 3px;
}

.noCardsContainer {
  position: absolute;
  left: 50%;
  top: 50%;
  margin-top: 40px;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  
  img {
    width: 280px;
  }
}

.noCardsTitle {
  font-family: 'Jost', sans-serif;
  line-height: 36px;
  letter-spacing: 0.2px;
  font-size: 28px;
  color: var(--36363A-FFFFFF);
  margin-top: 48px;
}

.noCardsSubTitle {
  width: 350px;
  font-family: "Roboto", sans-serif;
  text-align: center;
  margin: 16px 0 40px 0;
  font-size: 18px;
  line-height: 24px;
  color: var(--606068-BCBCC2);

  > div:nth-child(2) > span:nth-child(2) {
    font-family: "Roboto-Medium", sans-serif;
  }
}

.noCardBtn {
  height: 48px;
  width: 281px;

  svg {
    margin-left: 8px;

    path {
      fill: var(--673E1D-5F391B);
    }
  }
}
