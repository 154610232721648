.newProfileContainer {
  display: flex;
  width: 100%;
  height: 100%;

  @media (max-width: 900px) {
    width: 1000px;
    background-color: var(--FFFFFF-222225);
  }
}

.newProfileLoader {
  width: inherit !important;
}

.newProfileLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;

  .logo {
    width: 102px;
    height: 36.24px;
    margin: 27.76px 0 0 44px;
    cursor: pointer;

    svg {
      width: 102px;
      height: 36.24px;
    }
  }

  .footer {
    display: flex;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    font-size: 10px;
    line-height: 14px;
    margin-bottom: 44px;
  }
}

.newProfileFormContainer {
  display: flex;
  height: 100%;
  margin: 0 auto;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 408px;

  .formTitle {
    font-family: 'Jost', sans-serif;
    text-align: center;
    font-weight: 500;
    font-size: 28px;
    line-height: 36px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 48px;
  }
}

.newProfileButtons {
  display: flex;
  flex-direction: column;
  margin-top: 48px;

  .buttonContinue {
    font-family: Roboto-Medium, sans-serif;
    font-weight: 500;
    white-space: nowrap;
    background: var(--F9CB76-D7AA70);
    color: var(--673E1D-5F391B);
    font-size: 16px;
    line-height: 23px;
    border-radius: 6px;
    height: 48px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      background: var(--E8C187-BE8F60);
    }
  }

  .buttonContinueDisabled {
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    white-space: nowrap;
    background: var(--F4F4F6-313135);
    color: var(--9F9FA7-7A7A84);
    font-size: 16px;
    line-height: 23px;
    border-radius: 6px;
    height: 48px;
    text-transform: uppercase;
    display: block;
    pointer-events: none;
  }

  .buttonCancel {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    white-space: nowrap;
    background: var(--FFFFFF-222225);
    color: var(--AF6931-DFBEA4);
    border-radius: 6px;
    height: 48px;
    margin-top: 16px;
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    cursor: pointer;

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }
}

.newProfileForm {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > div:first-child {
    width: 48%;
  }
  > div:nth-child(2) {
    width: 48%;
  }
  > div:nth-child(n + 3) {
    width: 100%;
    margin-top: 24px;
  }

  .website {
    > span {
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 16px;
      margin-top: 4px;
      margin-left: 14px;
      color: var(--9F9FA7-7A7A84);
    }
  }

  :global {
    .ant-form-item-explain-error {
      bottom: -13px;
    }
  }
}

.appInput  {
  > input {
    height: 48px;
  }
  > span {
    font-size: 16px;
  }
}

.newProfileCountrySelector, .newProfileIndustrySelector {
  height: 48px;

  input {
    height: 48px;
    line-height: 24px !important;
  }

  > div:nth-child(2) {
    > div > div {
      height: 40px !important;
    }
  }
}

.newProfileCountrySelector {
  input {
    padding: 15px 12px 12px 50px !important;
  }
}

.newProfileRightSection, .newProfileRightSectionLoading {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    display: block;
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}

.newProfileRightSectionLoading {
  img {
    display: none;
  }
}
