.cardBanner {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 112px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background: linear-gradient(90deg, #151223 16.5%, #544A8F 100%);
  padding: 16px 24px;

  &:before {
    content: '';
    left: 0;
    width: 100%;
    height: 100%;
    position: absolute;
    background: url(assets/images/cardActivationBg.webp) lightgray 50% / cover no-repeat;
    mix-blend-mode: soft-light;
    border-radius: 12px;
  }

  > button {
    width: 106px;
  }
}

.cardBannerMainSection {
  display: flex;

  > svg {
    z-index: 1;
    width: 80px;
    height: 80px;
    margin-right: 16px;
  }

  > div:nth-child(1) {
    z-index: 1;
    width: 80px;
    height: 80px;
    margin-right: 16px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      margin-top: 5px;
    }
  }
}

.cardBannerDescription {
  display: flex;
  flex-direction: column;
  margin-top: 5px;
  max-width: 270px;

  > div:nth-child(1) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: #FFFFFF;
    margin-bottom: 6px;
  }

  > div:nth-child(2) {
    font-family: Roboto, sans-serif;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.8;
    color: #FFFFFF;
  }
}