.button {
  font-size: 16px;
  line-height: 23px;
  text-transform: uppercase;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.primaryButton {
  font-family: Roboto-Medium, sans-serif;
  color: var(--673E1D-5F391B);
  background: var(--F9CB76-D7AA70);
  border-radius: 6px;

  svg {
    path {
      fill: var(--673E1D-5F391B);
    }
  }

  &:hover {
    background: var(--E8C187-BE8F60);
  }
}

.emptyButton {
  font-family: "Roboto", sans-serif;
  text-align: center;
  color: var(--AF6931-DFBEA4);
  padding: 0;

  svg {
    transform: rotate(180deg);
    margin-right: 8px;
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    color: var(--8C5020-F8C6A0);

     > svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.ghostButton {
  font-family: Roboto-Medium, sans-serif;
  text-align: center;
  color: var(--AF6931-DFBEA4);
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    color: var(--8C5020-F8C6A0);
    background-color: var(--FDE8C0-554639);

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.dangerButton {
  border-radius: 6px;
  font-family: Roboto-Medium, sans-serif;
  color: var(--D92638-F45258);
  background-color: var(--FBE5E7-3A1D1E);

  &:hover {
    color: var(--36363A-FFFFFF);
    background-color: var(--F2B6BC-763739);
  }
}

.strokeButton {
  border-radius: 6px;
  font-family: Roboto-Medium, sans-serif;
  background-color: var(--FFFFFF-222225);
  color: var(--AF6931-DFBEA4);
  border: 1px solid var(--E8C187-BE8F60);

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    color: var(--8C5020-F8C6A0);
    background-color: var(--FEF7E9-36302B);

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.disabledBtn {
  pointer-events: none;
  background-color: var(--F4F4F6-313135) !important;
  color: var(--9F9FA7-7A7A84) !important;
  font-family: Roboto, sans-serif !important;

  svg {
    path {
      fill: var(--B3B3B9-686871) !important;
    }
  }
}

.loadingButton {
  pointer-events: none;
  background-color: var(--E8C187-BE8F60);
}
