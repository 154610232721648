.generatedReportsContainer, .generatedReportsContainerEmpty {
  width: 100%;
  overflow-x: auto;

   > div {
     width: 100%;
     margin: 24px;

     @media (max-width: 1280px) {
       width: 1200px;
     }

     @media (min-width: 1680px) {
       width: 1552px;
       margin: 24px auto;
     }
   }
}

.generatedReportsContainerEmpty {
  > div {
    height: calc(100% - 48px);
  }
}

.generatedReportsNavigation {
  display: flex;
  margin-bottom: 16px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}

.generatedReportsMainSection, .generatedReportsMainSectionEmpty {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  background: var(--FFFFFF-222225);
  border-radius: 12px;

  :global {
    .ant-spin-container {
      height: 100%;
    }

    .ant-table-placeholder {
      &:hover {
        > td {
          background-color: var(--FFFFFF-222225) !important;
        }
      }
    }

    .ant-table-tbody > tr > td:last-child {
      padding: 16px !important;
    }

    .ant-pagination {
      margin: 24px 0 0 0 !important;
    }
  }
}

.generatedReportsMainSectionEmpty {
  height: calc(100% - 32px);

  :global {
    .ant-table-empty {
      height: calc(100% - 62px);
    }

    .ant-table-tbody {
      > tr > td {
        border-bottom: none !important
      }
    }
  }
}

.generateReportsTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.actionCell {
  display: flex;
  justify-content: flex-end;
}

.actionContainer {
  display: flex;

  > div {
    width: 32px;
    height: 32px;
    display: flex;
    border-radius: 6px;
    justify-content: center;
    align-items: center;
    > svg {
      path {
        fill: var(--D9D9DC-4E4E55);
      }
    }

    &:hover {
      background-color: var(--FDE8C0-554639);
      cursor: pointer;
        > svg {
          path {
            fill: var(--F19304-FEBD72);
          }
        }
    }
  }

  > div:nth-child(2) {
    margin-left: 24px;
  }

}
.subTitleContainer {
  > span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    font-weight: 500;
    font-family: "Roboto-Medium",sans-serif;
  }
}

.okayBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 7px 16px;
  background-color: var(--F9CB76-D7AA70);
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  color: var(--673E1D-5F391B);

  &:hover {
    background: var(--E8C187-BE8F60);
  }
}

.amountCounterparty {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  text-align: left;

  color: var(--9F9FA7-7A7A84);
}

.dashCounterparty {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  color: var(--9F9FA7-7A7A84);
}

.counterparty {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;

  width: 600px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;

  color: var(--36363A-FFFFFF);
}

.date {
  font-family: "Roboto-Medium", sans-serif;
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  text-align: left;

  color: var(--36363A-FFFFFF);
}

.tableRow {
  font-family: "Roboto", sans-serif;
  font-size: 14px;
  line-height: 20px;
  text-align: left;
  color: var(--36363A-FFFFFF);
}

.tooltip {
  :global {
    .ant-tooltip-inner {
      width: max-content;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      text-align: left;

      background-color: var(--FFFFFF-222225);
      padding: 10px;
      color: var(--36363A-FFFFFF);
      box-shadow: 0 4px 16px 0 var(--B2BEC340-131316CC);
    }
    .ant-tooltip-arrow-content::before {
      background: var(--FFFFFF-222225);
    }

    .ant-tooltip-arrow {
      &:before {
        background: var(--FFFFFF-222225);
      }
    }
  }
}

.onlyTransfersText {
  font-family: "Roboto", sans-serif;
  font-size: 10px;
  font-weight: 400;
  line-height: 14px;
  color: var(--9F9FA7-7A7A84);
  margin-top: 2px;
}

.reportTypeCol {
  display: flex;
  flex-direction: column;
}

.colWithSort {
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    margin-left: 4px;
  }
}

.colWithSortDesc {
  display: flex;
  align-items: center;
  cursor: pointer;
  > svg {
    margin-left: 4px;
    transform: rotate(180deg);
  }
}

.localeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 368px);
}

.headerNoReports {
  font-family: "Jost", sans-serif;
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  text-align: center;
  color: var(--606068-BCBCC2);
}

.noReportsText {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: center;

  color: var(--8D8D96-7A7A84);
}
