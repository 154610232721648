.appMultipleSelectWithNames {
  position: relative;
}

.selectValueContainer {
  display: flex;
  border: 1px solid var(--ECECED-3D3D42);
  border-radius: 4px;
  padding: 13px 40px 13px 14px;
  background-color: var(--FFFFFF-222225);
  outline: none;
  height: 48px;
  width: 100%;

  &:hover {
    border: 1px solid var(--7F78E3-736DBB);
    border-radius: 4px;
  }

  > div:nth-child(1) {
    color: var(--2C2C30-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    white-space: pre-wrap;
  }

  > div:nth-child(2) {
    margin-left: 4px;
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
  }
}

.searchContainer {
  position: relative;

  input {
    width: 100%;
    border: 1px solid var(--ECECED-3D3D42);
    border-radius: 4px;
    color: var(--2C2C30-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 40px 13px 14px;
    background-color: var(--FFFFFF-222225);
    outline: none;
    text-overflow: ellipsis;

    &:hover {
      border: 1px solid var(--7F78E3-736DBB);
      border-radius: 4px;
    }
  }

  input::placeholder {
    color: var(--9F9FA7-7A7A84);
  }

  > span {
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 12px 13px 14px;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
  }
}

.openSearchContainer {
  input {
    border: 1px solid var(--7F78E3-736DBB);
    border-radius: 4px;
    outline: none;
  }

  > span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    opacity:1;
    padding: 0 6px 4.5px 6px;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-736DBB);
    background: var(--FFFFFF-222225);
  }
}

.inputArrow, .inputUpArrow {
  position: absolute;
  right: 8px;
  top: 14px;
  cursor: pointer;
}

.inputUpArrow {
  transform: rotate(180deg);

  path {
    fill: var(--7F78E3-736DBB);
  }
}

.dropdown {
  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  box-shadow: var(--B2BEC340-131316CC);
  width: 100%;
  z-index: 100;
  background-color: var(--FFFFFF-222225);
  top: 54px;
  overflow-y: auto;
  max-height: 344px;
}

.dropdownItem, .emptyItem {
  display: flex;
  align-items: center;
  padding: 0 12px 0 50px;
  height: 48px;
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;

  svg {
    position: absolute;
    left: 14px;

    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
    cursor: pointer;
  }
}

.selectWithValue {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
  opacity: 1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(7px);
  background: var(--FFFFFF-222225);
  color: #8D8D96;
}

.selectWithoutValue {
  display: none;
}

.emptyItem {
  color: var(--9F9FA7-7A7A84);

  svg {
    margin-right: 12px;
    path {
      fill: var(--D9D9DC-4E4E55);
    }
  }

  > span {
    font-size: 14px;
    line-height: 20px;
  }

  &:hover {
    background-color: var(--FFFFFF-222225);
    cursor: default;
  }
}

.dropdownFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
  border-top: 1px solid var(--D9D9DC-4E4E55);

  > div:nth-child(1) > span:nth-child(1), > div:nth-child(2) {
    font-family: Roboto-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
    cursor: pointer;

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }

  > div:nth-child(1) > span:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: #8D8D96;
  }
}

.selectedValuesContainer {
  margin-top: 12px;
  max-height: 56px;
  overflow: hidden;
}

.collapsedSelectValueContainer {
  max-height: 100%;
  overflow: visible;
}

.selectedValues {
  display: flex;
  flex-wrap: wrap;
  row-gap: 8px;

  > div {
    &:not(:last-child) {
      margin-right: 8px;
    }
  }
}

.selectedValue {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px 6px 2px 8px;
  border-radius: 4px;
  background-color: var(--EDECFE-2D2B46);

  &:hover {
    background-color: var(--AFABED-4F498D);
    color: #FFFFFF;
    > div {
      color: #FFFFFF;
    }
    > svg {
      path {
        fill: #FFFFFF;
      }
    }
  }

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--564C94-A39ED1);
    margin-right: 6px;
  }

  svg {
    cursor: pointer;

    path {
      transform: scale(0.85) translate(2px, 2px);
      fill: var(--7F78E3-736DBB);
    }

    &:hover {
      path {
        fill: #FFFFFF;
      }
    }
  }
}

.showBtn {
  display: inline-block;
  margin-top: 10px;
  font-family: Roboto-Medium, sans-serif;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
  color: var(--AF6931-DFBEA4);
  cursor: pointer;

  &:hover {
    color: var(--8C5020-F8C6A0);
  }
}

.openDropdown {
  position: relative !important;
}

