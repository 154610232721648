.cardDeliveryIssue {
  > svg {
    margin-bottom: 24px;
  }

  > div {
    text-align: center;
  }

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 16px;
  }

  > div:nth-child(3), > div:nth-child(4) {
    width: 376px;
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }

  > div:nth-child(4) {
    margin-bottom: 8px;
  }

  > div:nth-child(5) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }

  > button {
    width: 240px;
    height: 40px;
    margin-top: 48px;
  }
}