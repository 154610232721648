.authInProgressContainer {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: var(--FFFFFF-222225);
  @media (max-width: 900px) {
    width: 1000px;
    background-color: var(--FFFFFF-222225);
  }
}

.authInProgressLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 144px;
  }

  & .footer {
    display: flex;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 42px;
  }
}

.authInProgressMainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;

  > svg {
    margin-top: 64px;
    margin-bottom: 40px;
  }
}

.authInProgressRightSection {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}

.authInProgressBody {
  width: 408px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > span:nth-child(1) {
    margin-bottom: 16px;
    font-family: "Jost", sans-serif;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;

    color: var(--36363A-FFFFFF);
  }

  > span:nth-child(2) {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    text-align: center;

    color: var(--606068-BCBCC2);
  }
}

.authInProgressBtn {
  font-family: "Roboto", sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 23px;
  text-align: center;
  text-transform: uppercase;

  background-color: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  display: flex;
  justify-content: center;
  align-items: center;

  margin-top: 70.92px;

  height: 48px;
  width: 100%;

  &:hover {
    background-color: var(--E8C187-BE8F60);
  }

  > svg {
    margin-left: 11px;
    path {
      fill: var(--673E1D-5F391B);
    }
  }
}

