.lastReportItem {
  border-radius: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid var(--ECECED-3D3D42);
  background-color: var(--FFFFFF-222225);
  padding: 17px 24px;

  &:hover {
    background-color: var(--FAFAFB-27272B);
    cursor: pointer;

    .lastReportItemTypeIcon {
      svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
    }
  }
}

.lastReportItemMainContainer {
  display: flex;
}

.lastReportItemTypeIcon {
  margin-right: 24px;
  height: 40px;
  margin-top: 5px;

  svg {
    path {
      transform: scale(1.4) translate(-4px, -3.5px);
      fill: var(--ECECED-3D3D42)
    }
  }
}

.lastReportItemMainSection {
  display: flex;
  flex-direction: column;
}

.lastReportItemDate {
  display: flex;
  align-items: center;
  height: 24px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.lastReportNewLabel {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: var(--E2F3ED-173B35);
  height: 20px;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--12836E-74BEA5);
    margin-right: 4px;
  }

  svg {
    width: 16px;
    height: 16px;
  }
  svg * {
    width:  100%;
    height: 100%;
  }
}

.lastReportGeneratingLabel {
  display: flex;
  align-items: center;
  margin-left: 8px;
  padding: 2px 6px;
  border-radius: 6px;
  background-color: var(--EDECFE-2D2B46);
  height: 20px;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    font-weight: 400;
    color: var(--7F78E3-8782C4);
    margin-right: 4px;
  }

}

.lastReportItemSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--9F9FA7-7A7A84);
  margin-top: 4px;
}

.lastReportItemIcons {
  display: flex;

  > div {
    padding: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;

    &:hover {
      background-color: var(--FDE8C0-554639) !important;

      svg {
        path {
          fill: var(--FCB241-DD994B);
        }
      }
    }
  }

  svg {
    width: 16px;
    height: 16px;

    path {
      fill: var(--D9D9DC-4E4E55);
    }
  }

  > div:nth-child(2) {
    margin-left: 24px;
    svg {
      margin-left: 3px;
      margin-top: 2px;
    }
  }
}

.lastReportItemIconTooltip {
  :global {
    .ant-tooltip-inner {
      width: max-content;
      border-radius: 2px;
      background-color: var(--FFFFFF-222225);
      padding: 8px;
      font-family: "Roboto", sans-serif;
      font-size: 12px;
      line-height: 16px;
      color: var(--36363A-FFFFFF);
    }
    .ant-tooltip-arrow::before {
      background: var(--FFFFFF-222225);
    }
  }
}
.subTitleContainer {
  > span {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(2) {
    font-weight: 500;
    font-family: "Roboto-Medium",sans-serif;
  }
}

.okayBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 6px;
  padding: 7px 16px;
  background-color: var(--F9CB76-D7AA70);
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  text-transform: uppercase;
  color: var(--673E1D-5F391B);

  &:hover {
    background: var(--E8C187-BE8F60);
  }
}
