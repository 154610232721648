.companyInfo {
  display: flex;
  justify-content: center;
  align-items: center;

  :global {
    .ant-avatar {
      margin-right: 12px;
      background: var(--746BB3-736DBB);
      border-radius: 4px;
    }
  }
}

.companyAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 44px;

   > div {
     font-family: Roboto-Medium, sans-serif;
     color: var(--242320-FFFFFF);
     font-weight: 500;
     font-size: 14px;
     line-height: 20px;
   }
}

.userAvatar {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  padding: 4px;

  @media (min-width: 1920px) {
    padding-right: 0;
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
    border-radius: 4px;
  }

   > div:nth-child(2) {
     font-family: Roboto-Medium, sans-serif;
     font-weight: 500;
     font-size: 14px;
     line-height: 20px;
     color: var(--242320-FFFFFF);
     margin-right: 16px;
   }
  > div:nth-child(3) {
    margin-top: 6px;

    path {
      fill: var(--B3B3B9-686871);
    }
  }
}

.userAvatarOpen {
  background-color: var(--EDECFE-2D2B46);
  border-radius: 4px;

  > div:nth-child(3) {
    path {
      fill: var(--7F78E3-736DBB)
    }
  }
}

.profileMenu {
  display: flex;
  flex-direction: column;
  width: 200px;
  background: var(--FFFFFF-222225);
  border-radius: 4px;
  padding: 4px 0;
  box-shadow: var(--B2BEC340-131316CC);
}

.profileMenuItem {
  display: flex;
  align-items: center;
  height: 48px;
  padding: 0 12px 0 14px;
  font-size: 14px !important;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--58585F-FFFFFF);
  cursor: pointer;
  margin: 0;

  &:hover {
    background-color: var(--EDECFE-2D2B46);
  }

  > span:nth-child(2) {
    padding-left: 0;
  }
}

.profileMenuSecondBLock {
  border-top: 1px solid var(--EAEAEB-3D3D42);
  border-bottom: 1px solid var(--EAEAEB-3D3D42);

  > div {
    display: flex;
    flex-direction: column;

    > label {
      justify-content: space-between;
    }
  }

  :global {
    .ant-radio {
      order: 2
    }
    .ant-radio-inner {
      width: 20px;
      height: 20px;
    }
  }
}

.profileMenuFeatureToggles {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid var(--EAEAEB-3D3D42);
  align-items: center;
  height: 48px;
  padding: 0 12px 0 14px;
  font-size: 14px;
  line-height: 20px;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  color: var(--58585F-FFFFFF);
  cursor: pointer;
  margin: 0;
}

.profileMenuModal {
  :global {
    .ant-modal-content {
      box-shadow: var(--B2BEC340-131316CC);
      min-width: 236px;
    }
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;

      > div {
        font-family: "Jost", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        color: var(--36363A-FFFFFF);
      }
    }
    .ant-modal-body {
      padding: 0 32px 32px 32px;
    }
    .ant-avatar {
      background:  var(--746BB3-736DBB);
      border-radius: 50%;
      width: 48px;
      height: 48px;

      > span {
        font-family: "Roboto", sans-serif;
        font-size: 18px;
        font-weight: 500;
        line-height: 24px;
        transform: scale(1) translate(-50%, 45%) !important;
        color: #FFFFFF;
      }
    }

    .ant-modal-close {
      top: 35px;
      svg {
        path {
          fill: var(--D9D9DC-4E4E55) !important;
        }
      }
    }
  }
}

.profileMenuModalContainer {
  display: flex;
  flex-direction: column;
}

.modalUserInfoSection {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
  border-bottom: 1px solid var(--F4F4F6-313135);
}

.modalUserInfo {
  display: flex;
  flex-direction: column;
  margin-left: 16px;
  padding-bottom: 4px;

  > span {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: var(--36363A-FFFFFF);
  }
}

.modalCompanyInfo {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  > span:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--8D8D96-7A7A84);
    font-weight: 400;
  }

  > span:nth-child(2) {
    font-size: 16px;
    line-height: 24px;
    font-weight: 400;
    color: var(--36363A-BCBCC2);
    font-family: "Roboto", sans-serif;
  }
}
