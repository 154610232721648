.container {
  border-top: 1px solid var(--EAEAEB-3D3D42) ;
  height: 80px;
  display: flex;
  justify-content: center;
}

.collapsedTimeContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important;
}

.dayWithMonthYear, .hhMmCollapsed {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--606068-BCBCC2);
}

.offset {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--B3B3B9-686871);
}

.dayWithMonth {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--606068-BCBCC2);
  margin-bottom: 4px;
}

.hhMm {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  text-align: left;
  color: var(--606068-BCBCC2);
  border-right: 1px solid var(--F4F4F6-27272B);
  padding-right: 4px;
  margin-right: 4px;
  height: 10px;
  display: flex;
  align-items: center;
  margin-left: 12px;
}

.currentTimeContainer {
  display: flex;
  justify-content: center;
  align-items: center;
}
