.beneficiaryUserContainer, .selectedBeneficiaryUser {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 80px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--EAEAEB-3D3D42);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.selectedBeneficiaryUser {
  border: 1px solid var(--7F78E3-8782C4);

  .beneficiaryUser {
    svg {
      path {
        fill: var(--7F78E3-736DBB);
      }
    }
  }

  &:hover {
    border: 1px solid var(--746BB3-736DBB) !important;
    background-color: var(--FAFAFB-27272B);

    > div:nth-child(1) svg {
      path {
        fill: var(--746BB3-A39ED1) !important;
      }
    }
  }
}

.beneficiaryUserContainer {
  &:hover {
    background-color: var(--FAFAFB-27272B);
    border: 1px solid var(--ECECED-3D3D42);
    .beneficiaryUserIcons {
      > div:nth-child(1) {
        svg {
          display: block;
        }
      }
    }
    .beneficiaryUserIcon {
      background-color: var(--F9CB76-D7AA70);

      > span {
        color: var(--673E1D-5F391B);
      }

      svg {
        path {
          fill: var(--673E1D-5F391B);
        }
      }
    }
    .beneficiaryUser {
      > div {
        color: var(--36363A-FFFFFF);
        font-weight: 500;
      }

      > svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
    }
  }
}

.trashIcon {
  path {
    fill: var(--B3B3B9-686871);
  }

  &:hover {
    path {
      fill: var(--7F78E3-736DBB);
    }
  }
}

.beneficiaryUser {
  display: flex;
  align-items: center;

  path {
    fill: var(--ECECED-3D3D42)
  }


  > div {
    font-size: 18px;
    line-height: 24px;
    font-family: "Roboto", sans-serif;
    color: var(--606068-BCBCC2);
    margin-left: 24px;
  }
}

.beneficiaryUserIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);
  height: 40px;
  width: 117px;
  padding: 7px 12px 7px 16px;

  > span {
    text-transform: uppercase;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    line-height: 23px;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  &:hover {
    background-color: var(--E8C187-BE8F60) !important;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      transform: translate(8px, 4px);
      fill: var(--FCB241-DD994B);
    }
  }
}

.beneficiaryUserIcons {
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    svg {
      display: none;
      margin-right: 24px;
      cursor: pointer;
    }
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;
  padding: 0 32px 32px 32px;

  > button:nth-child(1) {
    font-size: 16px;
    line-height: 23px;
    height: 40px;
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    color: var(--AF6931-DFBEA4);
    background-color: transparent;
    padding: 0;
    cursor: pointer;

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }
  > button:nth-child(2) {
    width: 202px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--D92638-F45258);
    padding: 7px 0;
    background-color: var(--FBE5E7-3A1D1E);
    border-radius: 6px;
    cursor: pointer;

    svg {
      margin-left: 8px;

      path {
        fill: var(--E15C69-C7383D);
      }
    }

    &:hover {
      color: var(--36363A-FFFFFF);
      background-color: var(--F2B6BC-763739);

      svg {
        path {
          fill: var(--D92638-F45258);
        }
      }
    }
  }
}

.beneficiaryUserModal {
  height: 218px;

  :global {
    .ant-modal-content {
      box-shadow: var(--B2BEC340-131316CC);
      min-width: 500px;
    }
    .ant-modal-header {
      padding: 32px;
      border-bottom: none;
      margin-bottom: 0;

      > div {
        font-size: 18px;
        font-weight: 500;
        line-height: 26px;
        font-family: 'Jost', sans-serif;
        color: var(--36363A-FFFFFF);
      }
    }
    .ant-modal-body {
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 24px;
      color: var(--606068-BCBCC2);
    }
    .ant-modal-close {
      display: none;
    }
    .ant-modal-body {
      padding: 0 32px 32px 32px;
    }
    .ant-modal-footer {
      padding: 0;
      border-top: none;
      margin-top: 0;
    }
  }
}
