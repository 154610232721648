.appMultipleSelect {
  position: relative;
}

.inputContainer {
  position: relative;

  input {
    width: 100%;
    border: 1px solid var(--ECECED-3D3D42);
    border-radius: 4px;
    color: var(--2C2C30-FFFFFF);
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 22px;
    padding: 13px 40px 13px 14px;
    background-color: var(--FFFFFF-222225);
    caret-color: transparent;
    outline: none;
    text-overflow: ellipsis;

    &:hover {
      border: 1px solid var(--7F78E3-736DBB);
      border-radius: 4px;
    }
  }

  input::placeholder {
    color: var(--9F9FA7-7A7A84);
  }

  > span {
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 400;
    line-height: 24px;
    padding: 13px 12px 13px 14px;
    pointer-events: none;
    position:absolute;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
    opacity:0.5;
  }
}

.openInputContainer {
  input {
    border: 1px solid var(--7F78E3-736DBB);
    border-radius: 4px;
    outline: none;
  }

  > span {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    opacity:1;
    padding: 0 6px 4.5px 6px;
    transform: translateY(-50%) translateX(7px);
    color: var(--7F78E3-736DBB);
    background: var(--FFFFFF-222225);
  }
}

.inputArrow, .inputUpArrow {
  position: absolute;
  right: 8px;
  top: 14px;
  cursor: pointer;
}

.inputUpArrow {
  transform: rotate(180deg);

  path {
    fill: var(--7F78E3-736DBB);
  }
}

.dropdown {
  position: absolute;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  box-shadow: var(--B2BEC340-131316CC);
  width: 100%;
  z-index: 100;
  background-color: var(--FFFFFF-222225);
  top: 54px;
  overflow-y: auto;
  max-height: 344px;
}

.dropdownItem {
  display: flex;
  align-items: center;
  padding: 0 12px 0 50px;
  height: 48px;
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  position: relative;

  svg {
    position: absolute;
    left: 14px;

    path {
      fill: var(--7F78E3-736DBB);
    }
  }

  &:hover {
    background-color: var(--EDECFE-2D2B46);
    cursor: pointer;
  }
}

.selectWithValue {
  font-family: "Roboto", sans-serif !important;
  font-size: 12px !important;
  line-height: 16px !important;
  opacity: 1 !important;
  padding: 0 6px 4.5px 6px !important;
  transform: translateY(-50%) translateX(7px);
  background: var(--FFFFFF-222225);
  color: #8D8D96;
}

.selectWithoutValue {
  display: none;
}

.dropdownFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 48px;
  padding: 8px 16px;
  border-top: 1px solid var(--D9D9DC-4E4E55);

  > div:nth-child(1) > span:nth-child(1), > div:nth-child(2) {
    font-family: Roboto-Medium, sans-serif;
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    color: var(--AF6931-DFBEA4);
    cursor: pointer;

    &:hover {
      color: var(--8C5020-F8C6A0);
    }
  }

  > div:nth-child(1) > span:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: #8D8D96;
  }
}
