.newCardContainer {
  margin: 24px;

  > div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    > div:nth-child(2) {
      margin-bottom: 24px;
    }
  }

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 24px auto;
  }
}

.newCardNavigation {
  display: flex;
  margin-bottom: 32px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}

.selectTypeTitle {
  font-family: Jost, 'sans-serif';
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 24px;
}

.newCardTypeContainer, .newCardTypeContainerDisabled {
  display: flex;
  height: 320px;
  width: 640px;

  > img {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border: 1px solid var(--ECECED-3D3D42);
    border-right: none;
    width: 296px;
  }

  &:hover {
    cursor: pointer;

    > img, .newCardTypeSection {
      border: 1px solid var(--7F78E3-8782C4);
    }
    > img {
      border-right: none;
    }
    .newCardTypeSection {
      border-left: none;
    }
  }
}

.selectTypeNewCardImg {
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    border: 1px solid var(--ECECED-3D3D42);
    border-right: none;
}

.selectTypeNewVirtualCardImg, .selectTypeNewPhysicalCardImg {
  display: none;
}

.newCardTypeContainerDisabled {
  &:hover {
    cursor: default;
  }

  > img, .newCardTypeSection {
    border: none !important;
  }
}

.newCardTypeImgLoader {
    width: 296px;
    height: 320px;
    position: relative;
    top: initial;
    min-height: initial;
    border: 1px solid var(--ECECED-3D3D42);
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
}

.newCardTypeSection {
  width: 344px;
  background-color: var(--FFFFFF-222225);
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--ECECED-3D3D42);
  border-left: none;

  > div > div:nth-child(2) {
    margin-bottom: 16px;
  }
}

.newCardTypeSectionTitle {
  font-family: Jost, 'sans-serif';
  font-size: 24px;
  letter-spacing: 0.2px;
  line-height: 32px;
  margin-bottom: 20px;
  color: var(--36363A-FFFFFF);
}

.newCardTypeSectionElem, .newCardTypeSectionCustomElem {
  display: flex;
  align-items: center;

  > div:nth-child(1) {
    width: 40px;
    height: 40px;
    border-radius: 40px;
    background-color: var(--EDECFE-2D2B46);
    margin-right: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    > svg {
      path {
        fill: var(--7F78E3-736DBB);
      }
    }
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    width: 200px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
  }
}

.newCardTypeSectionCustomElem {
  > div:nth-child(2) {
    width: 205px;
  }
}

.newCardTypeSectionBtn, .newCardTypeSectionBtnDisabled {
  width: 224px;
  height: 40px;
  border-radius: 6px;
  padding: 7px 16px;

  span {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 16px;
    font-weight: 500;
    line-height: 23px;
    text-transform: uppercase;
    color: var(--673E1D-5F391B);
  }

  > span {
    display: flex;
  }
}

.newCardTypeSectionBtnDisabled {
  background-color: var(--F4F4F6-27272B);
  pointer-events: none;
  cursor: default !important;

  span {
    color: var(--9F9FA7-7A7A84) !important;
    font-family: Roboto, 'sans-serif' !important;
  }
}

.maxCardSectionSubtitle {
  width: 200px;
}

.maxCardSubtitle {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  margin-bottom: 8px !important;
}

.maxCardSupportElem {
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: var(--7F78E3-8782C4);
  cursor: pointer;

  &:hover {
    color: var(--564C94-A39ED1)
  }
}
