.appDatePickerContainer {
  position: relative;
  margin-bottom: 4px;

  > span {
    position: absolute;
    font-family: "Roboto", sans-serif;
    font-size: 12px !important;
    font-weight: 400;
    line-height: 16px !important;
    opacity:1 !important;
    padding: 0 6px 4.5px 6px !important;
    transform: translateY(-40%) translateX(7px);
    background: var(--FFFFFF-222225);
    color: #8D8D96;
    pointer-events: none;
    left:0;
    top:0;
    transition: 0.2s;
    transition-timing-function: ease, cubic-bezier(0.25, 0.1, 0.25, 1);
  }
  :global {
    .ant-picker-disabled {
      pointer-events: none;
      border: 1px solid var(--F4F4F6-313135);
      background-color: var(--FAFAFB-27272B);

      > div > input {
        color: var(--36363A-FFFFFF) !important;
      }
    }
  }
}

.dateDisabled {
  background: linear-gradient(var(--FFFFFF-222225) 37%, var(--FAFAFB-27272B) 0%) !important;
}

.appDatePicker {
  position: relative;
  width: 268px;
  padding: 12px 12px 12px 50px;
  background-color: var(--FFFFFF-222225);
  border-radius: 4px;
  border: 1px solid var(--ECECED-3D3D42);
  box-shadow: none;

  :global {
    .ant-picker-input {
      > input {
        font-size: 16px;
        line-height: 24px;
        color: var(--36363A-FFFFFF);
      }
    }

    .ant-picker-suffix {
      position: absolute;
      left: -40px;
      margin-top: 3px;
    }

    .ant-picker-clear {
      display: none;
    }

    .ant-picker-active-bar {
      margin-inline-start: 51px;
    }

    .ant-picker-active-bar {
      background-color: #FCB241;
    }
  }
}

.separator {
  font-size: 16px;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
}


.popupDatePicker {
  max-width: 342px;
  max-height: 384px;

  :global {
    .ant-picker-panel-container {
      border-radius: 4px;
      background: transparent;
      border: 1px solid var(--AFABED-4F498D);
      max-height: 384px;
    }

    .ant-picker-range-arrow {
      margin-inline-start: 76.5px;

      &:before {
        background-color: var(--FFFFFF-222225);
      }
    }

    .ant-picker-date-panel, .ant-picker-month-panel, .ant-picker-year-panel {
      max-height: 384px;
      width: 342px;
      background-color: var(--FFFFFF-222225);
    }

    .ant-picker-month-panel {
      tbody > tr {
        height: 32px !important;

        .ant-picker-cell-range-end {
          > div {
            background-color: var(--7F78E3-8782C4) !important;
            color: #fff !important;
            border-top-right-radius: 4px !important;
            border-bottom-right-radius: 4px !important;
          }
        }
      }

      .ant-picker-content td {
        height: 66px !important;
      }

      .ant-picker-cell-inner {
        width: 100% !important;
      }
    }

    .ant-picker-year-panel {
      .ant-picker-header {
        > button, > div {
          display: none;
        }
      }

      .ant-picker-content td {
        height: 71px !important;
      }

      .ant-picker-cell-inner {
        margin-left: 28px;
      }
    }

    .ant-picker-panel {
      background-color: var(--FFFFFF-222225);
      width: 342px;

      .ant-picker-body {
        padding: 0 24px 24px 24px;
      }

      .ant-picker-content {
        > tbody {
          &:before {
            content:"\200C";
            display:block;
            line-height:10px;
          }
        }
      }

      .ant-picker-content th {
        width: 42px;
        height: 32px;
        padding: 0;
        font-size: 12px;
        line-height: 16px;
        color: #8D8D96;
      }

      .ant-picker-content td {
        padding: 0;
        height: 32px;

        &:after {
          display: none !important;
        }

        > div {
          width: 42px;
          height: 32px;
          display: flex;
          align-items: center;
          justify-content: center;

          &:after {
            background: transparent !important;
          }
        }
      }

      .ant-picker-cell-in-view, .ant-picker-cell-start {
        font-family: Roboto, 'sans-serif';
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
      }

      .ant-picker-cell {
        color: var(--B3B3B9-686871);
      }

      .ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
        background-color: var(--7F78E3-8782C4);
      }

      .ant-picker-cell-disabled {
        color: var(--B3B3B9-686871) !important;

        &:before {
          background: none;
        }
      }

      .ant-picker-cell-in-view {
        color: var(--36363A-FFFFFF);

        &:hover {
          color: #FFFFFF;
          border-radius: 4px;

          > div {
            background-color: var(--7F78E3-8782C4);
          }
        }

        &:before {
          background: none;
        }
      }

      .ant-picker-cell-selected {
        > div {
          background: var(--7F78E3-8782C4) !important;
        }
      }

      .ant-picker-cell-today {
        > div {
          font-weight: 500;

          &:before {
            position: absolute;
            border: none !important;
            width: 24px;
            background: #FCB241;
            height: 3px;
            left: 9px;
            bottom: 0;
            z-index: 1;
            border-radius: 2px !important;
            content: "";
            top: inherit !important;
            inset-inline-start: auto !important;
            inset-inline-end: auto !important;
          }
        }
      }

      .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-start,
      .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end.ant-picker-cell-today,
      .ant-picker-cell.ant-picker-cell-in-view.ant-picker-cell-range-end {
        color: #FFFFFF;

        > div {
          background-color: var(--7F78E3-8782C4);
        }

        &:before {
          border-radius: 5px 0 0 5px;
          background-color: var(--7F78E3-8782C4);
          height: 32px;

          &:hover {
            border-radius: 5px 0 0 5px;
            background-color: var(--7F78E3-8782C4);
            height: 32px;
          }
        }
      }

      .ant-picker-cell-in-range {

        > div {
          background-color: var(--EDECFE-2D2B46) !important;
          color: var(--36363A-FFFFFF) !important;
          border-radius: initial !important;

          &:hover {
            background-color: var(--EDECFE-2D2B46) !important;
          }
        }

        &:hover {
          color: var(--36363A-FFFFFF) !important;
          border-radius: 0 !important;
        }

        &:before {
          background: var(--EDECFE-2D2B46) !important;
          height: 32px;
        }
      }

      .ant-picker-cell-in-range.ant-picker-cell-range-hover-start {
        &:before {
          background: var(--EDECFE-2D2B46) !important;
        }
      }

      .ant-picker-content > tbody > tr:not(:last-child) {
        height: 44px;
        padding-bottom: 12px;
      }

      > div:nth-child(2) {
        display: none;
      }

      .ant-picker-header-next-btn, .ant-picker-header-super-next-btn {
        visibility: visible !important;

        svg {
          path {
            fill: var(--7F78E3-736DBB);
          }
        }
      }
    }

    .ant-picker-header {
      position: relative;
      height: 68px;
      padding: 0 16px;
      border-bottom: none;

      .ant-picker-header-super-prev-btn {
        position: absolute;
        right: 85px;
        height: 100%;
      }

      .ant-picker-header-super-prev-btn, .ant-picker-header-super-next-btn {
        svg {
          path {
            fill: var(--7F78E3-736DBB);
          }
        }
      }

      .ant-picker-header-view {
        > button {
          color: var(--36363A-FFFFFF);
          font-family: Roboto, 'sans-serif';
          font-size: 14px;
          font-weight: 400;
        }

        > button:nth-child(1) {
          position: absolute;
          left: 46px;
          top: 10px;
          line-height: 44px;
        }
        > button:nth-child(2) {
          position: absolute;
          right: 46px;
          top: 10px;
          line-height: 42px;
        }
      }

      .ant-picker-header-next-btn {
        position: absolute;
        left: 80px;
        height: 100%;
      }

      .ant-picker-header-prev-btn, .ant-picker-header-next-btn {
        svg {
          path {
            fill: var(--7F78E3-736DBB);
          }
        }
      }

      .ant-picker-year-btn {
        position: absolute;
        right: 46px !important;
        top: 11px !important;
        left: inherit !important;
      }
    }
  }
}

.timeZoneInformation {
  font-family: "Roboto", sans-serif;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0px;
  text-align: left;

  padding-left: 14px;
  color: var(--9F9FA7-7A7A84);
}
