&:root[data-theme="light"] {
  .subscriptionPeriodItem:hover, .subscriptionPeriodActiveSelectedItem {
    box-shadow: 0 4px 16px 0 rgba(178, 190, 195, 0.25);
  }
}

&:root[data-theme="dark"] {
  .subscriptionPeriodItem:hover, .subscriptionPeriodActiveSelectedItem {
    box-shadow: 0 4px 20px 0 rgba(19, 19, 22, 0.80);
  }
}

.subscriptionNotActivatedModal {
  :global {
    .ant-modal-content {
      background-color: transparent;
    }
    .ant-modal-header {
      border-bottom: none;
      margin-bottom: 0;
    }
    .ant-modal-close {
      width: 16px;
      height: 16px;
      top: 52px;
      right: 38px;
    }
  }
}

.subscriptionNotActivatedModalContainer {
  display: flex;
  min-height: 860px;
}

.subscriptionNotActivatedModalLeftSection {
  padding: 32px;
  width: 400px;
  background: url(assets/images/trialPaidSubscriptionBg.webp) lightgray 50% center no-repeat;
  background-size: cover;
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;

  > div:nth-child(1), > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 44px;
    font-weight: 700;
    letter-spacing: 0.2px;
    text-transform: uppercase;
  }

  > div:nth-child(1) {
    color: var(--7F78E3-8782C4);
  }

  > div:nth-child(2) {
    color: #FFFFFF;
  }

  > div:nth-child(3) {
    margin-top: 16px;
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: #FFFFFF;
  }

  > img {
    margin-top: 108px;
    width: 336px;
    height: 221px;
  }
}

.subscriptionNotActivatedModalRightSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 618px;
  border: 1px solid var(--ECECED-3D3D42);
  border-left: none;
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;
  background-color: var(--FFFFFF-222225);
  padding: 40px;

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }

  > div:nth-child(2) {
    display: flex;
    margin-top: 40px;

    > div:first-child {
      margin-right: 16px;
    }
  }

  > div:nth-child(3) {
    margin-top: 24px;
  }

  > button {
    width: 324px;
    height: 48px;
    margin-top: 94px;
  }
}


.subscriptionPeriodItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 12px;
  width: 256px;
  height: 146px;
  padding: 24px 16px;
  position: relative;
  border: 1px solid var(--D9D9DC-4E4E55);

  &:hover {
    cursor: pointer;
  }
}

.subscriptionPeriodActiveSelectedItem {
  border: 2px solid var(--7F78E3-8782C4) !important;
}

.subscriptionPeriodAnnualItem {
  border: 1px solid var(--D4E8E4-1F5C52);
}

.subscriptionPeriodItemTitle {
  margin-bottom: 12px;
  font-family: Roboto-Medium, sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: var(--36363A-FFFFFF);
}

.subscriptionPeriodItemAmountSection {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    margin-top: 2px;
  }
}

.subscriptionPeriodItemAmount {
  > span:nth-child(1) {
    color: var(--36363A-FFFFFF);
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 28px;
    line-height: 40px;
  }
  > span:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 22px;
    color: var(--9F9FA7-7A7A84);
    text-decoration-line: line-through;
    text-decoration-thickness: 1px;
    font-weight: 300;
    line-height: 40px;
    margin-left: 8px;
  }
}

.subscriptionNotActivatedModalAccountSection {
  margin-top: 48px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
  }

  > div:nth-child(2) {
    margin-top: 24px;
  }
}

.subscriptionNotActivatedModalAccountValue {
  position: absolute;
  top: 13px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #8D8D96;
  left: 112px;
}

.subscriptionPeriodItemMostPopular {
  position: absolute;
  border-radius: 24px;
  padding: 3px 6px;
  background-color: var(--D4E8E4-1F5C52);
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--12836E-74BEA5);
  top: -11px;
}