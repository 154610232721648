.outBoundTransfer {
  margin-top: 32px;
  width: 100%;

  > div:nth-child(3) {
    margin-top: 24px;
  }
}

.outBoundTransferSectionTitle {
  margin-bottom: 16px;
  font-family: Roboto-Medium, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}