.cardsShimmer {

  > div {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    width: 586px;

    > div {
      width: 285px;
      height: 179px;
      border-radius: 12px;
      background-color: var(--ECECED-3D3D42);
    }
  }
}

.cardsRowShimmer {
  > div {
    display: flex;
    flex-direction: column;
    width: 586px;

    > div {
      width: 100%;
      height: 80px;
      border-radius: 12px;
      background-color: var(--ECECED-3D3D42);

      &:not(:last-child) {
        margin-bottom: 24px;
      }
    }
  }
}
