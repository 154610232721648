.transactionHistoryContainer, .transactionHistoryContainerEmpty {
  width: 100%;
  overflow-x: auto;

  > div {
    width: 100%;
    margin: 24px;

    @media (max-width: 1280px) {
      width: 1200px;
    }

    @media (min-width: 1680px) {
      width: 1552px;
      margin: 24px auto;
    }
  }
}

.transactionHistoryContainerEmpty {
  > div {
    height: calc(100% - 48px);
  }
}

.transactionHistoryNavigation {
  display: flex;
  margin-bottom: 24px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: #8D8D96;
    cursor: pointer;

    &:hover {
      color: var(--36363A-FFFFFF);
    }
  }

  svg {
    width: 16px;
    height: 16px;
    margin: 0 12px;
    path {
      transform: translateY(14px) translateX(4px) rotate(-90deg);
      fill: var(--FCB241-DD994B);
    }
  }

  > div:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: var(--36363A-FFFFFF);
  }
}

.transactionHistoryHeader {
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    position: relative;
  }
}

.transactionHistoryMainSection, .transactionHistoryMainSectionEmpty {
  display: flex;
  flex-direction: column;
  padding: 24px;
  width: 100%;
  background: var(--FFFFFF-222225);
  border-radius: 12px;

  :global {
    .ant-table-placeholder {
      &:hover {
        > td {
          background-color: var(--FFFFFF-222225) !important;
        }
      }
    }

    .ant-table-tbody > tr > td:last-child {
      padding: 16px 8px !important;
    }

    .ant-pagination {
      margin: 24px 0 0 0 !important;
    }
  }
}

.transactionHistoryMainSectionEmpty {
  height: calc(100% - 32px);
}

.transactionHistoryTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.filterContainerByTransactionType {
  display: flex;
  margin-left: 24px;
  border-radius: 8px;
  padding: 4px;
  background-color: var(--FAFAFB-27272B);
}

.filterSectionByTransactionType {
  padding: 4px 8px;
  border-radius: 6px;
  color: var(--606068-BCBCC2);
  font-family: Roboto, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  cursor: pointer;
}

.filterActiveSectionByTransactionType {
  font-family: Roboto-Medium, 'sans-serif';
  background-color: var(--746BB3-736DBB);
  color: #FFFFFF;
}

.localeContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 388px);

  > div:nth-child(3) {
    margin-top: 4px;
  }

  svg {
    width: 80px;
    height: 80px;

    > path, > g {
      transform: translateY(5px);
    }
  }
}

.headerNoTransaction {
  color: var(--606068-BCBCC2);
  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  margin-top: 16px;
  font-family: "Jost", sans-serif;
}

.noTransactionText {
  color: #8D8D96;
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  width: 270px;
  font-family: "Roboto", sans-serif;
}

.highlightBottomBorder, .bottomBorderNone {
  margin-top: 24px;

  &:last-child > td {
    border-bottom: none !important;
  }

  :global {
    .ant-spin-container {
      height: 100%;
    }

    .ant-table-thead {
      height: 44px;
      position: relative;

      &:before {
        position: absolute;
        content: '';
        left: 0;
        right: 0;
        height: 1px;
        background-color: var(--ECECED-3D3D42);
        bottom: 0;
        z-index: 1;
      }

      > tr {
        height: 44px;

        > td:nth-child(1) {
          &:before {
            display: none;
          }
        }

        > th:nth-child(2) {
          padding: 0 !important;
        }
      }
    }

    .ant-table-tbody {
      > tr {
        height: 76px;

        > td {
          height: 76px;
          border-bottom: 1px solid var(--F4F4F6-313135);
        }

        > td:first-child {
          display: flex;
          justify-content: center;
          align-items: center;
          border-bottom: none;
        }
        > td:nth-child(2) {
          padding: 16px 0;
        }

        &:hover {
          cursor: pointer;
        }
      }
      }
  }
}

.bottomBorderNone {
  :global {
    .ant-table-empty {
      height: calc(100% - 62px);
    }

    .ant-table-tbody {
      > tr > td {
        border-bottom: none !important
      }
      > tr > td:first-child {
        display: table-cell;
        cursor: default;
      }
    }

    .ant-table-placeholder {
      &:hover {
         > td {
           background-color: var(--FFFFFF-222225) !important;
         }
      }
    }
  }
}

.transactionHistoryDate, .declinedTransactionHistoryDate {
  > div:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    margin-bottom: 3px;
    height: 20px;
  }

  > div {
    > span {
      display: inline-block;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      font-size: 12px;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    > span:nth-child(1) {
      color: var(--9F9FA7-7A7A84);
      margin-right: 3px;
    }
  }
}

.declinedTransactionHistoryDate {
  color: var(--9F9FA7-7A7A84) !important;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;

  > div {
    color: var(--9F9FA7-7A7A84) !important;

    > span:nth-child(1) {
      text-decoration-line: line-through;
      text-decoration-thickness: 1px;
    }
  }
}

.transactionHistorySource, .declinedTransactionHistorySource, .specificTransactionHistorySource {
  > div:nth-child(1) > div:nth-child(1) {
    margin-bottom: 3px;

    > span {
      font-family: "Roboto", sans-serif;
      line-height: 20px;
      font-size: 14px;
      color: var(--606068-BCBCC2);
    }

    > span:nth-child(2) {
      position: relative;
      margin: 0 5px;

      &:before {
        content: '';
        position: absolute;
        height: 3px;
        width: 3px;
        background-color: var(--606068-BCBCC2);
        border-radius: 50%;
        right: -2px;
        top: 40%;
      }
    }
  }

  > div:nth-child(1) > div:nth-child(2) {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--9F9FA7-7A7A84);
  }
}

.specificTransactionHistorySource {
  > div:nth-child(1) > div:nth-child(1) {
    > span:nth-child(2) {
      &:before {
        display: none;
      }
    }
  }
}

.declinedTransactionHistorySource {
  > div > div {
    .declinedTransactionHistoryDate
  }

  > div:nth-child(1) > div:nth-child(1) {
    > span {
      color: var(--9F9FA7-7A7A84);
    }

    > span:nth-child(2) {
      &:before {
        background-color: var(--9F9FA7-7A7A84);
      }

      &:after {
        content: "";
        position: absolute;
        width: 10px;
        height: 1px;
        background-color: var(--9F9FA7-7A7A84);
        top: 47%;
        right: -5px;
      }
    }
  }
}

.transactionHistoryDescription {
  > div:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    line-height: 20px;
    color: var(--606068-BCBCC2);
    margin-bottom: 3px;
  }
  > div:nth-child(2) {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--9F9FA7-7A7A84);
  }
}


.transactionItemAmount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;

  > div {
    display: flex;

    > span > div {
      display: flex;
      align-items: center;
      color: var(--36363A-FFFFFF);
      font-family: Roboto-Medium, sans-serif;
      line-height: 20px;
      font-size: 14px;
    }
  }
}

.transactionItemCustomAmount {
  display: flex;
  justify-content: flex-end;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > div {
      display: flex;
      align-items: center;
    }

    > div:nth-child(1) {
      margin-bottom: 3px;

      > span, > span > div {
        display: flex;
        align-items: center;
        color: var(--36363A-FFFFFF);
        font-family: Roboto-Medium, sans-serif;
        line-height: 20px;
        font-size: 14px;
      }
    }

    > div:nth-child(2) > span {
      font-family: "Roboto", sans-serif;
      line-height: 14px;
      font-size: 10px;
      color: var(--9F9FA7-7A7A84);
    }
  }
}

.declinedTransactionItemAmount {
  > div span {
    color: var(--9F9FA7-7A7A84) !important;
  }

  > div > span:nth-child(2), > div > div > span:nth-child(2) {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1.5px;
      top: 55%;
      left: 0;
      background-color: var(--9F9FA7-7A7A84) !important;
    }
  }
}

.transactionHistoryLogo, .transactionHistoryLogoPending {
  background-color: var(--F4F4F6-313135);

  > svg {
    path {
      fill: var(--B3B3B9-686871);
    }
  }
}

.transactionHistoryLogoPending, .transactionHistoryLogoDeclined {
  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--F69744-E67A0F);
    border-radius: 50%;
    right: 2px;
    z-index: 2;
    bottom: 3px;
  }
  &:after {
    position: absolute;
    content: '';
    width: 11px;
    height: 11px;
    background-color: var(--FFFFFF-222225);
    border-radius: 50%;
    right: 1px;
    z-index: 1;
    bottom: 2px;
  }
}

.transactionHistoryLogoDeclined {
  &:before {
    background-color: var(--E15C69-C7383D);
  }
}

.transactionHistoryCurrency, .declinedTransactionHistoryCurrency {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
}

.declinedTransactionHistoryCurrency {
  .declinedTransactionHistoryDate
}

.transactionsExportBtn, .transactionsExportMultiPointButton {
  display: flex;
  width: 130px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  height: 32px;
  padding: 6px 10px;
  background-color: var(--FEF7E9-36302B);
  font-family: "Roboto-Medium", sans-serif;
  line-height: 20px;
  font-size: 14px;
  color: var(--AF6931-DFBEA4);
  text-transform: initial;

  > svg {
    margin-right: 8px;
    margin-bottom: 1px;

    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    background-color: var(--FDE8C0-554639);
    color: var(--8C5020-F8C6A0);

    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.transactionsExportBtn {
  border-radius: 6px 0 0 6px;
  margin-right: 1px;
}

.transactionsExportBtnActive {
  background-color: var(--F9CB76-D7AA70);

  > span {
    background-color: var(--673E1D-5F391B) !important;
  }

  &:hover {
    background-color: var(--F9CB76-D7AA70) !important;

    > span {
      background-color: var(--673E1D-5F391B) !important;
    }
  }
}

.disabledTransactionsExportBtn {
  > span {
    background-color: var(--B3B3B9-686871) !important;
  }
}

.transactionsExportMultiPointButton {
  width: 32px !important;
  border-radius: 0 6px 6px 0;
  padding: 0 !important;
  position: static !important;

  > div {
    display: none !important;
  }

  > span {
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: var(--FCB241-DD994B);

    &:not(:nth-child(3)) {
      margin-right: 3px;
    }
  }

  &:hover {
    > span {
      background-color: var(--F19304-FEBD72);
    }
  }
}

.exportSelector {
  position: absolute;
  width: 155px;
  border-radius: 4px;
  border: 1px solid var(--AFABED-4F498D);
  padding: 4px 0;
  display: flex;
  flex-direction: column;
  background-color: var(--FFFFFF-222225);
  right: 0;
  top: 36px;
  box-shadow: var(--B2BEC340-131316CC);
  z-index: 2;

  > div {
    padding: 10px 12px 10px 14px;
    font-family: Roboto, 'sans-serif';
    line-height: 20px;
    font-size: 14px;
    color: var(--606068-BCBCC2);

    &:hover {
      cursor: pointer;
      background-color: var(--FAFAFB-27272B);
    }
  }
}

.transactionHistoryColSort, .transactionHistoryCurrentColSort, .transactionHistoryColAscSort, .transactionHistoryCustomColSort {
  display: flex;
  align-items: center;
  cursor: pointer;

  > svg {
    margin-left: 4px;
  }
}

.transactionHistoryCustomColSort {
  justify-content: flex-end;
}

.transactionHistoryColSort {
  > svg {
    path {
      fill: var(--D9D9DC-4E4E55) !important;
    }
  }
}

.transactionHistoryColAscSort {
  > svg {
    transform: rotate(180deg);
  }
}

.reportModalButtonsContainer {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  > button:first-child {
    display: flex;
    align-items: center;
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;
    padding: 0;

    height: 40px;
    width: 166px;
    color: var(--AF6931-DFBEA4);
    text-transform: uppercase;

    svg {
      margin-right: 8px;
      transform: rotate(180deg);
      path {
        fill: var(--FCB241-DD994B);
      }
    }

    &:hover {
      color: var(--8C5020-F8C6A0);

      svg {
        path {
          fill: var(--F19304-FEBD72);
        }
      }
    }
  }
  > button:nth-child(2) {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0;
    text-align: center;

    height: 40px;
    width: 147px;
    background-color: var(--F9CB76-D7AA70);
    color: var(--673E1D-5F391B);
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      margin-left: 8px;
      path {
        fill: var(--673E1D-5F391B)
      }
    }

    &:hover {
      background-color: var(--E8C187-BE8F60);
    }
  }
}
