.dashboardContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  @media (max-width: 1280px) {
    width: 1200px;
  }

  @media (min-width: 1920px) {
    width: 1552px;
    margin: 0 auto;
  }
}

.successExchangePopUpContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 24px 0;

}

.rightEmptyBlock {
  display: none;
  width: calc((100vw - 1552px - 240px) / 2);

  @media (min-width: 1920px) {
    display: block;
    position: absolute;
    height: 100%;
    right: calc((100vw - 1552px - 240px) / -2);
    top: 0;
    background-color: var(--FFFFFF-222225);
  }
}

