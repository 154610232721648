.errorRetailScreenContainer {
  display: flex;
  width: 100%;
  height: 100%;
  @media (max-width: 900px) {
    width: 1000px;
    background-color: var(--FFFFFF-222225);
  }
}

.errorRetailScreenLeftSection {
  width: 50%;
  display: flex;
  flex-direction: column;

  .logo {
    cursor: pointer;
    margin-bottom: 100px;
    svg {
      width: 140px;
      height: 51.08px;
    }
  }

  .footer {
    display: flex;
    font-family: 'Roboto', sans-serif;
    justify-content: center;
    color: var(--9F9FA7-7A7A84);
    line-height: 16px;
    font-size: 12px;
    margin-bottom: 44px;
    font-weight: 400;
  }
}

.thanksScreenMainSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.retailErrorTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  color: var(--36363A-FFFFFF);
  text-align: center;
}

.retailErrorSecondTitle {
  font-size: 28px;
  line-height: 36px;
  font-weight: 500;
  font-family: 'Jost', sans-serif;
  margin-bottom: 24px;
  color: var(--36363A-FFFFFF);
  text-align: center;
}

.retailErrorSubTitle {
  font-size: 18px;
  line-height: 24px;
  width: 408px;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  color: var(--606068-BCBCC2);
}

.retailErrorScreenRightSection {
  width: 50%;
  padding: 12px 12px 12px 0;

  > div {
    display: flex;
    height: 100%;
    align-items: center;
    justify-content: center;
    background: var(--F4F4F6-313135);
    border-radius: 16px;
  }

  img {
    width: 100%;
    height: auto;
    max-width: 40vw;

    @media (max-width: 900px) {
      width: 360px;
      max-width: none;
    }

    @media (min-width: 1920px) {
      width: 657px;
      max-width: none;
    }
  }
}

.openAccountButton {
  background: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  font-family: 'Roboto', sans-serif;
  text-align: center;
  width: 100%;
  height: 48px;
  margin-top: 156px;
  border-radius: 6px;
  cursor: pointer;

  &:hover {
    background: var(--E8C187-BE8F60);
  }
}

.loginButton {
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-family: 'Roboto', sans-serif;
  margin-top: 16px;
  width: 100%;
  height: 48px;
  background: transparent;
  color: var(--AF6931-DFBEA4);
  font-size: 16px;
  line-height: 23px;
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: var(--8C5020-F8C6A0);
  }
}
