.successTerminateModal {
  width: 640px;
  height: calc(100% - 64px);
  max-height: 938px;
  padding: 0 40px 40px 40px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  margin-bottom: 40px;
}

.successTerminateModalTitle {
  margin-top: 24px;
  margin-bottom: 17px;
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.successTerminateModalSubTitle, .successTerminateModalCustomSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
  width: 275px;
  text-align: center;

  > span:nth-child(2) {
    font-family: Roboto-Medium, sans-serif;
  }

  > div {
    margin-top: 4px;
  }
}

.successTerminateModalCustomSubTitle {
  width: 348px;
}

.successTerminateModalButtons {
  margin-top: 48px;

  > button {
    width: 240px;

    > svg {
      margin-left: 8px;
    }
  }

  > button:nth-child(2) {
    margin-top: 16px;
  }
}
