.containerShimmer {
  > div:first-child {
    width: 560px;
    height: 48px;
    border-radius: 8px;
    background-color: var(--F4F4F6-313135);
    margin-bottom: 12px;
  }
  > div:nth-child(2) {
    > div:nth-child(1) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      width: 88px;
    }
    > div:nth-child(2) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      width: 136px;
    }
    > div:nth-child(3) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      width: 96px;
    }
    > div:nth-child(4) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      width: 152px;
    }
  }

  > div:nth-child(3) {
    > div:nth-child(1) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      margin-top: 8px;
      width: 136px;
    }
    > div:nth-child(2) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      margin-top: 8px;
      width: 72px;
    }
    > div:nth-child(3) {
      height: 24px;
      border-radius: 6px;
      background-color: var(--F4F4F6-313135);
      margin-right: 8px;
      margin-top: 8px;
      width: 128px;
    }
  }
}
