.cardRowViewContainer {
  width: 100%;
  height: 80px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 24px;
  }

  > div:nth-child(1) {
    display: flex;
    align-items: center;

    img {
      width: 40px;
      height: 25px;
      margin-right: 24px;
    }

    svg {
      margin-right: 22px;
      margin-left: -2px;
      margin-bottom: -8px;

      > g:nth-child(3) {
        mix-blend-mode: soft-light;

        > rect {
          mix-blend-mode: luminosity;
        }
      }
    }
  }

  &:hover {
    border: 1px solid var(--7F78E3-8782C4);

    .cardRowInfo {
      > div:nth-child(1) {
        color: var(--36363A-FFFFFF);
      }
    }

    .cardRowArrow {
      background-color: var(--FDE8C0-554639);
    }
  }
}

.miniFrozenPhysicalCard {
  margin-right: 18px !important;
  margin-bottom: -8px;
  margin-left: -6px;
  width: auto !important;
  height: auto !important;
}

.cardRowInfo {
  display: flex;
  align-items: center;

  > div {
    font-family: "Roboto-Medium", sans-serif;
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }

  > span {
    margin: 0 4px 0 8px;
    height: 4px;
    width: 4px;
    background-color: var(--B3B3B9-686871);
    border-radius: 50%;
    display: inline-block;
  }

  > div:nth-child(3) {
    font-family: "Roboto", sans-serif;
  }
}

.cardRowArrow {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);
}

.cardRowStatus, .cardRowFrozenStatus, .cardRowExpiresStatus, .cardRowShippingStatus {
  display: flex;
  align-items: center;
  width: max-content;
  border-radius: 32px;
  margin-left: 16px;
  padding: 2px 8px;
  height: 24px;
  font-family: Roboto-Medium, sans-serif;
  font-size: 14px;
  line-height: 20px;

  > span {
    margin: 0 5px;
    height: 4px;
    width: 4px;
    border-radius: 50%;
    display: inline-block;
  }
}

.cardRowFrozenStatus {
  background-color: var(--EDECFE-2D2B46);
  color: var(--746BB3-736DBB);
}

.cardRowExpiresStatus {
  background-color: var(--FBE5E7-3A1D1E);
  color: var(--D92638-F45258);

  > span {
    background-color: var(--D92638-F45258);
  }
}

.cardRowShippingStatus {
  background-color: var(--E2F3ED-173B35);
  color: var(--12836E-74BEA5);

  > span {
    background-color: var(--12836E-74BEA5);
  }
}
