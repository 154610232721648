.header {
  padding: 0 !important;
  position: fixed !important;
  width: 100vw;
  top: 0;
  z-index: 190;
  border-bottom: 1px solid var(--EAEAEB-3D3D42);

  :global {
    .ant-page-header-heading {
      background-color: var(--FFFFFF-222225);
      height: 64px;
      min-width: max-content;
    }
    .ant-page-header-heading-left {
      margin: 0;
    }
    .ant-page-header-heading-title {
      margin-right: 0;

      > div {
        display: flex;
        margin-left: 24px;

        svg {
          width: 76px;
          height: 27px;
        }
      }
    }
    .ant-page-header-heading-extra {
      display: flex;
      margin: 0 16px 0 16px;
    }
  }
}

.connectionErrorContainer {
  background-color: var(--FAFAFB-27272B);
  height: calc(100vh - 64px);
  margin-top: 64px;

  display: flex;
  flex-direction: column;
  align-items: center;

  > svg {
    margin-top: 55px;
    margin-bottom: 45px;
  }
}

.title {
  font-family: "Jost", sans-serif;
  font-size: 28px;
  font-weight: 500;
  line-height: 36px;
  text-align: center;

  color: var(--36363A-FFFFFF);
  margin-bottom: 16px;
}

.subTitle {
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  text-align: center;

  color: var(--606068-BCBCC2);
  margin-bottom: 48px;

  width: 325px;
}

.btn {
  font-family: "Roboto", sans-serif;
  background: var(--F9CB76-D7AA70);
  color: var(--673E1D-5F391B);
  border-radius: 6px;
  width: 320px;
  height: 48px;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;

  cursor: pointer;
  text-transform: uppercase;

  display: flex;
  align-items: center;
  justify-content: center;

  &:hover {
    background: var(--E8C187-BE8F60);
  }

  > svg {
    margin-left: 11px;
    path {
      fill: var(--673E1D-5F391B);
    }
  }
}
