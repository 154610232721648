.successActivateCard {
  > svg {
    margin-bottom: 24px;
  }

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 16px;
  }

  > div:nth-child(3) {
    width: 432px;
    text-align: center;
    margin-bottom: 8px;
  }

  > div:nth-child(4) {
    width: 274px;
    text-align: center;
    margin-bottom: 48px;
  }
}

.successActivateCardBtnSection {
  > button {
    width: 240px;
    height: 40px;
  }

  > button:nth-child(1) {
    margin-bottom: 16px;
  }
}

.successActivateCardDescription, .successActivateCardBoldDescription {
  font-size: 16px;
  line-height: 24px;
}

.successActivateCardDescription {
  font-family: Roboto, sans-serif;
  color: var(--606068-BCBCC2);
}

.successActivateCardBoldDescription {
  font-family: Roboto-Medium, 'sans-serif';
  color: var(--36363A-FFFFFF);
}