.unblockCardModal {
  :global {
    .ant-modal-body {
      padding: 32px;
    }
  }
}

.unblockCardModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > img {
    margin-top: 30px;
    margin-bottom: 32px;
  }

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 8px;
  }

  > div:nth-child(3) {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
    margin-bottom: 16px;
    text-align: center;
  }
}

.unblockCardModalBtnSection {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  width: 100%;

  > button {
    width: 192px;
    height: 40px;
  }

  > button:nth-child(1) {
    width: 61px;
    padding-left: 0;
    justify-content: left;
  }
}