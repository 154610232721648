.debitCardsLandingContainer {
  padding: 100px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: var(--FFFFFF-222225);
  position: relative;

  > div:nth-child(1) {
    display: flex;
    margin-bottom: 67px;
  }

  > div:nth-child(3) {
    width: 569px;
    font-family: Jost, 'sans-serif';
    line-height: 36.4px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-top: 63px;
    margin-bottom: 32px;
    font-size: 28px;
    text-align: center;
  }

  > button {
    width: 281px;
    height: 48px;
  }

  > svg {
    position: absolute;
  }
}

.debitCardsLandingDescriptionSection {
  display: flex;
  flex-direction: column;
  width: 282px;
  padding-left: 30px;
  border-left: 1px solid #F1E3CA;

  &:first-child {
    margin-right: 81px;
  }

  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 26px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 6px;
  }

  > div:nth-child(2) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }
}

.debitCardsLandingImgSection {
  position: relative;

  > img {
    z-index: 1;
    position: relative;
    width: 765px;
  }

  > svg {
    position: absolute;
    path {
      fill: var(--ECECED-3D3D42);
    }
  }

  > svg:nth-child(2) {
    left: -310px;
    top: -90px;
  }
  > svg:nth-child(3) {
    right: -200px;
    top: -410px;
    transform: rotate(210deg);
  }
}