.additionalValueClassName {
  position: absolute;
  top: 13px;
  font-family: "Roboto", sans-serif;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  color: #8D8D96;
  left: 112px;
}

.customAccountSelect {
  height: 52px;

  > div {
    height: 52px !important;
  }

  :global {
    .ant-select-dropdown {
      top: 54px !important;
      max-width: 440px !important;
      width: 100% !important;
      min-width: max-content !important;
      border-radius: 4px !important;
    }

    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding-left: 16px;
      padding-right: 14px;
    }

    .ant-select-selection-item {
      .ant-typography {
        font-size: 18px;
      }

      div > span:nth-child(2) {
        display: none;
      }
    }

    .ant-select-dropdown .ant-select-item-option {
      height: 52px;
      padding-left: 16px;
      padding-right: 14px;
    }

    .ant-select-item-option-content {
      > div > div > div {
        > span:nth-child(2) {
          font-size: 12px !important;
          line-height: 16px !important;
          color: #8D8D96 !important;
        }
      }
      div {
        > span {
          font-size: 14px !important;
          line-height: 20px !important;
          margin-bottom: 2px !important;
          color: var(--606068-BCBCC2) !important;
        }
      }
    }
  }
}

.bankTransferSubTitle {
  margin-top: 24px;
  margin-bottom: -16px;
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
  text-align: center;
}

.bankTransferFooterTitle {
  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 12px;
    line-height: 16px;
    color: var(--9F9FA7-7A7A84);
  }

  > span:nth-child(2) {
    font-family: Roboto-Medium, sans-serif;
    color: #8D8D96;
  }
}

.buttonsStyles {
  display: flex;
  justify-content: center;
  flex-direction: row-reverse;
  margin-top: 32px;

  > button {
    height: 48px;
  }

  > button:nth-child(1) {
    max-width: 322px;
  }
}

.toBankAccountTitle {
  display: flex;
  align-items: center;
  height: 20px;
}

.toBankAccountBalance {
  font-size: 18px;
  line-height: 24px;
  color: #8D8D96;
  font-family: Roboto, 'sans-serif';

  > span > div {
    > span {
      font-size: 18px;
      line-height: 24px;
      color: #8D8D96;
    }

    > span:nth-child(1) {
      margin-left: 5px;
      margin-right: 0 !important;
    }
    > span:nth-child(2) {
      display: inline-block !important;
    }
  }
}
