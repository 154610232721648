.lastCardTransactions {
  display: flex;
  flex-direction: column;
  width: 50%;
  height: 100%;
  background: var(--FFFFFF-222225);
  padding: 49px 48px 0 48px;
  position: relative;

  @media (max-width: 1440px) {
    width: 46%;
  }

  @media (min-width: 1680px) and (max-width: 1919px){
    width: 57.5%;
  }

  @media (min-width: 1920px) {
    padding-right: 0;
  }
}

.lastCardTransactionsHeader {
  margin-bottom: 27px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.lastCardTransactionsTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.latestCardTransactionsViewAll {
  display: flex;
  align-items: center;
  cursor: pointer;

  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 23px;
    text-transform: uppercase;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  > div {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
  }

  svg {
    path {
      fill: var(--FCB241-DD994B);
    }
  }

  &:hover {
    > span {
      color: var(--8C5020-F8C6A0);
    }
    svg {
      path {
        fill: var(--F19304-FEBD72);
      }
    }
  }
}

.lastCardsTransactionsContainer {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;

  > div > div {
    margin-bottom: 4px;
  }
}

.emptyCardTransactions, .emptyTransactions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  > div {
    text-align: center;
    color: var(--9F9FA7-7A7A84);
    width: 411px;
  }

  > div:nth-child(2) {
    margin: 48px 0 16px 0;
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
  }

  > div:nth-child(3), > div:nth-child(4) {
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    line-height: 24px;
  }
}

.emptyTransactions {
  > div {
    color: var(--606068-BCBCC2);
  }

  > div:nth-child(3), > div:nth-child(4) {
    color: #8D8D96;
  }
}
