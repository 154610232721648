.transfersContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background: var(--F2F2F2-313135);

  @media (max-width: 1280px) {
    width: 1200px;
  }
}
