.transactionItemContainer {
  &:first-child > div {
    margin-top: 0;
  }
}

.transactionItemDate {
  margin-top: 20px;
  font-family: Jost, 'sans-serif';
  font-size: 12px;
  line-height: 16px;
  color: var(--36363A-FFFFFF);
}

.transactionItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding: 18px 0;
}

.transactionItemLogoName {
  display: flex;
  align-items: center;
  width: 47.7%;
}

.transactionItemLogo {
  margin-right: 16px;
  background-color: var(--F4F4F6-313135);

  > svg {
    path {
      fill: var(--B3B3B9-686871);
    }
  }
}

.transactionItemLogoPending, .transactionItemLogoDeclined {
  &:before {
    position: absolute;
    content: '';
    width: 8px;
    height: 8px;
    background-color: var(--F69744-E67A0F);
    border-radius: 50%;
    right: 2px;
    z-index: 2;
    bottom: 3px;
  }
  &:after {
    position: absolute;
    content: '';
    width: 11px;
    height: 11px;
    background-color: var(--FFFFFF-222225);
    border-radius: 50%;
    right: 1px;
    z-index: 1;
    bottom: 2px;
  }
}

.transactionItemLogoDeclined {
  &:before {
    background-color: var(--E15C69-C7383D);
  }
}

.transactionItemDescription, .declinedTransactionItemDescription {
  width: 79.9%;

  > div:nth-child(1) {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
    margin-bottom: 3px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-right: 2px;
    height: 20px;
  }

  > div {
    > span {
      display: inline-block;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      font-size: 12px;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }

    > span:nth-child(1) {
      color: var(--9F9FA7-7A7A84);
      margin-right: 3px;
    }
  }
}

.declinedTransaction {
  color: var(--9F9FA7-7A7A84) !important;
  text-decoration-line: line-through;
  text-decoration-thickness: 1px;
}

.declinedTransactionItemDescription {
  > div {
    .declinedTransaction;
  }

  > div:nth-child(2) > span:nth-child(1) {
    text-decoration-line: line-through;
    text-decoration-thickness: 1px;
  }
}

.declinedTransactionItemName {
  > div:nth-child(1) {
    .declinedTransaction
  }

  > div {
    > span:nth-child(1) {
      .declinedTransaction
    }
  }
}

.transactionPendingStatus {
  color: var(--D66B00-D48F49);
}

.transactionDeclinedStatus {
  color: var(--D92638-F45258);
}

.transactionItemName, .declinedTransactionItemName, .specificTransactionItemName {
  width: 30.3%;

  > div > div {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    color: var(--606068-BCBCC2);
  }

  > div:nth-child(1) > div:nth-child(1) {
    margin-bottom: 3px;

    > span {
      font-family: "Roboto", sans-serif;
      line-height: 24px;
      font-size: 16px;
      color: var(--606068-BCBCC2);
    }

    > span:nth-child(2) {
      position: relative;
      margin: 0 5px;

      &:before {
        content: '';
        position: absolute;
        height: 3px;
        width: 3px;
        background-color: var(--606068-BCBCC2);
        border-radius: 50%;
        right: -2px;
        top: 40%;
      }
    }
  }

  > div:nth-child(1) > div:nth-child(2) {
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    line-height: 16px;
    color: var(--9F9FA7-7A7A84);
  }
}

.specificTransactionItemName {
  >  div:nth-child(1) > div:nth-child(1) {
    > span:nth-child(2) {
      &:before {
        display: none;
      }
    }
  }
}

.declinedTransactionItemName {
  > div > div {
    .declinedTransaction
  }

  > div > div:nth-child(1) {
    color: var(--9F9FA7-7A7A84);

    > span {
      color: var(--9F9FA7-7A7A84) !important;
    }

    > span:nth-child(2) {
      &:before {
        background-color: var(--9F9FA7-7A7A84) !important;
      }

      &:after {
        content: "";
        position: absolute;
        width: 11px;
        height: 1px;
        background-color: var(--9F9FA7-7A7A84);
        top: 45%;
        right: -5px;
      }
    }
  }
}

.transactionSubAmount {
  > span {
    display: flex;
    align-items: center;
    color: var(--9F9FA7-7A7A84);
    font-family: "Roboto", sans-serif;
    line-height: 16px !important;
    font-size: 12px !important;

    span {
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      font-size: 12px;
      color: var(--9F9FA7-7A7A84);
    }
  }
}

.transactionItemAmount {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  width: 22%;

  > div {
    display: flex;
    align-items: center;

    > span:nth-child(1) {
      margin-bottom: 3px;
    }

    > span > div {
      display: flex;
      align-items: center;
      color: var(--36363A-FFFFFF);
      font-family: Roboto-Medium, sans-serif;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

.transactionItemCustomAmount {
  width: 22%;

  > div {
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    > div {
      display: flex;
    }

    > div:nth-child(1) {
      margin-bottom: 3px;
      align-items: center;

      > span:nth-child(1) {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 3px;
      }

      > span > div {
        display: flex;
        align-items: center;
        color: var(--36363A-FFFFFF);
        font-family: Roboto-Medium, sans-serif;
        line-height: 24px;
        font-size: 16px;
      }
    }

    > div:nth-child(2) > span {
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      font-size: 12px;
      color: var(--9F9FA7-7A7A84);
    }
  }
}


.pendingTransactionItemAmount {
  > div {
    > span, div {
      color: var(--D66B00-D48F49) !important;
    }
  }
  > div > div:nth-child(1) span {
    color: var(--D66B00-D48F49) !important;
  }
}

.receivedTransactionItemAmount {
  > div {
    > span, div {
      color: var(--12836E-74BEA5) !important;
    }
  }

  > div > div:nth-child(1) span {
    color: var(--12836E-74BEA5) !important;
  }

  > div > div:nth-child(2) span {
    color: var(--579E92-379585) !important;
  }
}

.declinedTransactionItemAmount {
  > div span {
    color: var(--9F9FA7-7A7A84) !important;
  }

  > div > span:nth-child(2), > div > div > span:nth-child(2) {
    position: relative;

    &:before {
      position: absolute;
      content: '';
      width: 100%;
      height: 1px;
      top: 55%;
      background-color: var(--9F9FA7-7A7A84) !important;
    }
  }
}

.emptyDataDash {
  background-color: var(--B3B3B9-686871) !important;
  width: 11px;
  height: 2px;
}
