.accountOpeningModal {
  :global {
    .ant-modal-body {
      padding: 32px;
    }
  }
}

.accountOpeningModalBody {
  display: flex;
  flex-direction: column;
  align-items: center;

  > div {
    text-align: center;
  }

  > img {
    transform: scale(0.73);
    margin-top: 20px;
  }

  > div:nth-child(2) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    margin-bottom: 8px;
    color: var(--36363A-FFFFFF);
  }
}

.accountOpeningModalDescription {
  width: 328px;

  > span {
    font-size: 16px;
    line-height: 24px;
    font-family: Roboto, sans-serif;
    color: var(--606068-BCBCC2);
  }
  > span:nth-child(3) {
    font-family: Roboto-Medium, 'sans-serif'
  }
}

.accountOpeningModalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 48px;

  > button {
    width: 192px;
    height: 40px;
  }

  > button:nth-child(1) {
    margin-right: 32px;
    justify-content: left;
    padding-left: 0;
  }
}