.fundAccountModal {
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);

  :global {
    .ant-modal-content {
      background-color: transparent;
    }
    .ant-modal-header {
      border-bottom: none;
      margin-bottom: 0;
    }
    .ant-modal-close {
      width: 16px;
      height: 16px;
      top: 52px;
      right: 40px;
    }
  }
}

.fundAccountModalContainer {
  display: flex;
  min-height: 860px;

  > div {
    padding: 40px;
  }
}

.fundAccountModalLeftSection {
  display: flex;
  flex-direction: column;
  width: 400px;
  background-color: var(--FAFAFB-27272B);
  border-bottom-left-radius: 12px;
  border-top-left-radius: 12px;

  > div:nth-child(1) {
    text-align: left;
  }
}

.fundAccountModalRightSection, .cryptoTransferSection {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 618px;
  background-color: var(--FFFFFF-222225);
  border-bottom-right-radius: 12px;
  border-top-right-radius: 12px;

  > div:nth-child(1) {
    width: 100%;
  }
}

.cryptoTransferSection {
  padding-bottom: 38px !important;
}

.fundAccountModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 28px;
  line-height: 36px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 32px;
  text-align: center;
}

.paymentMethodSection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 24px;
  border-radius: 12px;
  background-color: var(--FFFFFF-222225);
  border: 1px solid var(--ECECED-3D3D42);
  height: 88px;

  > div:nth-child(1) {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }

  &:hover {
    cursor: pointer;
    border: 1px solid var(--AFABED-4F498D);

    > div:nth-child(1) {
      color: var(--36363A-FFFFFF);
    }
  }

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.disabledPaymentMethodSection {
  pointer-events: none;
}

.paymentMethodIcon, .transferIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  border: 1.5px solid var(--ECECED-3D3D42);
  background-color: var(--FAFAFA-424242);
  margin-left: 24px;
}

.transferIcon {
  background-color: var(--FAFAFB-27272B);

  svg {
    path {
      fill: var(--B3B3B9-686871);
    }
  }
}

.selectedPaymentMethodSection {
  border: 1px solid var(--7F78E3-8782C4) !important;

  > div:nth-child(1) {
    font-family: Roboto-Medium, sans-serif !important;
    color: var(--36363A-FFFFFF) !important;
  }
}
