.topUpSubscribeBanner {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 374px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  background: url(assets/images/trialPaidSubscriptionBg.webp) lightgray 50% center no-repeat;
  background-size: cover;
  padding: 24px 24px 32px;
  margin-top: 16px;

  > img {
    width: 272px;
    height: 179px;
    margin-bottom: 24px;
  }
}

.topUpSubscribeBannerTitle {
  font-family: Jost, 'sans-serif';
  font-size: 24px;
  font-weight: 700;
  letter-spacing: 0.2px;
  text-transform: uppercase;
  color: #FFFFFF;
  margin-bottom: 8px;
}

.topUpSubscribeBannerSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
  color: #FFFFFF;
}