.additionalFieldsModal {
  :global {
    .ant-modal-header {
      padding-bottom: 0 !important;

      > div {
        font-family: Jost, 'sans-serif';
      }
    }
  }
}

.saveFieldsBtn {
  > button {
    width: 252px;
    height: 47px;
  }
}

.additionalFieldsSection {
  > div {
    width: 100%;
  }

  > div:not(:first-child) {
    margin-top: 24px;
  }

  input {
    color: var(--36363A-FFFFFF) !important;
    font-size: 18px !important;
  }
}

.additionalFieldsModalSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 14px;
  line-height: 20px;
  color: var(--606068-BCBCC2);
  margin-top: 8px;
  margin-bottom: 24px;
}

.customAdditionalSelect {
  > div {
    height: 52px !important;
  }

  :global {
    .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
      padding: 0 16px;
    }

    .ant-select-selection-item {
      .ant-typography {
        font-size: 18px;
      }

      div > span:nth-child(2) {
        display: none;
      }
    }

    .ant-select-dropdown {
      top: 54px !important;;
    }

    .ant-select-dropdown .ant-select-item-option {
      height: 48px;
      padding-left: 14px;
      padding-right: 25px;
    }

    .ant-select-item-option-content {
      div {
        > span:nth-child(1) {
          font-size: 14px;
          line-height: 20px;
          margin-bottom: 2px;
          color: var(--606068-BCBCC2);
        }
      }
    }
  }
}
