.notReceivedCardModal {
  :global {
    .ant-modal-body {
      padding: 32px;
    }
  }
}

.notReceivedCardModalBody {
  display: flex;
  flex-direction: column;
}

.notReceivedCardModalTitle {
  font-family: Jost, 'sans-serif';
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
  margin-bottom: 8px;
}

.notReceivedCardModalSubTitle {
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.notReceivedCardModalSupportText {
  margin-top: 16px;

  > span {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    line-height: 24px;
  }

  > span:nth-child(1) {
    color: var(--606068-BCBCC2);
  }

  > span:nth-child(2) {
    font-family: Roboto-Medium, 'sans-serif';
    color: var(--36363A-FFFFFF);
  }
}

.notReceivedCardModalButtons {
  display: flex;
  margin-top: 32px;

  > button {
    width: 272px;
    height: 40px;
  }

  > button:nth-child(1) {
    margin-right: 32px;
  }
}