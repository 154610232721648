.pinCodeChangeModal {
  :global {
    .ant-modal-content {
      padding: 32px;
    }
  }
}

.pinCodeChangeModalContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  > svg {
    margin-top: 24px;
    margin-bottom: 32px;
  }

  > div:nth-child(2), > div:nth-child(3) {
    font-family: Jost, 'sans-serif';
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
  }
}

.pinCodeChangeModalButtons {
  display: flex;
  justify-content: space-between;
  margin-top: 56px;
  width: 100%;

  button {
    height: 40px;
  }

  > button {
    width: 61px;
    padding-left: 0;
    justify-content: left;
  }

  > div {
    display: flex;

    > button:nth-child(1) {
      width: 245px;
      margin-right: 24px;
    }

    > button:nth-child(2) {
      width: 108px;
    }
  }
}
