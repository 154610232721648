.shippingServiceContainer, .selectedShippingServiceContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 96px;
  padding: 0 24px;
  border-radius: 12px;
  border: 1px solid var(--ECECED-3D3D42);
  cursor: pointer;

  &:not(:last-child) {
    margin-bottom: 16px;
  }

  &:hover {
    background-color: var(--FAFAFB-27272B);
    border: 1px solid var(--ECECED-3D3D42);
    .shippingServiceIcons {
      > div:nth-child(1) {
        svg {
          display: block;
        }
      }
    }
    .shippingServiceIcon {
      background-color: var(--F9CB76-D7AA70);

      > span {
        color: var(--673E1D-5F391B);
      }

      svg {
        path {
          fill: var(--673E1D-5F391B);
        }
      }
    }
    .shippingService {
      > div {
        color: var(--36363A-FFFFFF);
        font-weight: 500;
      }

      > svg {
        path {
          fill: var(--D9D9DC-4E4E55);
        }
      }
    }
  }
}

.selectedShippingServiceContainer {
  border: 1px solid var(--7F78E3-8782C4);

  &:hover {
    border: 1px solid var(--7F78E3-8782C4);
  }
}

.shippingService {
  display: flex;
  align-items: center;

  path {
    fill: var(--ECECED-3D3D42)
  }


  > div {
    margin-left: 24px;
  }
}

.shippingServiceName {
  display: flex;
  align-items: center;
  margin-bottom: 4px;

  > div {
    font-family: Roboto, 'sans-serif';
    font-size: 18px;
    line-height: 24px;
    color: var(--606068-BCBCC2);
  }

  > span {
    width: 4px;
    height: 4px;
    background-color: #8D8D96;
    margin: 0 5px;
    border-radius: 50%;
  }
}

.shippingServiceSubName {
  > span {
    font-family: Roboto, 'sans-serif';
    font-size: 14px;
    line-height: 20px;
    color: var(--9F9FA7-7A7A84);
  }
}

.shippingServiceFreeName {
  color: var(--12836E-74BEA5) !important;
}

.shippingServiceIcons {
  display: flex;
  align-items: center;
}

.shippingServiceIcon {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background-color: var(--FEF7E9-36302B);
  height: 40px;
  width: 117px;
  padding: 7px 12px 7px 16px;

  > span {
    text-transform: uppercase;
    font-family: "Roboto-Medium", sans-serif;
    font-size: 16px;
    line-height: 23px;
    margin-right: 8px;
    color: var(--AF6931-DFBEA4);
  }

  &:hover {
    background-color: var(--E8C187-BE8F60) !important;
  }

  svg {
    width: 24px;
    height: 24px;

    path {
      transform: translate(8px, 4px);
      fill: var(--FCB241-DD994B);
    }
  }
}
