.shippingContainer {
  position: relative;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 40px;
  height: 100%;

  > div {
    width: 100%;
  }
}
