.setPinCodeContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 40px;
  height: 100%;
}

.setPinCodeHeader {
  > div:nth-child(1) {
    font-family: Jost, 'sans-serif';
    font-size: 28px;
    line-height: 36px;
    letter-spacing: 0.2px;
    color: var(--36363A-FFFFFF);
    margin-bottom: 40px;
    text-align: center;
  }

  > div:not(:nth-child(1)) {
    font-family: Roboto, 'sans-serif';
    font-size: 16px;
    line-height: 24px;
    color: #8D8D96;
    text-align: center;
  }
}

.setPinCodeButtons {
  display: flex;
  justify-content: space-between;
  width: 100%;

  > button:nth-child(1) {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    height: 48px;

    > svg {
      transform: rotate(180deg);
      margin-right: 8px;
      path {
        fill: var(--FCB241-DD994B);
      }
    }

    &:hover {
      > svg {
        path {
          fill: var(--F19304-FEBD72);
        }
      }
    }
  }

  > button:nth-child(2) {
    width: 396px;
    height: 48px;
  }
}
