.insufficientFundModal {
  :global {
    .ant-modal-close {
      top: 32px;
      right: 32px;
    }
  }
}

.insufficientFundModalBody {
  padding: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  > div {
    width: 100%;
    text-align: center;
  }

  > img {
    margin-top: 24px;
  }
}

.insufficientFundModalBodyTitle {
  margin-top: 22px;
  font-family: Jost, 'sans-serif';
  font-size: 20px;
  line-height: 32px;
  letter-spacing: 0.2px;
  color: var(--36363A-FFFFFF);
}

.insufficientFundModalBodySubTitle {
  margin-top: 8px;
  font-family: Roboto, 'sans-serif';
  font-size: 16px;
  line-height: 24px;
  color: var(--606068-BCBCC2);
}

.insufficientFundModalButtons {
  margin-top: 48px;
  display: flex;
  justify-content: space-between;

  > button {
    height: 40px;
  }

  > button:nth-child(2) {
    width: 192px;

    svg {
      margin-left: 8px;
    }
  }
}